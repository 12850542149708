
import React, { Component } from "react";
import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import { Redirect } from 'react-router';

import {deliveryOption,handlePayment,checkPayment, fetchCoupon,handlePreviousStep ,} from "../../store/actions/productAction";

import Loading from "../Loading";
import qs from 'qs'
import unauthorized from '../../assets/images/401.png'
import notfound from '../../assets/images/404.png'
import exist from '../../assets/images/409.png'
import cardDeclined from '../../assets/images/card-declined.png'
import ReactGA from 'react-ga';


class PaymentResponses extends Component {
    state = {
        redirect: false,
        timer:10

    }

    componentDidMount(){
    


        this.props.history.replace('/checkout','null')


        try {
            let tapID = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).tap_id
        
            if ( tapID === undefined || tapID === "" ) {
                this.props.history.replace('/','null')

            } 
            else {
                this.props.checkPayment(tapID)

            }

            

          }
          catch(err) {
            this.props.history.replace('/','null')
        }



         
    }


    timer = ()=> {

        var newCount = this.state.timer - 1;
        if(newCount >= 0) { 
            this.setState({ timer: newCount });
        } else {
            clearInterval(this.state.intervalId);
        }
     }




     UNSAFE_componentWillReceiveProps(nextProps) {
        const loading =  this.props.loading
        if(loading !== nextProps.loading) {
            this.redirect = setTimeout(() => this.setState({ redirect: true }), 10000)
            var intervalId = setInterval(this.timer, 1000);
            this.setState({intervalId: intervalId});
          }

        
        }
      


    componentWillUnmount() {
        clearTimeout(this.redirect)
        clearInterval(this.state.intervalId);
        

        }









    render() {

        if (this.props.errors.code === "001"){
            ReactGA.pageview(`/payment/response/exist`);


            return this.state.redirect ? <Redirect to="/" /> :
            <div>
                <img src={exist} alt="bg" className="payment-img"/>
                <h6 className="redirectTimerText"> Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6>
            </div>            
        }
        
        if (this.props.errors.code === "404"){
            ReactGA.pageview(`/payment/response/not found`);

            return this.state.redirect ? <Redirect to="/" /> :
            <div>
                <img src={notfound} alt="bg" className="payment-img"/>
                <h6 className="redirectTimerText"> Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6>
            </div>
        }
        
        if (this.props.errors.code === "401"){
            ReactGA.pageview(`/payment/response/unauthorized`);

            return this.state.redirect ? <Redirect to="/" /> :
            <div>
                <img src={unauthorized} alt="bg" className="payment-img"/>
                <h6 className="redirectTimerText"> Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6>
            </div>
            
        }

        if (this.props.errors.code === "002"){

            ReactGA.pageview(`/payment/response/cardDeclined`);

            return this.state.redirect ? <Redirect to="/" /> :

            <div className="home">
                <img src={cardDeclined} alt="bg" className="payment-img"/>
                <h6 className="redirectTimerText"> Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6>
            </div>


        }

        if (this.props.loading) {
            return <Loading />
          }else{

              
              
              
              
              if (this.props.payment_response.code === "201"){
                  let random =  Math.floor(Math.random() * 100)
                  
                  ReactGA.pageview(`/payment/response/successful`);
                  
                  this.props.history.push(`/successful/?code=${random}`,{ secret: random })
                  return (
                      <div></div>
                      
                      )
                    }
                    
                    else {
                        
                        
                        ReactGA.pageview(`/payment/response/not found`);
                        
                        return this.state.redirect ? <Redirect to="/" /> :
                        <div>
                    <img src={notfound} alt="bg" className="payment-img"/>
                    <h6 className="redirectTimerText"> Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6>
                </div>
            
            
            
        }
        
    }
    }
}




const mapStateToProps = state => {
    return {

        loading:state.productReducer.loading_payment_response,
        payment_response:state.productReducer.payment_response,
        errors: state.errorReducer.errors,


    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        deliveryOption: (DP) => dispatch(deliveryOption(DP)),
        fetchCoupon: (code) => dispatch(fetchCoupon(code)),
        handlePayment: (sub_id,delivery,coupon, selectedMeals,address_type,startDate) => dispatch(handlePayment(sub_id,delivery,coupon, selectedMeals,address_type,startDate)),
        checkPayment: (tapID) => dispatch(checkPayment(tapID)),

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentResponses));
  