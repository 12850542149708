import React from "react";
import ReactDOM from "react-dom";
// import axios from "axios";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Store
import store from "./store";

// Bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Fontawesome
// import "./utils/faLibrarySetup";
// import "../node_modules/font-awesome/css/font-awesome.min.css";

// Token check
import checkForExpiredToken from "./utils/checkForExpiredToken";

// google tag
import ReactGA from 'react-ga';

// sentry
import * as Sentry from '@sentry/react';

// ReactPixel tag
import ReactPixel from 'react-facebook-pixel';

  // ReactPixel tag
  const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
  };
  ReactPixel.init(`${process.env.REACT_APP_RP_KEY}`,  options);
  ReactPixel.pageView(); 					                   // For tracking page view
  // ReactPixel.track( event, data ) 		             // For tracking default events
  // ReactPixel.trackCustom( event, data )         	 // For tracking custom events









// google tag
ReactGA.initialize(`${process.env.REACT_APP_GA_KEY}`);


// sentry
if (process.env.NODE_ENV === 'production'){
  Sentry.init({dsn: `${process.env.REACT_APP_SENTRY_KEY}`});
}

checkForExpiredToken(store);



ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter >
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
