import {
  GET_SUBSCRIPTIONS,
  GET_SELECTED_SUB,
  GET_SELECTED_START_DATE,
  GET_SELECTED_DAY,
  GET_END_DAY,
  GET_MEALS,
  GET_NEXT_STEP,
  GET_PREVIOUS_STEP,
  GET_MEALS_LIST_FOR_SELECTED_DAY,
  CHANGE_LOADING_MEALS_STATUS,
  CHANGE_SLEECTED_SUB_TO_TRUE,
  CHANGE_SLEECTED_SUB_TO_FALSE,
  CHANGE_SLEECTED_DATE_TO_TRUE,
  CHANGE_SLEECTED_DATE_TO_FALSE,
  CHANGE_ONE_DAY_SELECTED_MEAL_TO_FALSE,
  CHANGE_ONE_DAY_SELECTED_MEAL_TO_TRUE,
  INCREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY,
  DECREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY,
  GET_SELECTED_MEAL_DETAIL,
  GET_SELECTED_DELIVERY_OPTION,
  GET_COUPON,
  GET_MAP,
  GET_ADDRESS,
  POST_ADDRESS,
  GET_USER_ADDRESSES,
  GET_SELECTED_ADDRESS_TYPE,
  GET_AUTOCOMPLETE,
  GET_SUPPORTED_PLACES,
  POST_PAYMENT,
  GET_SELECTED_BIRTH_DATE,
  GET_ALL_MEALS,
  SEND_EMAIL,
  RESET_STORE,
  CHANGE_LOCAL,


} from "../actions/types";

const initialState = {
  subscriptions:[],
  meals: {},
  selectedSub: {},
  locale:true ,
  loading: true,
  loading_payment_response: true,
  loadingMeals: true,
  loadingSubs: true,
  loadingAllMeals: true,
  selectedStartDay: undefined,
  day: undefined,
  endDay: undefined,
  step : 2,
  selectedDate:{},
  ondDayMeals:[],
  loadingOndDayMeals:true,
  isSelectedSubChanged:false,
  isSelectedDateChanged:false,
  selectedMealDetail:{},
  loadingSelectedMealDetail:true,
  DeliveryOption:'P',
  coupon:{
    'code':''
  },
  map:{},
  address:'',
  area:'',
  city:'',
  state:'',
  lat:'',
  lng:'',
  UserAddress:{
    "count": 0,},
  loadingUserAddress:true,
  created_address:'',
  address_type:'',
  autocomplete:'',
  supported_places:[],
  all_meals:[],
  date_of_birth:null,
  loadingSupportedPlaces:true,
  emailResponse:"",
  loadingEmail:true,
  payment_response:{
    code:"",
    response:""
  }

  
  

};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STORE:
      return {
        ...state,
        step:2,
        date_of_birth:null,
        selectedSub: {},
        selectedStartDay: undefined,
        day: undefined,
        endDay: undefined,
        loadingSelectedMealDetail:true,
        meals: {},
        payment_response:{
          code:"",
          response:""
        },

      };
    case GET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
        loadingSubs: false
      };
    case SEND_EMAIL:
      return {
        ...state,
        emailResponse: action.payload,
        loadingEmail: false
      };
    case GET_ALL_MEALS:
      return {
        ...state,
        all_meals: action.payload,
        loadingAllMeals: false
      };
    case GET_ADDRESS:
      return {
        ...state,
        address: action.address,
        area: action.area,
        city: action.city,
        state: action.state,
        lat: action.lat,
        lng: action.lng,
        mapUrl:action.mapUrl,
        country:action.country,
        county:action.county,
        postal_code:action.postal_code,

      };
    case POST_PAYMENT:
      return {
        ...state,
        payment_response: action.payload,
        loading_payment_response: false
      };
    case GET_USER_ADDRESSES:
      return {
        ...state,
        UserAddress: action.payload,
        loadingUserAddress:false
      };
    case GET_SUPPORTED_PLACES:
      return {
        ...state,
        supported_places: action.payload,
        loadingSupportedPlaces:false
      };
    case CHANGE_LOCAL:
      return {
        ...state,
        locale: !state["locale"],
      };


    case POST_ADDRESS:
      return {
        ...state,
        created_address: action.payload,
      };  

      case GET_MAP:
        return {
          ...state,
          map: action.payload,
      };
      case GET_AUTOCOMPLETE:
        return {
          ...state,
          autocomplete: action.payload,
      };
    case GET_MEALS:
      return {
        ...state,
        meals: action.payload,
        loadingMeals: false
      };
    case GET_COUPON:
      return {
        ...state,
        coupon: action.payload,
        // loadingCoupon: false
      };
    case GET_SELECTED_SUB:
      return {
        ...state,
        selectedSub: action.payload,
        loading: false
      };
    case GET_SELECTED_START_DATE:
      return {
        ...state,
        selectedStartDay: action.payload,
        loading: false
      };
    case GET_SELECTED_BIRTH_DATE:
      return {
        ...state,
        date_of_birth: action.payload,
      };
    case GET_SELECTED_DELIVERY_OPTION:
      return {
        ...state,
        DeliveryOption: action.payload,
      };
    case GET_SELECTED_ADDRESS_TYPE:
      return {
        ...state,
        address_type: action.payload,
      };
    case GET_SELECTED_DAY:
      return {
        ...state,
        day: action.payload,
        loading: false
      };
    case GET_END_DAY:
      return {
        ...state,
        endDay: action.payload,
      };
    case GET_NEXT_STEP:
      return {
        ...state,
        step: action.payload,
        loading: false,


      };
    case GET_SELECTED_MEAL_DETAIL:
      return {
        ...state,
        selectedMealDetail: action.payload,
        // loadingSelectedMealDetail: false,


      };
    case GET_PREVIOUS_STEP:
      return {
        ...state,
        step: action.payload,
        loading: false,
        // loadingMeals: false

      };
    case GET_MEALS_LIST_FOR_SELECTED_DAY:
      return {
        ...state,
        ondDayMeals: action.payload,
        loadingOndDayMeals: false
      }
    case CHANGE_ONE_DAY_SELECTED_MEAL_TO_FALSE:
      return {
        ...state,
        [action.payload]: false,
      }
    case CHANGE_ONE_DAY_SELECTED_MEAL_TO_TRUE:
      return {
        ...state,
        [action.payload]: true,
        // let dd = [`${action.payload}_number_of_selected`],
        // [`${action.payload}_number_of_selected`]: + 1
      }
    case INCREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY:
      return {
        ...state,
        // let dd = [`${action.payload}_number_of_selected`],
        [`total_selectedd_for_${action.payload}`]: state[`total_selectedd_for_${action.payload}`] ? state[`total_selectedd_for_${action.payload}`] +1 :  1,
      }
    case DECREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY:
      return {
        ...state,
        // let dd = [`${action.payload}_number_of_selected`],
        [`total_selectedd_for_${action.payload}`]:  state[`total_selectedd_for_${action.payload}`] -1 ,
      }
    case CHANGE_LOADING_MEALS_STATUS:
      return {
        ...state,
        loadingMeals: true
      }
    case CHANGE_SLEECTED_DATE_TO_TRUE:
      return {
        ...state,
        isSelectedDateChanged: true
      }
    case CHANGE_SLEECTED_DATE_TO_FALSE:
      return {
        ...state,
        isSelectedDateChanged: false
      }
    case CHANGE_SLEECTED_SUB_TO_TRUE:
      // let a = state.map(e => e.startWith("chosen_")) 
      // console.log("state",state)

      for (var key in state) {
        if (state.hasOwnProperty(key)) {
          if (key.startsWith("chosen_")){
            delete state[key]
            // console.log(key); // key (ex. sandwich)
            // console.log(state[key]); // value (ex. turkey)
          }
          if (key.startsWith("total_selectedd_for_")){
            delete state[key]
            // console.log(key); // key (ex. sandwich)
            // console.log(state[key]); // value (ex. turkey)
          }
        }
      }

      return {
        ...state,
        isSelectedSubChanged: true,
        meals:{}

      }
    case CHANGE_SLEECTED_SUB_TO_FALSE:
      return {
        ...state,
        isSelectedSubChanged: false
      }
    // case GET_SELECTED_DATE:
    //   return {
    //     ...state,
    //     selectedDate: action.payload + 1,
    //     loading: false
    //   };
    default:
      return state;
  }
};


