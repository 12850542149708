import React, { Component } from "react";
import {withRouter,} from "react-router-dom"
import { connect } from "react-redux";
import { handleSeletedSub } from "../../store/actions/productAction";
import { handleNextStep,handlePreviousStep,decrease_number_of_selected_meals,changeOndDaySeletedMealToFalse,changeOndDaySeletedMealToTrue,increase_number_of_selected_meals } from "../../store/actions/productAction";
import Loading from "../Loading";
import OneDayMeals from "./OneDayMeals"
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class MealsList extends Component {
  state = {
    number_of_selected:0
  };

  async componentDidMount(){    
  }



   checkSelectedMeal =  (mealID)=>{
    let selected = `chosen_${this.props.day.date}_${mealID}`
    let selected_date = this.props.day.date
    if (this.props.productReducer[selected]) {

      this.props.changeOndDaySeletedMealToFalse(selected) &&
      this.props.decrease_number_of_selected_meals(selected_date)    }
    else {
      this.props.changeOndDaySeletedMealToTrue(selected)
      this.props.increase_number_of_selected_meals(selected_date)

    }

    
}


back = e => {
  e.preventDefault();
  this.props.handlePreviousStep(4);
}




render() {

  if (this.props.loading) {
    return <Loading />
  } else {

  let locale = this.props.locale
  let lang = locale ? ar : en

  let day = this.props.day

  let myday = new Date(day.date)


  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  let mealDate = locale? myday.toLocaleDateString('ar-EG',options ) : myday.toLocaleDateString(undefined, options)



let total_selectedd = `total_selectedd_for_${this.props.day.date}`


let slectRemaining  = 0
this.props.productReducer[total_selectedd] > 0 ? slectRemaining = this.props.selectedSub.number_of_meals - this.props.productReducer[total_selectedd] :  slectRemaining = this.props.selectedSub.number_of_meals

    const oneDayMeals = day.meals.map(meal => (
    <OneDayMeals key={meal.id} meal={meal}  checkSelectedMeal={this.checkSelectedMeal}  />));

    var Header = ''
    var backBottomText =''
    if (slectRemaining === 1){
     backBottomText = `${lang.Select} ${slectRemaining} ${lang.nom} ` 
    }
    else{
     backBottomText = `${lang.Select} ${slectRemaining} ${lang.noms} ` 
    }

    if (this.props.selectedSub.number_of_meals === 1){
     Header = `${lang.Select} ${this.props.selectedSub.number_of_meals} ${lang.nom} ` 
    }
    else{
     Header = `${lang.Select} ${this.props.selectedSub.number_of_meals} ${lang.noms} ` 
    }



    var backBottomDisable = false
    if (this.props.productReducer[total_selectedd] === this.props.selectedSub.number_of_meals){
       backBottomDisable = false
       backBottomText = lang.Back_To_Recomended_Meals
    }
    else{
       backBottomDisable = true

    }


    return (

<div className="container " >
        <div className="row justify-content-md-center " >

              <div className="col-lg-9 col-12 ">

                        <h4 className="centerHeader logoColor yourPlane mb-4">{Header}</h4>
                        <h5 className="centerHeader logoColor  mb-4">{mealDate} </h5>
                        <hr className="mb-4"/>
                        <div className="row justify-content-md-center " >
                            {oneDayMeals}
                        </div>
                        <hr className="mb-4"/>



              </div>


              <button 
                        type="button" 
                        onClick={this.back}  
                        disabled={backBottomDisable}
                        title={backBottomText}
                        style={backBottomDisable ? {}:{fontSize:"15px"}}

                        className="btn btn-block btn-lg btn-outline-warning custom-button-new-address  centerHeader m-auto   col-md-9  col-sm-8  col-11  col-lg-8 "
                        > 
                        <strong> <i className="fas fa-utensils mr-2"></i>   {backBottomText} </strong> 
              </button> 
        </div>

              <br/>
              <br/>
</div>



      );
    }}
}
  
  
  const mapStateToProps = state => {
    return {
      selectedSub: state.productReducer.selectedSub,
      productReducer: state.productReducer,
      day: state.productReducer.ondDayMeals,
      loading: state.productReducer.loadingOndDayMeals,
      step: state.productReducer.step,
      locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleSeletedSub: (id) => dispatch(handleSeletedSub(id)),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        changeOndDaySeletedMealToFalse: (selected) => dispatch(changeOndDaySeletedMealToFalse(selected)),
        changeOndDaySeletedMealToTrue: (selected) => dispatch(changeOndDaySeletedMealToTrue(selected)),
        increase_number_of_selected_meals: (selected) => dispatch(increase_number_of_selected_meals(selected)),
        decrease_number_of_selected_meals: (selected) => dispatch(decrease_number_of_selected_meals(selected)),
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MealsList));
  