import React, { Component } from "react";
import { connect } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {handleNextRegistrationStep} from '../../../store/actions/authActions'

// Actions
import { signupVerify,signupSendOTP } from "../../../store/actions/authActions";
import { resetErrors } from "../../../store/actions/errorActions";

// Components
import TextFieldGroup from "../../common/TextFieldGroup";

import ar from '../../../assets/locales/ar.json'
import en from '../../../assets/locales/en.json'

class OTP extends Component {
    state = {
        pin: "",
        timer:180,
        isDisabled:false,
        buttonMsg:' Send PIN'

      }
      componentDidMount(){
        this.props.signupSendOTP(this.props.new_user)

        var intervalId = setInterval(this.timer, 1000);
        this.setState({intervalId: intervalId, isDisabled:true ,pin_changed:true,
        });
    
        this.props.resetErrors()

      }

      componentWillUnmount() {
        clearInterval(this.state.intervalId);
    
        }

backStep =()=>{
  this.props.handleNextRegistrationStep(1)
}


sendOTP =()=>{
    this.props.signupSendOTP(this.props.new_user)

    var intervalId = setInterval(this.timer, 1000);
    this.setState({intervalId: intervalId, isDisabled:true ,pin_changed:true,
    });

    this.props.resetErrors()

}


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    
    let errorChanged = `${e.target.name}_changed`
    this.setState({[errorChanged]:false})


  }

  onSubmit = e =>{
    e.preventDefault();
    const pin = this.state.pin;
    let newUser = this.props.auth.new_user
    
    newUser['pin'] =  pin
    
    this.props.signupVerify(newUser);
    
    this.setState({
      pin_changed:true,
      
    })

    this.props.resetErrors()

  }
  


  timer = ()=> {
    // setState method is used to update the state
    // this.setState({ timer: this.state.timer -1 });


    var newCount = this.state.timer - 1;
    if(newCount >= 0) { 
        this.setState({ timer: newCount });
    } else {
        clearInterval(this.state.intervalId);
        this.setState({timer:180,isDisabled:false,buttonMsg:"try again"})
    }
 }
  
  
  render() {
    const { errors, } = this.props;
    let timerMMSS =  new Date(this.state.timer * 1000).toISOString().substr(14, 5)


    let locale = this.props.locale
    let lang = locale ? ar : en
    

    
    let label= this.state.isDisabled ? 
    
    <strong className="requiredField" >{lang.auth.OTPSent}  <span className="requiredColor phoneNumberField" > {this.props.new_user.phone_number} </span> . </strong> 
    : 
    <strong className="requiredField" >{lang.auth.OTPSend}  <span className="requiredColor phoneNumberField" > {this.props.new_user.phone_number} </span> {lang.question} </strong>


    return (
      <div className={locale? 'rtl login': "ltr login"}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">{lang.auth.verification}</h1>
              <br/>
              {/* <p className="lead text-center">Verify Your Number</p> */}
              <form className="needs-validation"  onSubmit={this.onSubmit} >
{/* far fa-comment-dots */}
                <TextFieldGroup
                  inputCustomClassName={locale?"textFieldGroup_rtl noBorderColer":"textFieldGroup_ltr noBorderColer"}
                  name="pin"
                  isDisabledButton={this.state.isDisabled}
                  IconLeft={locale? ' fas fa-sms icon-left-with-button_rtl': ' fas fa-sms icon-left-with-button'} 
                  ButtonLeftText={this.state.isDisabled ? timerMMSS :lang.auth.clickToSentOTP}
                  ButtonLeftClassName=' verifyButton btn btn-dark'
                  ButtonLeft={this.sendOTP}
                  type="number"
                  label= {label} 
                  placeholder={lang.auth.pinPlaceholder }
                  IconRightTimer={'Timer icon-eye is-reverse'}
                  // IconRightText={timerMMSS}
                  value={this.state.pin || ""}
                  required={true}
                  onChange={this.onChange}
                  errors={this.state.pin_changed && (errors.response || errors.non_field_errors || errors[0])}
                />

                {/* <div className="col-md-12 mb-3">
                  <button type="submit" className="btn btn-lg btn-outline-warning custom-detail-meal  btn-block  mt-4 mx-2" > Register</button>       
                </div>
                <div className="col-md-12 mb-3">
                  <button onClick={this.backStep} type="button" className="btn btn-lg btn-outline-warning custom-detail-meal  btn-block  mt-4 mx-2" > Back</button>       
                </div> */}


                <div className={ 'ltr row justify-content-center recomendedMeal'} >
                        <button  
                            onClick={this.backStep} 
                            type="button"
                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-3    mr-3 col-md-4  col-sm-4  col-5"
                            style={{marginTop:'.5rem'} }> <strong className="ButtonText"> <i className="fas fa-user-edit mr-2"></i>  {lang.Back}</strong>   
                            
                        </button>
                        <button  
                            disabled={this.state.pin === ""} 
                            title={this.state.pin === "" ? "Please enter the code" :  "Register"}
                            type="submit"
                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-3   col-md-4  col-sm-4  col-5 "
                            style={this.state.pin === "" ? {filter:"blur(0.7px)opacity(0.5)" , marginTop:'.5rem'} :{ marginTop:'.5rem'}}>
                            <strong className="ButtonText ">   {lang.auth.signUp} <i className="fas fa-user-plus ml-2"></i> </strong>   
                        </button>
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errorReducer.errors,
  date_of_birth: state.productReducer.date_of_birth,
  new_user: state.auth.new_user,
  locale: state.productReducer.locale,

});


// const mapDispatchToProps = dispatch => {
//   return {
//     signupSendOTP: (email) => dispatch(signupSendOTP(email)),
//     handleNextRegistrationStep: (step) => dispatch(handleNextRegistrationStep(step))
//   };
// };

export default connect(
  mapStateToProps,
  { signupVerify, resetErrors,handleNextRegistrationStep,signupSendOTP }
)(OTP);
