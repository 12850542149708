import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import notFound from '../../assets/images/nf.png'
import { handleSeletedSub,handleNextStep,change_selected_sub_to_true } from "../../store/actions/productAction";
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class CarouselItems extends Component {

    checkSelected = async (sub) => {

      if (this.props.selectedSub.id !== undefined && this.props.selectedSub.id !== sub.id){

        this.props.change_selected_sub_to_true()
      }



      await this.props.handleSeletedSub(sub);

      this.props.handleNextStep(3);
      
    
    }

  render() {
    

        
    let locale = this.props.locale
    let lang = locale ? ar : en


    let subscription = this.props.subscription
    const myImage = {  backgroundImage:`url(${subscription.image ? subscription.image : notFound})` }


    let index = this.props.index

    let customClass = locale ? "textCaptionBody arDir": "textCaptionBody "

      return (      

          // <div   >
          <Link to={`/subscribe`} className={`carousel-item ${index === 0 && "active" } `} data-interval="10000"  onClick={ () => this.checkSelected(subscription)}>
              <div 
                  id = {index}  
                  className=" mb-4 pr-0 pl-0 card bg-light text-black  cardStyle  "
                  style={ myImage }
                  
                  >
              </div> 
              <div className="carousel-caption d-md-block textCaption">
                  <h3 className={customClass}>{locale ? subscription.name_ar: subscription.name}</h3>
                  <h5 className={customClass}>{locale ? subscription.period_name_ar : subscription.period_name}, {locale ? subscription.displayed_number_ar:subscription.displayed_number},{lang.no} {subscription.number_of_meals}  { subscription.number_of_meals > 1 ? lang.noms : lang.nom} </h5>
                  <h5 className={customClass} >{subscription.price}  {lang.currency}</h5>
              </div> 
          {/* </div> */}
          </Link>

      )

    
    }
  }

const mapStateToProps = state => {
  return {
    loadingOrder: state.orderReducer.loading,
    orders: state.orderReducer.orders,
    auth: state.auth,
    loadingSub: state.productReducer.loadingSubs,
    subscriptions: state.productReducer.subscriptions,
    selectedSub: state.productReducer.selectedSub,
    locale: state.productReducer.locale,


  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSeletedSub: (sub) => dispatch(handleSeletedSub(sub)),
    handleNextStep: (step) => dispatch(handleNextStep(step)),
    change_selected_sub_to_true: () => dispatch(change_selected_sub_to_true()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CarouselItems);
