import {
    GET_ORDERS,
    UPDATE_ORDER_MEALS,
    RESET_STORE,
    GET_ORDER_DETAIL,
    GET_KICHEN_MEALS,
    GET_LOGISTICS_ORDER_MEALS,
    GET_IS_MAX_ORDERS,
    // GET_NEXT_ORDER_STEP,
    // GET_ORDER_MEALS,

  
  } from "../actions/types";
  
  const initialState = {
    orders: [],
    order: undefined,
    loading: true,
    loadingOrder: true,
    kitchen_meals: [],
    loading_kitchen_meals: true,
    logistics_order_meals: [],
    loading_logistics_order_meals: true,
    isMax:{is_max:false},
    loadingIsMax:true
    // step : 1,
    // order_meals:[]

  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case RESET_STORE:
          return {
            ...state,
            orders:[],
            order:[],
            loading:true,
            loadingOrder:true
          };
      case GET_ORDERS:
        return {
          ...state,
          orders: action.payload,
          loading: false,
          
        };
      case GET_IS_MAX_ORDERS:
        return {
          ...state,
          isMax: action.payload,
          loadingIsMax: false,
          
        };
      case GET_ORDER_DETAIL:
        return {
          ...state,
          order: action.payload,
          loadingOrder: false,
          
        };
      case GET_KICHEN_MEALS:
        return {
          ...state,
          kitchen_meals: action.payload,
          loading_kitchen_meals: false,
          
        };
      case GET_LOGISTICS_ORDER_MEALS:
        return {
          ...state,
          logistics_order_meals: action.payload,
          loading_logistics_order_meals: false,
          
        };
      case UPDATE_ORDER_MEALS:
        return {
          ...state,
          loadingOrder: true,
          updated_order_meals: action.payload,
          // loading: false,
          
        };
        // case GET_NEXT_ORDER_STEP:
        //   return {
        //     ...state,
        //     step: action.payload,    
        //   };
        // case GET_ORDER_MEALS:
        //   return {
        //     ...state,
        //     order_meals: action.payload,    
        //   };

      default:
        return state;
    }
  };
  