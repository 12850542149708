import React, { Component } from "react";
import { connect } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import "../../assets/css/styles.css";

// Actions
import { updateUserProfile } from "../../../store/actions/authActions";
import { resetErrors } from "../../../store/actions/errorActions";

import Loading from "../../Loading";

// Components
import TextFieldGroup from "../../common/TextFieldGroup";
import 'react-day-picker/lib/style.css';

import ar from '../../../assets/locales/ar.json'
import en from '../../../assets/locales/en.json'


class ChangePassword extends Component {
    state = {
        password: "",
        password2:"",
        current_password:"",
      };
    

  async componentDidMount(){
  }



  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    
    let errorChanged = `${e.target.name}_changed`
    this.setState({[errorChanged]:false})


  }


  onSubmit = e =>{
    let locale = this.props.locale
    let lang = locale ? ar : en
    e.preventDefault();
    this.props.updateUserProfile({
        password2:this.state.password2,
        password:this.state.password,
        current_password:this.state.current_password,

    },lang.sucssess.passwordUpdated,"password");

    this.setState({
      password_changed:true,
      password2_changed:true,
      current_password_changed:true,
    })




    this.props.resetErrors()

  }

   TogglePassword = (elementId) => {
    var x = document.getElementById(elementId);
    if (x.type === "password") {
        x.type = "text";
    } else {
        x.type = "password";
    }
}


  render() {
    const { errors, } = this.props;

    let locale = this.props.locale
    let lang = locale ? ar : en
    let inputCustomClassName= locale?" textFieldGroup_rtl":"textFieldGroup_ltr"

    const { isAuthenticated } = this.props.auth;
    isAuthenticated === false && this.props.history.push('/login')
    
    if (this.props.lodaingProfile) {
        return <Loading />
        }
        else {

    return (
      <div className={locale? 'rtl login': "ltr login "}>
        <div className="container onNaveChange">
          <div className="row ">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center mb-4 logoColor ">{lang.auth.updatePassword}</h1>

              <form className="needs-validation "  onSubmit={this.onSubmit} >
                      <TextFieldGroup
                                  
                        inputCustomClassName={inputCustomClassName}
                        name="current_password"
                        IconLeft='fas fa-lock icon-left'
                        IconRight={ locale?'fa fa-eye icon-eye is-reverse_rtl': 'fa fa-eye icon-eye is-reverse'}
                        type="password"
                        toggleID='current_password'
                        label= {lang.auth.currentPasswrd}
                        placeholder={lang.auth.PasswrdPlaceholder}
                        value={this.state.currentPassword}
                        required ={true}

                        onChange={this.onChange}
                        errors={this.state.current_password_changed  && (errors.current_password || errors.non_field_errors)}
                      />
                      <TextFieldGroup
                                  
                        inputCustomClassName={inputCustomClassName}
                        name="password"
                        IconLeft='fas fa-lock icon-left'
                        IconRight={ locale?'fa fa-eye icon-eye is-reverse_rtl': 'fa fa-eye icon-eye is-reverse'}
                        type="password"
                        toggleID='password'
                        label= {lang.auth.newPasswrd}
                        placeholder={lang.auth.PasswrdPlaceholder}
                        value={this.state.password}
                        required ={true}

                        onChange={this.onChange}
                        errors={this.state.password_changed  && (errors.password || errors.non_field_errors)}
                      />


                      <TextFieldGroup
                                  
                        inputCustomClassName={inputCustomClassName}
                        name="password2"
                        IconLeft='fas fa-lock icon-left'
                        IconRight={ locale?'fa fa-eye icon-eye is-reverse_rtl': 'fa fa-eye icon-eye is-reverse'}
                        type="password"
                        toggleID='password2'
                        label= {lang.auth.confirmPasswrd}
                        required ={true}

                        placeholder={lang.auth.PasswrdPlaceholder}
                        value={this.state.password2}
                        onChange={this.onChange}
                        errors={this.state.password2_changed && (errors.password2 || errors.non_field_errors)}
                      />



                      <div className="row justify-content-center recomendedMeal " style={{direction: "ltr"}}>
                        <button  
                            onClick={()=>this.props.history.push('/profile')}
                            type="button"

                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-4    mr-3 col-md-4  col-sm-4  col-5"
                            style={{marginTop:'.5rem'} }> <strong className="ButtonText">   {lang.auth.account}</strong>   
                        </button>

                        <button  
                            type="submit"
                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-4   col-md-4  col-sm-4  col-5 "
                            style={{ marginTop:'.5rem'}}>
                            <strong className="ButtonText ">   {lang.auth.update}  </strong>   
                        </button>
                </div>

                    </form>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
            </div>
    );
  }
}

}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errorReducer.errors,

  locale: state.productReducer.locale,
  lodaingProfile:state.auth.lodaingProfile,


});

// const mapDispatchToProps = dispatch => {
//   return {
//     signup: (email) => dispatch(signup(email)),
//     handleNextRegistrationStep: (step) => dispatch(handleNextRegistrationStep(step))
//   };
// };

export default connect(
  mapStateToProps,
  {   updateUserProfile,  resetErrors}
)(ChangePassword);
