import React, { Component } from "react";
import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import { fetchOrderDetail } from "../../store/actions/orderActions";
import Loading from "../Loading";
import OrderMealList from './OrderMealList'
import notfound from '../../assets/images/404.png'
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class OrderDetail extends Component {
  state = {
    myOrder:undefined
  };

  componentDidMount() {
    let orderID = this.props.match.params.orderID;
    if (this.props.order === undefined || this.props.order.id !== + orderID){
      this.props.fetchOrderDetail(orderID)
  }

  }

    render() {
      let history = this.props.history

      const { isAuthenticated } = this.props.auth;
      let location =  this.props.location.pathname
      isAuthenticated === false && this.props.history.push('/login',{ from: location })


      if (this.props.errors.code === "404" ) {
        return <div>
        <img src={notfound} alt="bg" className="payment-img"/>
    </div>
    }

        let order = this.props.order

        if (order === undefined ) {
            return <Loading />
        }
        if (this.props.loading ) {
            return <Loading />
        } else {
            
        const orderMealList = order.order_meals && order.order_meals.map((order_meal, index) => (
            <OrderMealList key={order_meal.id} index={index} order_meal={order_meal} number_of_meals={order.subscription.number_of_meals}  end_date={order.end_date} />
            ));

        let locale = this.props.locale
        let lang = locale ? ar : en





        let startDate = new Date(order.start_date)
        let endDate = new Date(order.end_date)
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        let start_date = locale? startDate.toLocaleDateString('ar-EG',options ) : startDate.toLocaleDateString(undefined, options)
        let end_date = locale? endDate.toLocaleDateString('ar-EG',options ) : endDate.toLocaleDateString(undefined, options)

        


        return (
                
            <div  className="container" >
                <h6 className="centerHeader logoColor mt-4 onNaveChange  mb-4">{start_date} - {end_date}</h6>

                <div className="row justify-content-md-center mr-0 ml-0" >

                    {orderMealList}
                </div>

                <div className="centerBox"> 

                  <button className={`col-xl-10 col-lg-10 col-md-10 col-12 btn btn-lg btn-outline-warning custom-detail-meal  btn-block  mt-4 `} onClick={()=>history.push("/orders")}><strong><i className="far fa-calendar-alt mr-2"></i> {lang.Back}</strong> </button>
                </div>

                <br/>
              <br/>
            </div>
        );
        }
    } 
    }
  const mapStateToProps = state => {
    return {
      loading: state.orderReducer.loadingOrder,
      orders: state.orderReducer.orders,
      order: state.orderReducer.order,
      auth: state.auth,
      errors: state.errorReducer.errors,
      locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      fetchOrderDetail: (orderID) => dispatch(fetchOrderDetail(orderID)),
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetail));
  