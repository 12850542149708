import React, { Component } from "react";
import {withRouter,} from "react-router-dom"
import { connect } from "react-redux";
import Meals from './Meals'
class OrderMeals extends Component {
    state = {
    }




    render() {
        let orderMeal = this.props.orderMeal
        let index = this.props.index
        let myMeals =orderMeal.meals



        const meals = myMeals.length > 0 &&myMeals.map((myMeal,index) => (
            <Meals key={index}  myMeal={myMeal} />
          ));


        return (



            <tr >
            <th data-label="#" scope="row">{index + 1}</th>
            <td data-label="Order Id">{orderMeal.id}</td>
            <td data-label="Full Name">{orderMeal.full_name}</td>
            <td data-label=" Phone Number ">{orderMeal.phone_number} </td>
            <td data-label=" Delivery Option ">{orderMeal.delivery_option}</td>
            <td data-label=" Address">{orderMeal.address} </td>
            <td data-label=" map Url "> <a href={orderMeal.mapUrl}> Map </a>  </td>
            <td data-label=" Meals ">
            <ul className="list-inline">

            {meals} 
            </ul>

            </td>
            <td data-label=" Date " >{orderMeal.date} </td>
            <td data-label=" Subscription " >{orderMeal.subscription}  </td>
          </tr>




        )
    }
}

const mapStateToProps = state => {
    return {



    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderMeals));
  