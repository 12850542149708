import React, { Component } from "react";
import { connect } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import "../../assets/css/styles.css";

// Actions
import { updateUserProfile,getUserProfile } from "../../../store/actions/authActions";
import { resetErrors } from "../../../store/actions/errorActions";
import Loading from "../../Loading";


// Components
import TextFieldGroup from "../../common/TextFieldGroup";
import 'react-day-picker/lib/style.css';

import ar from '../../../assets/locales/ar.json'
import en from '../../../assets/locales/en.json'


class userProfile extends Component {
    state = {
        first_name: "",
        last_name: "",
        phone_number: "",
        email:"",
        email2:"",
        date_of_birth: null,
        weight: null,
        height: null,
        level_of_physical_activity: "",
        blood_type: "",
        sex: "",
      };
    

  async componentDidMount(){
    await this.props.getUserProfile()


    let userProfile = this.props.userProfile
    userProfile.phone_number && 
    this.setState({
      first_name: userProfile.first_name,
      last_name: userProfile.last_name,
      phone_number: userProfile.phone_number,
      email:userProfile.email,
      email2:userProfile.email,
      date_of_birth: userProfile.date_of_birth,
      weight: userProfile.weight,
      height: userProfile.height,
      level_of_physical_activity: userProfile.level_of_physical_activity,
      blood_type: userProfile.blood_type,
      sex: userProfile.sex

    })
  }



  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    
    let errorChanged = `${e.target.name}_changed`
    this.setState({[errorChanged]:false})


  }


  onSubmit = e =>{
    let locale = this.props.locale
    let lang = locale ? ar : en
    e.preventDefault();
    this.props.updateUserProfile({
        date_of_birth: this.state.date_of_birth,
        email: this.state.email,
        email2: this.state.email2,
        weight: this.state.weight,
        height: this.state.height,
        level_of_physical_activity: this.state.level_of_physical_activity,
        blood_type: this.state.blood_type,
        sex: this.state.sex,
      

    }, lang.sucssess.userUpdated,"userInfo");

    this.setState({
      phone_number_changed:true,
      first_name_changed:true,
      last_name_changed:true,
      blood_type_changed:true,
      height_changed:true,
      weight_changed:true,
      level_of_physical_activity_changed:true,
      sex_changed:true,
      email_changed:true,
      email2_changed:true,
      date_of_birth_changed:true,
    })




    this.props.resetErrors()

  }



  render() {
    const { errors, } = this.props;

    let locale = this.props.locale
    let lang = locale ? ar : en
    let inputCustomClassName= locale?" textFieldGroup_rtl":"textFieldGroup_ltr"


    const { isAuthenticated } = this.props.auth;
    isAuthenticated === false && this.props.history.push('/login')

    if (this.props.lodaingProfile) {
      return <Loading />
      }
      else {

    return (
      <div className={locale? 'rtl login': "ltr login"}>
        <div className="container onNaveChange">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center mb-4 logoColor">{lang.auth.account}</h1>

              <form className="needs-validation"  onSubmit={this.onSubmit} >
                <div className="form-row">
                    <div className="form-group col-md-6">
                      <TextFieldGroup
                        inputCustomClassName={locale?" phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}
                        disabled={true}
                        name="phone_number"
                        IconLeft='fas fa-mobile-alt icon-left'

                        label={lang.auth.phoneNumber}
                        placeholder={lang.auth.phoneNumberPlaceholder}
                        value={this.state.phone_number}
                        onChange={this.onChange}
                        errors={this.state.phone_number_changed  &&(errors.phone_number || errors.non_field_errors)}
                        required ={true}
                      />
                    </div>
                    <div className="form-group col-md-6">



                      <TextFieldGroup
                        inputCustomClassName={inputCustomClassName}

                        disabled={true}

                        name="date_of_birth"
                        IconLeft='far fa-calendar-alt icon-left'
                        type="text"
                        label={lang.auth.DOB}  
                        placeholder="YYYY-MM-DD"
                        value={this.state.date_of_birth || ""}
                        dataTarget="#mealDetail"
                        dataToggle="modal"
                        // readOnly={true}
                        required={true}
                        onChange={this.onChange}
                        errors={this.state.date_of_birth_changed && (errors.date_of_birth)}
                      />
                    </div>
                      <div className="form-group col-md-6">

                      <TextFieldGroup
                        disabled={true}

                        inputCustomClassName={inputCustomClassName}
                        name="first_name"
                        IconLeft='fas fa-user icon-left'
                        type="text"
                        label={lang.auth.firstName}
                        placeholder={lang.auth.firstNamePlaceholder}
                        value={this.state.first_name}
                        required ={true}

                        onChange={this.onChange}
                        errors={this.state.first_name_changed && (errors.first_name || errors.non_field_errors)}
                      />
                      </div>
                      <div className="form-group col-md-6">

                      <TextFieldGroup
                        inputCustomClassName={inputCustomClassName}
                        disabled={true}

                        name="last_name"
                        IconLeft='far fa-user icon-left'
                        type="text"
                        label={lang.auth.lastName} 
                        required ={true}

                        placeholder={lang.auth.lastNamePlaceholder}
                        value={this.state.last_name}
                        onChange={this.onChange}
                        errors={this.state.last_name_changed && (errors.last_name || errors.non_field_errors)}
                      />
                      </div>

                      <div className="form-group col-md-6">
                      <TextFieldGroup
                        inputCustomClassName={locale?" phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}


                        name="email"
                        IconLeft='far fa-user icon-left'
                        type="email"
                        label=  {lang.auth.email}
                        required ={true}

                        placeholder={lang.auth.emailPlaceholder}
                        value={this.state.email}
                        onChange={this.onChange}
                        errors={this.state.email_changed && (errors.email || errors.non_field_errors)}
                      />
                      </div>
                      <div className="form-group col-md-6">
                      <TextFieldGroup
                                  
                        inputCustomClassName={locale?" phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}

                        name="email2"
                        IconLeft='far fa-user icon-left'
                        type="email"
                        label={lang.auth.confirmEmail} 
                        required ={true}

                        placeholder={lang.auth.emailPlaceholder}
                        value={this.state.email2}
                        onChange={this.onChange}
                        errors={this.state.email2_changed && (errors.email2 || errors.non_field_errors)}
                      />
                      </div>


                      <div className="form-group col-md-6">
                      <TextFieldGroup
                                  
                        inputCustomClassName={locale?" phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}
                        name="height"
                        IconLeft='fas fa-arrows-alt-v icon-left'
                        type="number"
                        label={lang.auth.height}
                        placeholder={lang.auth.heightPlaceholder}
                        value={this.state.height || ""}
                        onChange={this.onChange}
                        errors={this.state.height_changed && (errors.height || errors.non_field_errors)}
                      />
                      </div>
                      <div className="form-group col-md-6">
                      <TextFieldGroup
                                  
                        inputCustomClassName={locale?" phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}
                        name="weight"
                        IconLeft='fas fa-weight icon-left'
                        type="number"
                        label={lang.auth.weight}
                        placeholder={lang.auth.weightPlaceholder}
                        value={this.state.weight || ""}
                        onChange={this.onChange}
                        errors={this.state.weight_changed && (errors.weight || errors.non_field_errors)}
                      />
                      </div>

      

      <div className="form-group col-md-6">

      <div className="col-md-12 mb-3">
      <label className= " mx-2" htmlFor='blood_type'>{lang.auth.bloodType}</label>
      <div className="input-group mx-2" >
      <i className='fas fa-notes-medical icon-left'></i>  


          <select 
          id="blood_type"
          name="blood_type"
          className= {`custom-select ${inputCustomClassName} ${ this.state.blood_type_changed &&(errors.blood_type || errors.non_field_errors)  && "is-invalid"}`} 
          // defaultValue={this.state.blood_type}
          onChange={this.onChange}
          value={this.state.blood_type}

          >
          
            <option value="">{lang.choose}</option>
            <option value="AP">A+</option>
            <option value="AN">A-</option>
            <option value="OP">O+</option>
            <option value="ON">O-</option>
            <option value="BP">B+</option>
            <option value="BN">B-</option>
            <option value="ABP">AB+</option>
            <option value="ABN">AB-</option>
          </select>


            {(this.state.blood_type_changed && errors.blood_type ) && errors.blood_type.map((error ,  index) => 
                  <div className="invalid-feedback" key={index}>
                  <ul style={{paddingLeft:"18px", marginTop:"0px", marginBottom:"0px"}}>
                    <li >
                      {error}
                    </li>
                  </ul>
                  </div>)
              }
            </div>
        </div>
      </div>



      <div className="form-group col-md-6">
      <div className="col-md-12 mb-3">
      <label className=" mx-2" htmlFor='level_of_physical_activity'>{lang.auth.LOPA}</label>
      <div className="input-group mx-2">
      <i className='fas fa-running icon-left'></i>  


          <select 
          id="level_of_physical_activity"
          name="level_of_physical_activity"
          className={`custom-select ${inputCustomClassName} ${ this.state.level_of_physical_activity_changed &&(errors.level_of_physical_activity || errors.non_field_errors)  && "is-invalid"}`} 
            
          onChange={this.onChange}
          value={this.state.level_of_physical_activity}

          >
            <option value="">{lang.choose}</option>
            <option value="N">{lang.auth.notActive}</option>
            <option value="A">{lang.auth.active}</option>
            <option value="G">{lang.auth.athletic}</option>

          </select>
            {(this.state.level_of_physical_activity_changed && errors.level_of_physical_activity ) && errors.level_of_physical_activity.map((error ,  index) => 
                  <div className="invalid-feedback" key={index}>
                  <ul style={{paddingLeft:"18px", marginTop:"0px", marginBottom:"0px"}}>
                    <li >
                      {error}
                    </li>
                  </ul>
                  </div>)
              }
            </div>
        </div>


      </div>
      </div>





       <div   className={`col-md-12 mb-3  ${ this.state.sex_changed &&(errors.sex || errors.non_field_errors)  && "is-invalid"}  `} >
      
          <label className=" mx-2" htmlFor='sex'>{lang.auth.gender}</label>


          <div className={` custom-control custom-radio custom-control-inline ${ this.state.sex_changed &&(errors.sex || errors.non_field_errors)  && "is-invalid"} `}>
          <input className="form-check-input custom-radio" type="radio" onChange={this.onChange} name='sex' id="exampleRadios1"  value='M'  checked={this.state.sex === "M"}/>
              <label className="form-check-label" style={locale?{"marginRight":"26px"}:{"marginLeft":"10px"}} htmlFor="exampleRadios1">
              {lang.male}
              </label>
          </div>

          <div className={` custom-control custom-radio custom-control-inline ${ this.state.sex_changed &&(errors.sex || errors.non_field_errors)  && "is-invalid"} `}>
          <input className="form-check-input" type="radio" onChange={this.onChange} name='sex' id="exampleRadios2" value='F' checked={this.state.sex === "F"} />
              <label className="form-check-label" style={locale?{"marginRight":"26px"}:{"marginLeft":"10px"}} htmlFor="exampleRadios2">

              {lang.female}
              </label>
          </div>

          {(this.state.sex_changed && errors.sex ) && errors.sex.map((error ,  index) => 
                  <div className="invalid-feedback" key={index}>
                  <ul style={{paddingLeft:"18px", marginTop:"0px", marginBottom:"0px"}}>
                    <li >
                      {error}
                    </li>
                  </ul>
                  </div>)
              }


      <br/>

      </div>



      



                      <div className="row justify-content-center recomendedMeal " style={{direction: "ltr"}}>
                        <button  
                            onClick={()=>this.props.history.push('/profile/password')}
                            type="button"

                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-4    mr-3 col-md-4  col-sm-4  col-5"
                            style={{marginTop:'.5rem'} }> <strong className="ButtonText">  {lang.auth.updatePassword}</strong>   
                        </button>




                        <button  
                            type="submit"
                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-4   col-md-4  col-sm-4  col-5 "
                            style={{ marginTop:'.5rem'}}>
                            <strong className="ButtonText ">   {lang.auth.update}  </strong>   
                        </button>
                </div>

                    </form>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
            </div>
    );
  }
}
}


const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errorReducer.errors,

  locale: state.productReducer.locale,

  userProfile: state.auth.userProfile,
  lodaingProfile:state.auth.lodaingProfile,

});

// const mapDispatchToProps = dispatch => {
//   return {
//     signup: (email) => dispatch(signup(email)),
//     handleNextRegistrationStep: (step) => dispatch(handleNextRegistrationStep(step))
//   };
// };

export default connect(
  mapStateToProps,
  {   updateUserProfile, getUserProfile, resetErrors}
)(userProfile);
