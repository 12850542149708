import axios from "axios";
import jwt_decode from "jwt-decode";
import { Loading, USER_PROFILE,SET_CURRENT_USER,GET_ERRORS ,RESET_STORE,NEW_USER,GET_NEXT_RESETPASSWORD_STEP ,GET_PREVIOUS_REGISTRATION_STEP,GET_NEXT_REGISTRATION_STEP,RESET_PHONE_NUMBER,RESET_PASSWORD,TOGGLE_LOADING} from "./types";
import setAuthToken from "../../utils/setAuthToken";

import {errorNotification,successNotification} from '../actions/errorActions'







// const headerAr = {
//     'Accept-Language': 'ar',
// }
// const headerEn = {
//     'Accept-Language': 'en',
// }

// const instance = axios.create({
//   baseURL: `${process.env.REACT_APP_SERVER_URL}`
// });  




let timeout  = 0

export const loginUser = (userData, history, from) => {
  return async dispatch => {
    try {
      let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/signin/`,userData);

      // let response = await instance.post("api/signin/", userData
      // ,{headers: locale?headerAr:headerEn}
      // );

      let user = response.data;
      setAuthToken(user.token);
      let decodedUser = jwt_decode(user.token);
      
      let msg = `Welcome ${decodedUser.full_name}` 
      successNotification(msg , "success")

      dispatch(setCurrentUser(decodedUser));

      timeout = setTimeout( () => dispatch(refreshToken()) , 720000)

      // history.goForward()
      history.push(from);
      
    } catch (error) {
      
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      
      
      
      errorNotification(error.response , 'danger')
      
      
    }
  };
};

export const refreshToken = () => {
  return async dispatch => {
    try {
      let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/refresh/`,
      {
        "token":localStorage.token
      }
      )
      
      let user = response.data;
      setAuthToken(user.token);
      let decodedUser = jwt_decode(user.token);
      
      dispatch(setCurrentUser(decodedUser));
      
      if( timeout !== 0 ){
        clearTimeout(timeout)
      }
      timeout = setTimeout( () => dispatch(refreshToken()) , 720000)      
      
    } catch (error) {
      dispatch(logoutUser())
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });

      errorNotification(error.response , 'danger')


    }
  };
};

export const getUserProfile = () => {
  return async dispatch => {
    try {
      let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/user/info`);
      let user = response.data;

      dispatch({type: Loading,});
      dispatch({
        type: USER_PROFILE,
        payload: user
      });

    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });

      errorNotification(error.response , 'danger')

    }
  };
};

export const updateUserProfile = (updatedInfo,msg,type) => {

  
  return async dispatch => {
    try {
      let response = await axios.put(`${process.env.REACT_APP_SERVER_URL}api/user/info`,updatedInfo );
      let user = response.data;
      dispatch({type: Loading,});

      dispatch({
        type: USER_PROFILE,
        payload: user
      });
      successNotification(msg , "success")
      if(type === "password"){
        dispatch(logoutUser());
      }

    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });

      errorNotification(error.response , 'danger')

    }
  };
};



export const signUpNoOTP = (userData) => {
  return async dispatch => {
    try {
      // let response = await instance.post("api/signup/verify/otp/", userData);
      let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/signup/no/otp/`,userData);

      let user = response.data;      
      let decodedUser = jwt_decode(user.token);
      setAuthToken(user.token);
      dispatch(setCurrentUser(decodedUser));
      let msg = `Welcome ${decodedUser.full_name}` 
      successNotification(msg , "success")
    } catch (error) {
      console.error(error.response);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      errorNotification(error.response , 'danger')

    }
  };
};

export const signupValidate = (userData, step) => {
  return async dispatch => {
    try {
      // await instance.post("api/signup/validate/", userData);
      await axios.post(`${process.env.REACT_APP_SERVER_URL}api/signup/validate/`,userData);

      dispatch(newUser(userData));
      dispatch(handleNextRegistrationStep(step))

    } catch (error) {
      console.error(error.response);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });

      errorNotification(error.response , 'danger')



    }
  };
};


export const signupSendOTP = (userData, step) => {
  return async dispatch => {
    try {
      let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/signup/send/otp/`,userData);

      // let response = await instance.post("api/signup/send/otp/", userData);
      let user = response.data;

      userData['request_id'] =  user.request_id
      dispatch(newUser(userData));
      // dispatch(handleNextRegistrationStep(step))
      // let decodedUser = jwt_decode(user.token);
      // setAuthToken(user.token);
      // dispatch(setCurrentUser(decodedUser));
      // history.push("/");
    } catch (error) {
      console.error(error.response);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      errorNotification(error.response , 'danger')

    }
  };
};


export const signupVerify = (userData) => {
  return async dispatch => {
    try {
      // let response = await instance.post("api/signup/verify/otp/", userData);
      let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/signup/verify/otp/`,userData);

      let user = response.data;      
      let decodedUser = jwt_decode(user.token);
      setAuthToken(user.token);
      dispatch(setCurrentUser(decodedUser));
      let msg = `Welcome ${decodedUser.full_name}` 
      successNotification(msg , "success")
    } catch (error) {
      console.error(error.response);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      errorNotification(error.response , 'danger')

    }
  };
};

// reset step 1
export const userExist = (phoneNumber, step) => {
  return async dispatch => {
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}api/user/exist/`,phoneNumber);
      // await instance.post("api/user/exist/", phoneNumber);
      dispatch({
        type: RESET_PHONE_NUMBER,
        payload: phoneNumber.phone_number
      });
      
      dispatch(handleResetPasswordSteps(step))
      
      
    } catch (error) {
      console.error(error.response);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      errorNotification(error.response , 'danger')

    }
  };
};

// reset step 2
export const passwordValidate = (newPassword, step) => {
  return async dispatch => {
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}api/user/password/validate/`,newPassword);

      // await instance.post("api/user/password/validate/", newPassword);
      // let response = 
      // let data = response.data
      dispatch({
        type: RESET_PASSWORD,
        payload: newPassword.password
      });

      dispatch(handleResetPasswordSteps(step))
      
      
    } catch (error) {
      console.error(error.response);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      errorNotification(error.response , 'danger')

    }
  };
};

// reset step 3
export const passwordReset = (userData,step) => {
  return async dispatch => {
    try {
      let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/user/password/reset/`,userData);

      // let response = await instance.post("api/user/password/reset/", userData);
      let user = response.data;      
      let decodedUser = jwt_decode(user.token);
      setAuthToken(user.token);
      dispatch(setCurrentUser(decodedUser));
      dispatch(handleResetPasswordSteps(step))
      dispatch({type: TOGGLE_LOADING,});

    } catch (error) {
      console.error(error.response);

      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      errorNotification(error.response , 'danger')


    }
  };
};







export const handleResetPasswordSteps = (step) => ({
  type: GET_NEXT_RESETPASSWORD_STEP,
  payload: step,
  
});

export const handleNextRegistrationStep = (step) => ({
  type: GET_NEXT_REGISTRATION_STEP,
  payload: step,
  
});

export const handlePreviousRegistrationStep = (step) => ({
  type: GET_PREVIOUS_REGISTRATION_STEP,
  payload: step,
});

export const newUser = (userData) => ({
  type: NEW_USER,
  payload: userData
});


export const setCurrentUser = decoded => (
  {
  type: SET_CURRENT_USER,
  payload: decoded
});

export const resetAuthStore = () => ({
  type: RESET_STORE,
});


// Logout User
export const logoutUser = history => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem("token");
  // Remove auth header token
  setAuthToken(false);
  dispatch(resetAuthStore());
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  // Redirect to "/"

  clearTimeout(timeout)

  history&&history.push("/");
};

// export const checkForExpiredToken = () => {
//   return dispatch => {
//     // Get token
//     const token = localStorage.getItem("token");
//     if (token) {
//       const currentTime = Date.now() / 1000;
//       // Decode token and get user info
//       const user = jwt_decode(token);
//       // Check token expiration
//       if (user.exp >= currentTime) {
//         // Set auth token header although its not needed here because this is not a new login this
//         // get the token from the local storage and checks for it's expiry date
//         setAuthToken(token);
//         //to store the user locally in the reducer
//         dispatch(setCurrentUser(user));
//       } else {
//         dispatch(logoutUser());
//       }
//     }
//   };
// };