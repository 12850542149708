import {Loading, USER_PROFILE,GET_NEXT_REGISTRATION_STEP,GET_PREVIOUS_REGISTRATION_STEP, SET_CURRENT_USER,NEW_USER ,RESET_STORE,GET_NEXT_RESETPASSWORD_STEP,RESET_PHONE_NUMBER,RESET_PASSWORD} from "../actions/types";

import isEmpty from "../../utils/is-empty";

const initialState = {
  isAuthenticated: false,
  user: {},
  new_user:{},
  registration_step:1,
  reset_password_step:1,
  reset_phone_number:0,
  reset_password:"",
  userProfile:{},
  lodaingProfile:false

};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case RESET_PHONE_NUMBER:
      return {
        ...state,
        reset_phone_number: action.payload
      };
    case Loading:
      return {
        ...state,
        lodaingProfile:true,
      };
    case USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
        lodaingProfile:false

      };

    case RESET_PASSWORD:
      return {
        ...state,
        reset_password: action.payload
      };

    case NEW_USER:
      return {
        ...state,
        new_user:action.payload,
      };
    case GET_NEXT_REGISTRATION_STEP:
      return {
        ...state,
        registration_step:action.payload,
      };
    case GET_NEXT_RESETPASSWORD_STEP:
      return {
        ...state,
        reset_password_step:action.payload,
      };
    case GET_PREVIOUS_REGISTRATION_STEP:
      return {
        ...state,
        registration_step:action.payload,
      };
    case RESET_STORE: 
      return {
        ...state,
        registration_step:1,
        
        new_user:{},

        userProfile:{},


        reset_password_step:1,
        reset_phone_number:0,
        reset_password:"",
      };

    default:
      return state;
  }
}
