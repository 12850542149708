import React, { Component } from "react";
import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import { handleNextStep,handleMealsListForSelectedDay,handleSeletedSub ,changeOndDaySeletedMealToFalse,decrease_number_of_selected_meals ,increase_number_of_selected_meals,changeOndDaySeletedMealToTrue} from "../../store/actions/productAction";
// import _ from 'lodash';
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class DatesList extends Component {
  state = {
    selectedSub:0
  };



  checkSelectedMeal =  (date,mealID)=>{
    let selected = `chosen_${date}_${mealID}`

    if (this.props.productReducer[selected]) {
      this.props.changeOndDaySeletedMealToFalse(selected) &&
      this.props.decrease_number_of_selected_meals(date) 
    }
    else {
      this.props.changeOndDaySeletedMealToTrue(selected)
      this.props.increase_number_of_selected_meals(date)
    }
}



  componentDidMount(){
    const dates = this.props.dates
    // var mergedList = _.map(dates.meals, function(item){
    //   return _.extend(item, _.find(dates.default_meals, { meal: item.id }));
    // });

    dates.meals.forEach(meal => {
      let selected = `chosen_${dates.date}_${meal.id}`
      if(this.props.productReducer[selected] === undefined && meal.isDefault){
          this.checkSelectedMeal(dates.date,meal.id)
      }  
    })
}




continue = (date) => {
  this.props.handleNextStep(5);
  this.props.handleMealsListForSelectedDay(date)
}



    render() {
      let locale = this.props.locale
      let lang = locale ? ar : en

      
      // var mergedList = _.map(dates.meals, function(item){
        //   return _.extend(item, _.find(dates.default_meals, { meal: item.id }));
        // });
        
        // ######################################################
        // if you wanna merge two list
        
        // var isDefaultMeal
        // var mergedList = dates.meals.map(meal => {
          //   isDefaultMeal = dates.default_meals.find(default_meal => default_meal.meals.includes(meal.id) )
          //   return _.extend(meal,isDefaultMeal )
          // })
          // ######################################################
          
          const dates = this.props.dates
          const index = this.props.index + 1
          let title = ''
          let title_ar = ''
          let description = ''
          let description_ar = ''
          
          dates.meals.forEach(meal => {
            let selected = `chosen_${dates.date}_${meal.id}`
            if (this.props.productReducer[selected]){
              title += `${meal.name} + `
              title_ar += `${meal.name_ar} + `
              if (meal.description !== null){
                description += `${meal.description} + `
                description_ar += `${meal.description_ar} + `
              }
            }
            
          })
          
          let cleanTitle = title.slice(0,-2)
          let cleanDescription = description.slice(0,-2)

          let cleanTitle_ar = title_ar.slice(0,-2)
          let cleanDescription_ar = description_ar.slice(0,-2)
          


      var myday = new Date(dates.date);

      // var str = String(myday)
      // var day = str.slice(0, 3);
      // var day = str.slice(0, 3);
      // var mm = String(myday.getMonth() + 1).padStart(2, '0'); //January is 0!
      // var dd = String(myday.getDate()).padStart(2, '0');
      // var yyyy = myday.getFullYear();



      var weekday = new Array(7);
      weekday[0] = lang.weekday.Sunday;
      weekday[1] = lang.weekday.Monday;
      weekday[2] = lang.weekday.Tuesday;
      weekday[3] = lang.weekday.Wednesday;
      weekday[4] = lang.weekday.Thursday;
      weekday[5] = lang.weekday.Friday;
      weekday[6] = lang.weekday.Saturday;
      
      // var fullDay = weekday[myday.getDay()];


      // var date = dd  + '-' +  mm  + ' ' + fullDay    ; 

      // var dateOnly = dd  + '-' +  mm + '-' +  yyyy     ; 
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };



      let mealDate = locale? myday.toLocaleDateString('ar-EG',options ) : myday.toLocaleDateString(undefined, options)



      return (


<div className="card  text-center col-lg-10  col-md-10 mb-3 pl-0 pr-0" style={{width:"100%", maxWidth:"", borderRadius:"30px 0px 60px 0px"}}>

  <div className="recommendedMealsHeader  "  style={{width:"100%", maxWidth:"", borderRadius:"30px 0px 0px"}}>

            {/* <h6 className="mb-0 date"> <strong> {lang.day} {index}  </strong> </h6> */}
            {/* <h6 className="mb-0 fontStyle">{mealDate} | {lang.day} {index}  | {fullDay} </h6> */}
            <h6 className="mb-0 fontStyle"> <strong> {lang.day} {index}  |  {mealDate}  </strong>   </h6>
            {/* <i className="fas fa-edit editIcond EditIConnRight" title="change slected meals" onClick={()=>this.continue(dates)} > </i> */}



  </div>

  <div className="card-body changeMealBody" style={{width:"100%", maxWidth:"", borderRadius:"0px 0px 60px"}} >
    <h6 className="card-title">{locale? cleanTitle_ar : cleanTitle}</h6>
    <p className="card-text">{locale? cleanDescription_ar: cleanDescription}</p>

    <button  
        onClick={()=>this.continue(dates)}
        title="change slected meals"
        className="btn btn-sm btn-outline-warning custom-detail-meal      col-md-4  col-8 "
        style={{marginTop:'.5rem'} }>
        <strong className="ButtonText ">   {lang.editMeals} <i className="fas fa-edit ml-2"></i> </strong>   
    </button>

  </div>
</div>


      );
    }
  }
  
  
  const mapStateToProps = state => {
    return {
      selectedSub: state.productReducer.selectedSub,
      step: state.productReducer.step,
      productReducer: state.productReducer,
      locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleSeletedSub: (id) => dispatch(handleSeletedSub(id)),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
        handleMealsListForSelectedDay: (date) => dispatch(handleMealsListForSelectedDay(date)),
        changeOndDaySeletedMealToFalse: (selected) => dispatch(changeOndDaySeletedMealToFalse(selected)),
        changeOndDaySeletedMealToTrue: (selected) => dispatch(changeOndDaySeletedMealToTrue(selected)),
        increase_number_of_selected_meals: (selected) => dispatch(increase_number_of_selected_meals(selected)),
        decrease_number_of_selected_meals: (selected) => dispatch(decrease_number_of_selected_meals(selected)),
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatesList));
  