import React, { Component } from 'react';
import Subscriptions from '../Subscriptions';
import SelectDate from '../SelectDate';
import MealsList from '../Meals/MealsList';
import Meals from '../Meals';
import Checkout from '../Checkout';
import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {handleNextStep,} from "../../store/actions/productAction";
import {fetchIsMaxOrders,} from "../../store/actions/orderActions";
import Loading from "../Loading";

import soos from '../../assets/images/soos.png'



export class Subscribe extends Component {

    componentDidMount() {
        this.props.fetchIsMaxOrders()
        }

    showStep = () => {
        if(this.props.step === 2){
            ReactGA.pageview('subscribe/Subscriptions');
        
            return (<Subscriptions 
            /> );
        }



        if(this.props.step === 3){
            ReactGA.pageview('subscribe/Select Start Date');

            return (<SelectDate 
            />);
        }


        if(this.props.step === 4){
            ReactGA.pageview('subscribe/Recomended Meals');

            return (<Meals 
            />);
        }


        if(this.props.step === 5){
            ReactGA.pageview('subscribe/Change Recomended Meals');

            return (<MealsList 
            />);
        }


        if(this.props.step === 6){
            ReactGA.pageview('subscribe/Checkout');

            return (<Checkout 
            />);
        }

    }


    
    
continue = (step) => {

    if(step === 2 ){
        this.props.handleNextStep(step)
    }
    if(step === 3 &&  this.props.selectedSub.id){
        this.props.handleNextStep(step)
    }
    if(step === 4 &&  this.props.selectedStartDay !== undefined){
        this.props.handleNextStep(step)
    }
    if(step === 6 &&  this.props.loadingMeals === false &&  this.props.selectedStartDay !== undefined){
        this.props.handleNextStep(step)
    }
  }

    // onClick={()=>this.continue(2)} 
    // disabled={this.props.selectedStartDay === undefined} 


    render(){
        const { step } = this.props;
        // const { isAuthenticated } = this.props.auth;

        const selectedStep = " slectedStep iconsBar"

        // let location =  this.props.location.pathname
        // isAuthenticated === false && this.props.history.push('/login',{ from: location })


        
        if (this.props.loadingIsMax) {
            return <Loading />
            }
        if (this.props.isMax.is_max) {
            return(
            <div>
                <img src={soos} alt="bg" className="payment-img"/>
                {/* <h6 className="redirectTimerText"> Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6> */}
            </div>  
            )
            }
        else {
            return (

                <>
                    <div className="row justify-content-center onNaveChangeIcons mr-auto ml-auto">
                        
                            
                        {/* <label className="labelIcon fas fa-map-marked-alt "> </label> */}
                        {/* <i className={this.props.step === 1 ? `${selectedStep}  fas fa-map-marked-alt ` : "fas fa-map-marked-alt iconsBar"} style={{color:"#bb8276", }}></i> */}

                        {/* <label className="labelIcon fas fa-bell "> </label> */}
                        <i    onClick={()=> this.continue(2)} className=  {step === 2 ? `${selectedStep} fas fa-bell selectStyle ` : "fas fa-bell iconsBar selectStyle " } style={step > 1 ? {color:"#bb8276"} :{color:"#a4a0a1"}}></i>
                        
                        {/* <label className="labelIcon far fa-calendar-alt "> </label> */}
                        <i   onClick={()=> this.continue(3)} className=  {step === 3 ? `${selectedStep} far fa-calendar-alt selectStyle ` : "far fa-calendar-alt  iconsBar selectStyle" } style={step > 2 ? {color:"#bb8276"} :{color:"#a4a0a1"}}></i>
                        
                        {/* <label className="labelIcon fas fa-utensils "> </label> */}
                        <i  onClick={()=> this.continue(4)}     className=  {step === 4 || step === 5 ? `${selectedStep} fas fa-utensils selectStyle ` : "fas fa-utensils  iconsBar selectStyle" } style={step > 3 ? {color:"#bb8276"} :{color:"#a4a0a1"}}></i>
                        
                        {/* <label className="labelIcon fas fa-shopping-cart "> </label> */}
                        <i onClick={()=> this.continue(6)} className=  {step === 6 ? `${selectedStep} fas fa-shopping-cart mr-0 selectStyle ` : "fas fa-shopping-cart iconsBar mr-0 selectStyle" } style={step > 5 ? {color:"#bb8276"} :{color:"#a4a0a1"}}></i>
                    </div>


                    <div className='mt-2'>


                    {this.showStep()}
                    </div>
        
                </>
                        
                        )

                    }    

    }
}


  
const mapStateToProps = state => {
    return {
      selectedSub: state.productReducer.selectedSub,
      step: state.productReducer.step,
      auth: state.auth,
      selectedStartDay: state.productReducer.selectedStartDay,
      loadingMeals: state.productReducer.loadingMeals,
      isMax:state.orderReducer.isMax,
      loadingIsMax:state.orderReducer.loadingIsMax,

    };
  };
  

    
  const mapDispatchToProps = dispatch => {
    return {
        handleNextStep: (step) => dispatch(handleNextStep(step)),
        fetchIsMaxOrders: () => dispatch(fetchIsMaxOrders()),
    };
  };
  

  export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Subscribe));
  