import React, { Component } from "react";
import { connect } from "react-redux";

import MultiCarouselItems from './MultiCarouselItems'

import ReactCarousel from "react-multi-carousel";


class MultiCarousel extends Component {

    componentDidMount() {
        }

    render() {
    


        
        const multiCarouselItems =  this.props.all_meals.map((meal , index) => (
          <MultiCarouselItems key={meal.id} meal={meal} index={index} />
        ));



        const responsive = {
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
          }
        };
  
  
      return (      

    <ReactCarousel
    swipeable={true}
    showDots={false}
    draggable={false}
    responsive={responsive}
    ssr={true} // means to render carousel on server-side.
    infinite={true}
    autoPlay={this.props.deviceType !== "mobile" ? true : false}
    autoPlaySpeed={5000}
    keyBoardControl={true}
    customTransition="transform 3000ms ease"
    // additionalTransfrom={16}
    transitionDuration={3000}
    containerClass="carousel-container"
    // removeArrowOnDeviceType={["tablet", "mobile"]}
    deviceType={this.props.deviceType}
    dotListClass="custom-dot-list-style"
    itemClass="carousel-item-padding-40-px  "
    >

        {multiCarouselItems}


    </ReactCarousel>  

      )

    
    }
  }

const mapStateToProps = state => {
  return {
    loadingOrder: state.orderReducer.loading,
    orders: state.orderReducer.orders,
    auth: state.auth,
    all_meals:state.productReducer.all_meals,
    loadingAllMeals:state.productReducer.loadingAllMeals,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    // fetchOrders: () => dispatch(fetchOrders())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiCarousel);
