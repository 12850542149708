import React from "react";
import { connect } from "react-redux";

import { fetchSubscriptions,handlePreviousStep,handleNextStep,change_selected_sub_to_true,change_selected_sub_to_false } from "../../store/actions/productAction";
import SubscriptionsList from "./SubscriptionsList";
import Loading from "../Loading";
import 'react-day-picker/lib/style.css';

import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class Subscriptions extends React.Component {
  state = {
  };

  componentDidMount() {

  }





  UNSAFE_componentWillReceiveProps(nextProps) {
    const selectedSub =  this.props.selectedSub


    if(selectedSub.id !== undefined && selectedSub.id !== nextProps.selectedSub.id) {
      this.props.change_selected_sub_to_true()
    }
    }






  continue = (e) => {

    this.props.handleNextStep(1)
}

back = e => {
  e.preventDefault();
  this.props.handlePreviousStep(1);
}


  render() {
    let locale = this.props.locale
    let lang = locale ? ar : en
    
    if (this.props.loading) {
      return <Loading />
    } else {
      
      const ListOfSubscriptions = Object.keys(this.props.subscriptions).length > 0 &&this.props.subscriptions.results.map(subscription => (
        <SubscriptionsList key={subscription.id} subscription={subscription}  />
      ));


      return <div className="container " >
                <h3 className="centerHeader logoColor yourPlane ">{lang.Choose_Your_Plan}</h3>
                    <div className="row justify-content-center mt-3" >
                        {ListOfSubscriptions}
                    </div>
                    <br/>        
              </div>
            }
          }
        }


const mapStateToProps = state => {
    return {
        subscriptions: state.productReducer.subscriptions,
        step: state.productReducer.step,
        selectedSub: state.productReducer.selectedSub,
        loading: state.productReducer.loadingSubs,
        isSelectedDateChanged: state.productReducer.isSelectedDateChanged,
        locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        fetchSubscriptions: () => dispatch(fetchSubscriptions()),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        change_selected_sub_to_true: () => dispatch(change_selected_sub_to_true()),
        change_selected_sub_to_false: () => dispatch(change_selected_sub_to_false()),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
  