import React, { Component } from "react";
import { connect } from "react-redux";


class TermsAndConditions extends Component {

  render() {

    return (
        <div className="container">


<p className="rtlTextTerms">



أهلا بك في موقع مؤسسة مسارات الصحة لتقديم الوجبات المالكة للعلامة التجارية هيلث زون . يتمثل الغرض من هذا الموقع الإلكتروني/ التطبيق في خدمة عملائنا. ويخضع استخدام هذا الموقع/ التطبيق للشروط والأحكام التالية ("الشروط والأحكام" أو "شروط الاستخدام")، لذلك يرجى قراءة الأحكام والشروط التالية بعناية قبل الشروع في استخدام هذا الموقع الإلكتروني / التطبيق. إذ أن الاستعمال لهذا الموقع/ التطبيق من المُستخدم يعتبر بمثابة موافقة على هذه الشروط والأحكام؛ فإذا كنت لا توافق على ذلك فلا تستعمل هذا الموقع.
من خلال استخدام أو زيارة أو التسوق/تقديم طلبات أو تصفح الموقع الإلكتروني أو التطبيق، فإنك توافق بدون أي قيود على هذه الشروط والأحكام والتي تعد بمثابة عقداً ملزماً بينك وبين مؤسسة هيلث زون أ (ويُشار إليها مجتمعين بـ "هيلث زون" أو "نحن") وبين المستخدم ("أنت" أو "المستخدم"). وتُشكل الشروط والأحكام بما فيها شروط الطلب عبر الموقع الإلكتروني/ التطبيق بالإضافة إلى سياسة الخصوصية والسياسات الأخرى الاتفاقية الكاملة (الاتفاقية)

</p>


<br/>
<br/>
<h4 className="rtlHeaderTerms">أحكام عامة</h4>

<p className="rtlTextTerms">

تحكم هذه الشروط والأحكام استخدام الموقع الإلكتروني/ التطبيق وشراء الأصناف من خلاله واستخدام/ شراء أي خدمات منه أو عن طريقه. وتخضع هذه الشروط والأحكام للتغيير في أي وقت من الأوقات بدون تقديم إخطار من جانب شركة هيلث زون وتكون الشروط والأحكام الأحدث هي القابلة للتطبيق. لذلك يُنصح بالاطلاع على الشروط والأحكام المنشورة على الموقع الإلكتروني/ التطبيق بين الحين والآخر. ونظراً لأن استخدامك للموقع الإلكتروني يعد بمثابة قبول للاتفاقية، ينصح بالاطلاع على وثائق الاتفاقية بالتفصيل، كما أن عدم الاطلاع الاختياري على وثائق الاتفاقية لن يغير بأي حال من الأحوال أو ينكر قبولك للاتفاقية ..
كما إنك توافق على الالتزام بكافة القوانين السارية ذات الصلة باستخدامك للموقع الإلكتروني. وتوافق أيضا بأن جميع المعلومات المقدمة من جانبك هي معلومات صحيحة ودقيقة وفقا لمطلق معرفتك وعلمك. وتُقر بأنك لا تستند إلى أية إقرارات و/أو ضمانات لم يتم الإشارة إليها صراحة في إطار الشروط والأحكام..

</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">الطلب</h4>

<p className="rtlTextTerms">

يمكن الطلب من خلال التطبيق أو الموقع واختيار الوجبات المتاحة وتحديد مكان وموعد التوصيل، بشرط أن تكون مواعيد التوصيل المختارة بين الساعة 8:00 صباحا  والساعة 5:00 مساءا  في أيام الأحد إلى الخميس، مع الأخذ في الاعتبار أنه قد يحدث تفاوت في وقت التوصيل المحدد.
يمكن لهيلث زون أن تقوم بإلغاء الطلب في بعض الأحيان. مثلا وليس حصرا ًو عند عدم توفر الوجبة أو أن يكون نطاق التوصيل خارج التغطية. في هذه الحالة لن يقوم العميل بدفع ثمن الوجبة ويرجع إليه المبلغ كاملاً في حالة الدفع.
مع العلم أنه بإمكانك إعادة جدولة موعد استلام وجبتك، أو تبديل الوجبة بأخرى في أي يوم وحتى موعد استلام الوجبة الفعلي وحتى الساعة 3  مساءا لليوم السابق للوجبة  وذلك بالرجوع إلى خدمة العملاء او عن طريق الموقع الالكتروني او التطبيق .

</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">الوصف</h4>

<p className="rtlTextTerms">

بالرغم من أن وصف الوجبات المعروضة على التطبيق مطابق لمحتويات الوجبة وبشكل كبير دقيق، إلا أنه في بعض الحالات يمكن أن تُستبدل ببعض المكونات أخرى شبيهة في حالة عدم توافرها.
الصور المعروضة لغرض تسويقي بشكل أساسي، وهذا لا يتنافى مع حرصنا "هيلث زون" على تقديم الوجبات في أفضل حالاتها.


</p>


<br/>
<br/>
<h4 className="rtlHeaderTerms">التوصيل</h4>

<p className="rtlTextTerms">


يعمل الآن في مدينة الرياض فقط. فضلاً راجع خدمة العملاء للتأكد من الأماكن التي تحت التغطية .
مواعيد التوصيل تبدأ من الساعة 8:00 صباحا  وحتى الساعة 5:00 مساءا  في أيام الأحد إلى الخميس مع الأخذ في الاعتبار أنه قد يحدث تفاوت في وقت التوصيل المحدد من قِبَل العميل يقدر الحد الأقصى منه بـ 30 دقيقة، إما قبل الموعد المحدد أو بعده. في حالة زيادة التأخير عن هذه المدة يحق للعميل إعادة ترتيب موعد آخر لتسلم الوجبة، أو إلغاء الطلب وسيتم استرداد المبلغ او استلام الوجبة عن طريق الفرع و اخر موعد لاستلامها سيكون الساعة 8 مساءا..


<br/>

على العميل أن يكون متاحًا في وقت التسليم وضمان استلام الوجبة. في حال تعذر الوصول إليك أو من ينوب عنك، سنحاول الاتصال بك على رقم الهاتف الخاص بك لدينا، وفي حالة عدم قدرتنا على الوصول إليك، يعتبر الطلب نافذا وستكون مسؤولًا عن دفعه بالكامل، ولن نتحمل المسؤولية عن عدم تواجدك وقت الوصول أو تفويض من ينوب عنك.
لضمان جودة الوجبة.  يفضل أن يتم تناولها فوراً فور التوصيل او يجب حفظها فور استلامها في الثلاجة للمحافظة على سلامة الوجبة من الناحية الصحية والقيمة الغذائية، ولمدة لا تزيد عن ٢٤ ساعة والامتناع عن تناولها بعد ذلك.

تبذل شركة هيلث زون  العناية الواجبة لضمان توصيل الطلبات خاصتك خلال المدة المتفق عليها . ومع ذلك لا نتحمل المسؤولية في حالة حدوث تأخير بسبب سوء الأحوال الجوية و/أو الازدحام المروري أو في حالة حدوث أي من أحداث القوة القاهرة .




</p>


<br/>
<br/>
<h4 className="rtlHeaderTerms">الدفع</h4>

<p className="rtlTextTerms">



جميع الأسعار بالريال السعودي. الأسعار صحيحة كما هو مبين على الموقع والتطبيق، وتشمل رسوم التوصيل داخل نطاق التغطية وضريبة القيمة المضافة. يحق لهيلث زون مراجعة و تعديل الأسعار من حين الى اخر. .
يمكن إجراء الدفع عبر الإنترنت (بطاقة الائتمان، ماستر كارد، أوخدمة الدفع مدى).
يلتزم العميل بسداد قيمة الاشتراك مسبقًا عند بدء الاشتراك، ولا يوجد أي إمكانية لتأجيل الدفع لمنتصف أو بعد الاشتراك .
يعتبر الاشتراك مفعلاً في حالة الانتهاء من اختيار كافة الوجبات للأيام وإتمام عملية الدفع بأحد الطرق الموضحة .
بطاقات الائتمان أو الدفع المسبق التي يستخدمها العميل في السداد من خلال بوابة الدفع الإلكتروني على  موقع أو تطبيق  هيلث زون يتوجب أن تعود ملكيتها للعميل نفسه. في حال كانت ملكية البطاقة تعود لشخص آخر فيتوجب على العميل استخدامها بصورة قانونية؛ وذلك بموافقة صاحب البطاقة.


</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">الخصومات والعروض</h4>

<p className="rtlTextTerms">



هيلث زون غير مسؤولة عن أي أكواد للخصومات غير مقدمة من خلال أحد ممثليها الرسميين من قسم المبيعات والتسويق أو خدمة العملاء .
الخصومات الخاصة بالشركات هي فقط لأفراد الشركات التي تم منح كود الخصم لهم، وأي استخدام للكود من خارج الشركة فلن يتم تفعيله وهيلث زون لها الحق في تحصيل رسوم الاشتراك كاملة .
لا يمكن للعميل استخدام كود خصم في حالة وجود خصم من هيلث زون على الاشتراك.
لا يمكن الحصول على خصمين أو استخدام أكثر من كود في اشتراك واحد.

</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">إلغاء الاشتراك</h4>

<p className="rtlTextTerms">


في حالة الرغبة في إلغاء الاشتراك، يتم إرجاع المبلغ إلى العميل مع خصم 10 % من قيمة المبلغ المدفوع كرسوم تسجيل إدارية و في حال تم الاستفادة من الاشتراك سيتم خصم قيمة الوجبة المستفاد منها على حسب ما هو موضح في قائمة الطعام ولا يحق للعميل  الغاء الاشتراك بعد الاستفادة من الخدمة بما يعدل 60% من الاشتراك. 
</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">نظام العضوية في هيلث زون </h4>

<p className="rtlTextTerms">


يجب عليك ملء استمارة التسجيل بشكل صحيح من حيث الاسم و العنوان ورقم الجوال و البريد الالكتروني  وكلمة مرور يتم تحديده بواسطة العضو وحدة نفسة يقتصر " اسم المستخدم" و " وكلمة المرور " على العضو وحدة  أن تكون العضوية فقط للمستخدم وحدة ولا يحق مشاركتها مع الغير وهذي العضوية من مسؤولية المستخدم وحدة. هيلث زون لا يسمح بتزويد نفس اسم المستخدم وكلمة المرور الى نفس العضوية. كما ان من مسؤولية المستخدم وحدة اختيار وحماية كلمة المرور وعن أي مشاكل قد تنشا عن استخدام كلمة المرور. المستخدم  يضمن أن المعلومات الشخصية المقدمة منك صحيحه و دقيقيه وسارية المفعول عند الاستخدام و كاملة من كافة النواحي و أنك سوف تقوم بإبلاغنا فوارا بأي تغير فورا بأي تغيرات تطرا على معلوماتك الشخصية عن طريق تحديث معلومات العضوية الخاصة بك عن طريق الموقع او طريق الاتصال بخدمة العملاء. استخدامك لموقع او تطبيق او التواصل لهيلث زون يعني موافقتك على عدم تقمص شخصية أي شخص او جهة أخرى او اسم مزور اسم لا تكون مخول باستخدامه.  
</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">استخدام ومشاركة المعلومات</h4>
<p className="rtlTextTerms">
هيلث زون قد يتم  بمشاركة رقم الهاتف و موقع العميل مع المندوب التوصيل "شركة التوصيل" لكي يتم تقديم خدمة توصيل سريعة وصحيحة. بقبولك اتفاقية فانت توافق على ان تقوم هيلث زون باستخدام معلومات الاتصال الخاصة بكم لإبلاغكم و الترويج لكم عن العروض و الخدمات التي نقدمها من حين الى حين اخر..
</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">حقوق الملكية الفكرية</h4>
<p className="rtlTextTerms">

جميع حقوق الملكية الفكرية المتعلقة بالموقع والتطبيق (بما في ذلك على سبيل المثال لا الحصر، الشعار والتصميم والتخطيط والبرمجة والنصوص) مملوكة لنا ويجب ألا يتم استنساخها دون الحصول على موافقة كتابية مسبقة. ولكن يمكن مشاركة هذه المعلومات بشكل شخصي مع آخرين لغرض غير اقتصادي مباشرة دون الرجوع إلينا.
</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">مسؤوليات العضو </h4>
<p className="rtlTextTerms">

إن العضو هو وحده المسؤول عن كافة الأفكار والآراء والبيانات الشخصية التي يذكرها أثناء استخدامه الموقع الالكتروني او التطبيق لهيلث زون، وهو كذلك مسؤول عن كافة الملفات التي يتم تحميلها إلى المراسلة لهيلث زون و المعلومات الشخصية المرسلة..
 لن يكون هيلث زون مسؤول بأي شكل من الاشكال عن تلك الملفات, او عن الولوج الى خدمات مقدمة عبر هيلث زون تكون غير مصرح بها , او باي طريقة أخرى غير تللك المحددة بواسطة هيلث زون. يلتزم العضو بعدم تغير البرمجيات باي طريقة كانت, و يلزم بتعويض هيلث زون عن كافة الخسائر المادية والأخلاقية  في حال عدم التزامك بالمواد المذكورة أعلاه  .
يلتزم العضو بعدم مضايقة و/أو تهديد المستخدمين الاخرين , او أي من موظفين التابعين لهيلث زون ويمتنع عن التصرف باي طريقة تؤثر على استخدام الخدمات من قبل المستخدمين الاخرين كما يلزم بعدم ارسال ، طباعة، او توزيع او تعميم أي مواد لا أخلاقية و غير قانونية أو معلومات قد تسبب ضررا بأسماء أي اشخاص او مؤسسات او الإعلان او البيع او العرض لبيع أي منتجات و خدمات او المشاركة في أي أفعال ضاره مثل المسح او المنافسة او الرسائل المتسلسلة، وعدم ارسال أي معلومات او برامج قد تؤدي الى الضرر بالمعلومات او البرمجيات على أجهزة المستخدمين الاخرين.
كافة السجلات و المواد التي يتم الحصول عليها بواسطة استخدام خدمات هيلث زون تكون ضمن موافقة المستخدم، وسوف يكون المستخدم مسؤولا تماما عن أي اضرار او فقد للمعلومات او خسار أخرى ناتجه عن ذلك لجهاز المستخدم ، ولا يحق للمستخدم بالمطالبة بالتعويض عن أي خسار تنشأ عن استخدام الخدمة كما يلتزم بعد استخدام خدمات هيلث زون لأي أغراض تجارية او إعلانية بدون الحصول على موافقة خطية من الإدارة . 


</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">تغيير الشروط والأحكام </h4>
<p className="rtlTextTerms">
نحن نحتفظ بالحق، وفقا لتقديرنا الخاص، بتغيير أو تعديل هذه الشروط والأحكام من وقت لآخر، وأي تغيير يصبح نافذ المفعول بمجرد نشره على الموقع الإلكتروني والتطبيق. وفي حالة استمرارك في استخدام التطبيق، فهذا يعني موافقتك على الشروط والأحكام المعدلة.
</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">القوة القاهرة </h4>
<p className="rtlTextTerms">
لا نتحمل المسؤولية الناشئة عن تعذر التنفيذ أو التأخر في تنفيذ أي من الالتزامات المنوطة بنا بموجب هذه الشروط والأحكام أو شروط الطلب عبر الموقع الإلكتروني أو التطبيق والتي تنتج بسبب أحداث وظروف القوة القاهرة التي تقع خارج نطاق سيطرتنا.

</p>

<br/>
<br/>
<h4 className="rtlHeaderTerms">القانون الحاكم</h4>

<p className="rtlTextTerms">

سيتم تفسير هذه الاتفاقية وأي مسائل ومنازعات تنشأ بموجب هذه وفقًا لقوانين المملكة العربية السعودية.

</p>

<br/>
<br/>

<h4 className="rtlHeaderTerms">الاشعارات و التبليغات</h4>

<p className="rtlTextTerms">

تكون الاشعارات والتبليغات التي توجهها هيلث زون الى المستخدم عن طريق عناوينهم الالكترونية والمشار لها أو عن طريق الواتس اب او الرسائل النصية SMS آو عن طريق الموقع الالكتروني او التطبيق.
</p>

<br/>
<br/>


<p className="text-center"> انتهى.. </p>





      </div>
    );
  }
}



const mapStateToProps = state => ({
});

// const mapDispatchToProps = dispatch => {
//   return {
//     signup: (email) => dispatch(signup(email)),
//     handleNextRegistrationStep: (step) => dispatch(handleNextRegistrationStep(step))
//   };
// };

export default connect(
  mapStateToProps,
)(TermsAndConditions);
