
import React, { Component } from "react";
import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import { Redirect } from 'react-router';

import {deliveryOption,handlePayment,checkPayment, fetchCoupon,handlePreviousStep ,} from "../../store/actions/productAction";

import payment_accepted from '../../assets/images/card-success.png'
import qs from 'qs'
import Loading from "../Loading";
import notfound from '../../assets/images/404.png'
// import ReactPixel from 'react-facebook-pixel';



class Successful extends Component {
    state = {
        redirect: false,
        timer:10,
        authorized:false,
        loading: true

    }

    componentDidMount(){


        try {
            let code = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code
            let secret = this.props.location.state.secret
            this.props.history.replace('/successful','null')


            if (+ code !== + secret){
                this.props.history.replace('/','null')
    
                this.setState(
                    { authorized:false , loading: false}
                )
            }
            else{
                // let orderID = this.props.payment_response.orderID
                // ReactPixel.trackCustom( '/successful', {"orderID":`${orderID}`} ) 		// For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9

                this.props.history.replace('/successful','null')
    
                    this.setState(
                        { authorized:true , loading: false}
                    )
    
            }

          }
          catch(err) {
            this.props.history.replace('/','null')
        }







    
        this.redirect = setTimeout(() => this.setState({ redirect: true }), 10000)
        var intervalId = setInterval(this.timer, 1000);
        this.setState({intervalId: intervalId});

    }


    timer = ()=> {

        var newCount = this.state.timer - 1;
        if(newCount >= 0) { 
            this.setState({ timer: newCount });
        } else {
            clearInterval(this.state.intervalId);
        }
     }

      


    componentWillUnmount() {
        clearTimeout(this.redirect)
        clearInterval(this.state.intervalId);

        }









    render() {

        
        
        
        if (this.state.loading) {
            return <Loading />
        } 
        
        else{            
                if(this.state.authorized){
                    let orderID = this.props.payment_response.orderID
                    let orderLocation = `/orders/${orderID}/`

                    return this.state.redirect ? <Redirect to={orderLocation} /> :
                    
                    <div>
                        <img src={payment_accepted} alt="bg" className="payment-img"/>
                        <h6 className="redirectTimerText"> Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6>
                    </div>


                }else{
                    return this.state.redirect ? <Redirect to='/' /> :
                    
                    <div>
                        <img src={notfound} alt="bg" className="payment-img"/>
                        <h6 className="redirectTimerText"> Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6>
                    </div>
                }
                
                
            }
            
            
        }
        
    }



const mapStateToProps = state => {
    return {

        loading:state.productReducer.loading_payment_response,
        payment_response:state.productReducer.payment_response,
        errors: state.errorReducer.errors,


    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        deliveryOption: (DP) => dispatch(deliveryOption(DP)),
        fetchCoupon: (code) => dispatch(fetchCoupon(code)),
        handlePayment: (sub_id,delivery,coupon, selectedMeals,address_type,startDate) => dispatch(handlePayment(sub_id,delivery,coupon, selectedMeals,address_type,startDate)),
        checkPayment: (tapID) => dispatch(checkPayment(tapID)),

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Successful));
  