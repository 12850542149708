
import React, { Component } from "react";
import {withRouter,} from "react-router-dom"
import { connect } from "react-redux";
import { handlePreviousStep} from "../../store/actions/productAction";
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class SelectedMealDetails extends Component {
    state = {
    }

    back = e => {
        e.preventDefault();
        this.props.handlePreviousStep(4);
      }
      

    render() {
        let locale = this.props.locale
        let lang = locale ? ar : en
        const meal = this.props.selectedMealDetail
        return (
            <div className ="container p-0">

                <div className=" text-center mb-3" style={{width:"100%", maxWidth:""}}  >
                        <img src={meal.image} className="card-img-top" alt="..." style={{width:"auto", height:"216px", margin:"auto"}}/>
                        <div className="card-body">
                            <h5 className="card-title">{locale ? meal.name_ar : meal.name}</h5>
                            <p className="card-text">{locale ? meal.description_ar: meal.description}</p>



                        </div>


                </div>

                <table className="table custom-border table-bordered text-center table-hover table-sm ">
                                    <thead className="custom-table-header">
                                        <tr>
                                        <th scope="col">{lang.meal.Protein}</th>
                                        <th scope="col">{lang.meal.Carb}</th>
                                        <th scope="col">{lang.meal.Fat}</th>
                                        <th scope="col">{lang.meal.CAL}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>{meal.protein}</td>
                                        <td>{meal.carb}</td>
                                        <td>{meal.fat}</td>
                                        <td>{meal.calories}</td>
                                        </tr>
                                    </tbody>
                            </table>

            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        selectedMealDetail: state.productReducer.selectedMealDetail,
        loading: state.productReducer.loadingSelectedMealDetail,
        locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectedMealDetails));
  