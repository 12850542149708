import React from "react";
import { connect } from "react-redux";

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {handleEndDay, handleSeletedDay,handleLoadingMealsStatus,change_selected_date_to_true ,handleNextStep,handleSeletedStartDate,fetchMeals,handlePreviousStep } from "../../store/actions/productAction";

import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class SelectDate extends React.Component {
  state = {
      date:"",
      isSelectedDatedChanged:false,
  };



   componentDidMount() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var nn = String(today.getDay())
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var date = yyyy + '-' + mm + '-' + dd; 
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    const lastDate = new Date(this.props.selectedSub.last_date)

    var toMonth = new Date();
    // toMonth.setMonth(lastDate.getMonth() + 1)
    toMonth = new Date(lastDate.getFullYear(), lastDate.getMonth()+1, 1);

    const tt = tomorrow.getMonth() + 1
    this.setState({date:date,nn:nn, dd:dd,mm:mm,yyyy:yyyy, today:today, tomorrow:tomorrow  ,tt:tt ,lastDate:lastDate, toMonth:toMonth});



  }


 UNSAFE_componentWillReceiveProps(nextProps) {
  const selectedStartDay =  this.props.selectedStartDay
  if(selectedStartDay !== nextProps.selectedStartDay) {
    this.props.change_selected_date_to_true()
    }
  }




continue = e => {
  e.preventDefault();
  this.props.handleNextStep(4)
}

back = e => {
    e.preventDefault();
    this.props.handlePreviousStep(2);
}

handleDayClick = (day,  { selected, disabled }) => {
    let selectedStartDay = day.toISOString().substring(0, 10)
    let selectedSub = this.props.selectedSub

    if (disabled) {
        this.props.handleSeletedStartDate(undefined)
        this.props.handleSeletedDay(undefined)
        this.props.handleEndDay(undefined ,selectedSub.total_days , selectedSub.include_weekend)

        return;
      }
    if (selected) {
        this.props.handleSeletedDay(day)
        this.props.handleSeletedStartDate(selectedStartDay)
        this.props.handleEndDay(day ,selectedSub.total_days , selectedSub.include_weekend)

        return;
      }

    this.props.handleSeletedStartDate(selectedStartDay)
    this.props.handleSeletedDay(day)
    this.props.handleEndDay(day ,selectedSub.total_days , selectedSub.include_weekend)

  }



  render() {
    let locale = this.props.locale
    let lang = locale ? ar : en

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };




    let selectedSub = this.props.selectedSub

    

    let disabledDays ={}

    
    let weekEndDays = {}

    if (selectedSub.include_weekend){
      weekEndDays= { daysOfWeek: [5] }
    }
    else{
      weekEndDays= { daysOfWeek: [6,5] }

    }



    let startDate = new Date(this.props.day);




    if (selectedSub.include_weekend && this.state.today < this.state.lastDate ){
      disabledDays= [weekEndDays,new Date(),this.state.tomorrow, { before: this.state.today , after:this.state.lastDate ,}]
    }
    if (selectedSub.include_weekend && this.state.today > this.state.lastDate ){
      disabledDays= [weekEndDays,new Date(),this.state.tomorrow, { before: this.state.today , after:this.state.today ,}]
      }
      
      if (selectedSub.include_weekend === false && this.state.today > this.state.lastDate  ){
        
        disabledDays = [weekEndDays,new Date(),this.state.tomorrow, { before: this.state.today , after: this.state.today ,}]
      }
      if (selectedSub.include_weekend === false && this.state.today < this.state.lastDate  ){
        disabledDays = [weekEndDays,new Date(),this.state.tomorrow, { before: this.state.today , after: this.state.lastDate ,}]
        
      }



      
      let highlighted = { 
        from: startDate, 
        to: this.props.endDay, 
      }

      const modifiers = {
        highlighted: highlighted ,
        weekEndDays: weekEndDays,
      };




      return <div className="container ">
                <h3 className="centerHeader logoColor yourPlane mb-4">{lang.Select_Start_Date}</h3>
                <div className="row justify-content-center " >

                        <p className="col-12 centerHeader" style={locale? {direction: "rtl"}:{direction: "ltr"}}> <strong> {locale ? selectedSub.name_ar: selectedSub.name}     </strong> </p>
                        <p className="col-12 centerHeader" style={locale? {direction: "rtl"}:{direction: "ltr"}}> <strong> {locale ? selectedSub.period_name_ar : selectedSub.period_name}, {locale ? selectedSub.displayed_number_ar:selectedSub.displayed_number},{lang.no}  {selectedSub.number_of_meals} { selectedSub.number_of_meals > 1 ? lang.noms : lang.nom}  </strong> </p>

                     {this.props.day ? (

                       <>
                       
                        <p className="col-12 centerHeader" style={locale? {direction: "rtl"}:{direction: "ltr"}}> <strong> {lang.startDate}: {locale? this.props.day.toLocaleDateString('ar-EG',options ) : this.props.day.toLocaleDateString(undefined, options)}     </strong> </p>
                        <p className="col-12 centerHeader" style={locale? {direction: "rtl"}:{direction: "ltr"}}> <strong> {lang.endDate}: {locale? this.props.endDay.toLocaleDateString('ar-EG',options ) : this.props.endDay.toLocaleDateString(undefined, options)}     </strong> </p>
                        </>
                                                                     
                        ) : (
                        <p className="col-12 centerHeader"><small style={{color:"#f7003e"}}> <strong>{lang.errors.select_start_date}</strong></small> </p>
                    )}
                <br></br>

                    <DayPicker  
                    modifiers={ modifiers }
                    // modifiersStyles={modifiersStyles}   
                    onDayClick={this.handleDayClick}
                    selectedDays={this.props.day}
                    initialMonth={this.state.tomorrow}
                    disabledDays={disabledDays}
                    fromMonth={this.state.tomorrow}
                    toMonth={this.state.toMonth}
                    showOutsideDays
                    enableOutsideDaysClick
                    showPreviousYear
                    showNextYear 
                    />


                </div>


              <div className="row justify-content-center recomendedMeal">
                        <button  
                            onClick={this.back} 
                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-3    mr-3 col-md-4  col-sm-4  col-5"
                            style={{marginTop:'.5rem'} }> <strong className="ButtonText"> <i className="fas fa-bell mr-2"></i>  {lang.Back}</strong>   
                        </button>
                        <button  
                            onClick={this.continue} 
                            disabled={this.props.selectedStartDay === undefined} 
                            title={this.props.selectedStartDay === undefined ? lang.errors.select_start_date :  lang.Next}

                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-3   col-md-4  col-sm-4  col-5 "
                            style={this.props.selectedStartDay === undefined ? {filter:"blur(0.7px)opacity(0.5)" , marginTop:'.5rem'} :{ marginTop:'.5rem'}}>
                            <strong className="ButtonText ">   {lang.Next} <i className="fas fa-utensils ml-2"></i> </strong>   
                        </button>
                </div>

                <br/>


                <br/>        
      </div>
    }
  }


const mapStateToProps = state => {
    return {
        subscriptions: state.productReducer.subscriptions,
        selectedStartDay: state.productReducer.selectedStartDay,
        selectedSub: state.productReducer.selectedSub,
        day: state.productReducer.day,
        endDay: state.productReducer.endDay,
        loading: state.productReducer.loading,
        birthday: state.productReducer.birthday,
        isSelectedSubChanged: state.productReducer.isSelectedSubChanged,
        locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleSeletedStartDate: (date) => dispatch(handleSeletedStartDate(date)),
        handleSeletedDay: (day) => dispatch(handleSeletedDay(day)),
        handleEndDay: (startDate, total_days,include_weekend)  => dispatch(handleEndDay(startDate, total_days,include_weekend) ),
        fetchMeals: (date,id) => dispatch(fetchMeals(date,id)),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        handleLoadingMealsStatus: () => dispatch(handleLoadingMealsStatus()),
        change_selected_date_to_true: () => dispatch(change_selected_date_to_true()),

        

    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(SelectDate);
  