import React, { Component } from "react";
import {withRouter,Link} from "react-router-dom"
import { connect } from "react-redux";
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class OrderMealList extends Component {
  state = {
  };

  componentDidMount() {

    }


    render() {

        let locale = this.props.locale
        let lang = locale ? ar : en

        let order_meal = this.props.order_meal
        let index = this.props.index + 1

        let title = ''
        let title_ar = ''
        order_meal.meals.filter(meal => ( meal.isSelected === true))
        .forEach(meal => {
            title += `${meal.name} + `
            title_ar += `${meal.name_ar} + `
          }
        )

        let cleanTitle = title.slice(0,-2)
        let cleanTitle_ar = title_ar.slice(0,-2)
  
        let meals = order_meal.meals

        var weekday = new Array(7);
        weekday[0] = lang.weekday.Sunday;
        weekday[1] = lang.weekday.Monday;
        weekday[2] = lang.weekday.Tuesday;
        weekday[3] = lang.weekday.Wednesday;
        weekday[4] = lang.weekday.Thursday;
        weekday[5] = lang.weekday.Friday;
        weekday[6] = lang.weekday.Saturday;
        var myday = new Date(order_meal.date);

        // var DayName = weekday[myday.getDay()];

        var todayDate = new Date()

        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        let mealDate = locale? myday.toLocaleDateString('ar-EG',options ) : myday.toLocaleDateString(undefined, options)


        let dayChecker = false


        myday.setHours(0,0,0,0)
        todayDate.setHours(0,0,0,0)

        if (myday > todayDate ){
          dayChecker = true
      }



        return (
                <div className="card  text-center col-lg-10  col-md-10 mb-3 pl-0 pr-0" style={{width:"100%", maxWidth:"", borderRadius:"30px 0px 60px 0px"}}>

                    <div className={`orderDetailHeader  ${!dayChecker && "expired"} `}  style={{width:"100%", maxWidth:"", borderRadius:"30px 0px 0px"}}>

                            {/* <h6 className="mb-0 date"> <small> {order_meal.date}  </small> </h6> */}
                            {/* <h6 className="mb-0 fontStyle "> {lang.day} {index}  | {DayName} </h6> */}
                            <h6 className="mb-0 fontStyle "> {lang.day} {index}  | {mealDate} </h6>
                                    {/* <Link
                                    title="change slected meals" 
                                    className={`fas fa-edit editIcond  ${dayChecker && "EditIConnRight" }`}
                                    to={{
                                        pathname: `${this.props.location.pathname}${order_meal.date}`,
                                        state:{
                                            "meals":meals,
                                            "number_of_meals":this.props.number_of_meals,
                                            "order_meal_id":order_meal.id,
                                            "end_date":this.props.end_date
                                        }
                                        }}
                                    >
                                    </Link> */}
                    </div>
                    <div 
                        className={`card-body orderDetailBody ${!dayChecker && "expired"}`} style={{width:"100%", maxWidth:"", borderRadius:"0px 0px 60px"}} >
                        <h6 className="card-title">{locale?cleanTitle_ar:cleanTitle}</h6>


                        <Link  
                          to={{
                              pathname: `${this.props.location.pathname}${order_meal.date}`,
                              state:{
                                  "meals":meals,
                                  "number_of_meals":this.props.number_of_meals,
                                  "order_meal_id":order_meal.id,
                                  "end_date":this.props.end_date
                              }
                              }}
                          title="change slected meals" 
                          className="btn btn-sm btn-outline-warning custom-detail-meal      col-md-4  col-8 "      
                          style={{marginTop:'.5rem'} }>
                            

                          <strong className="ButtonText ">   {dayChecker ? lang.editMeals : lang.reviewMeals }  <i className="fas fa-edit ml-2"></i> </strong>   
                      </Link>
                    </div>
                </div>
            );
        }
    } 
  const mapStateToProps = state => {
    return {
      loading: state.orderReducer.loadingOrder,
      orders: state.orderReducer.orders,
      auth: state.auth,
      locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderMealList));
  