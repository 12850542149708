import React from "react";

const TextFieldGroup = ({
  name,
  value,
  style,
  errors,
  placeholder,
  info,
  type,
  label,
  onChange,
  onClick,
  checked,
  disabled,
  toggleID,
  IconLeft,
  dataTarget,
  dataToggle,
  IconRight,
  IconRightText,
  IconRightTimer,
  readOnly,
  required,
  ButtonLeft,
  ButtonLeftClassName,
  ButtonLeftText,
  inputCustomClassName,
  isDisabledButton,
  errorMsgStyle,
  TogglePassword =  (elementId) => {
    var x =  document.getElementById(elementId);
    if (x.type === "password") {
        x.type = "text";
    } else {
        x.type = "password";
    }
}
},

) => {
  return (
    // <div className="form-group mx-2">
    <div className="col-md-12 mb-3" >

          {label && <label className=" mx-2 " htmlFor={name}>{label}</label>}
        <div className="input-group mx-2">
          
          <i className={IconLeft}></i>  

          {ButtonLeft && <button type="button"   disabled={isDisabledButton} onClick={ButtonLeft} className={ButtonLeftClassName }>{ButtonLeftText} </button> }
            

          <input
            type={type}
            className={`form-control form-control-md  ${inputCustomClassName}   ${errors && "is-invalid"}`}
            // className={`form-control form-control-md  ${errors ? "is-invalid" : "is-valid" }`}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            onClick={onClick}
            disabled={disabled}
            checked={checked}
            id={name}
            data-toggle={dataToggle} 
            data-target={dataTarget}
            // style={style}
            readOnly={readOnly}
            required = {required}
          />
          <i className={IconRight}  onClick={()=>TogglePassword(toggleID)}></i>
          <i className={IconRightTimer} > {IconRightText}</i>
          
          {info && <small className="form-text text-muted">{info}</small>}
          
          {
            errors && typeof errors === "object" ? 
            
           errors.map((error ,  index) => 
            <div className="invalid-feedback" key={index}>
            <ul style={{paddingLeft:"18px", marginTop:"0px", marginBottom:"0px"}}>
              <li >
                <span className={errorMsgStyle}> {error}</span>
              </li>
            </ul>
            </div>) : <div className="invalid-feedback">{errors} </div> 
          }

        </div>
    </div>
  );
};





export default TextFieldGroup;
