import React, { Component } from "react";
import { connect } from "react-redux";

import { sendEmail } from "../../store/actions/productAction";
// import defaultlSub from '../../assets/images/defaultlSub.jpg'
import healthZoneLocation from '../../assets/images/healthZoneLocation.png'
import "react-multi-carousel/lib/styles.css";
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class ContactUs extends Component {
    state={
    name:"",
    email_from:"",
    message:"",
    sendingEmail:false,
    }


    handleSubmit=(e)=>{
        this.setState({sendingEmail:true})
        e.preventDefault()
        let email= {
        name:this.state.name,
        email_from:this.state.email_from,
        message:this.state.message,
        }
        this.props.sendEmail(email)
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }



    componentDidUpdate(preProp, preState){
        if(preProp.loadingEmail === true && this.props.loadingEmail === false && this.state.sendingEmail === true){
        this.setState({sendingEmail:false})
        }
    }




    render() {
        let locale = this.props.locale
        let lang = locale ? ar : en
        let errors = this.props.errors


return (

    <div className=" row justify-content-center col-xl-11 col-lg-12 col-md-12 col-sm-12 col-12 m-auto ">

        {this.state.sendingEmail ?
        <div className=" SectionLoaderSpiner   m-auto CarsoulHome  ">
            <i className="fa fa-spinner" aria-hidden="true"></i>
        </div>
        :
        this.props.emailResponse.response ? <h2
            className="text-center  m-auto  HomeForm col-xl-5 col-lg-6 col-md-11 col-sm-12 col-12 mr-auto ml-auto ">
            {this.props.emailResponse.response}</h2> :

        <form 
            onSubmit={this.handleSubmit}
            style={locale ?{direction:"rtl", textAlign:"right"}:{}}
            className="HomeForm col-xl-5 col-lg-6 col-md-11 col-sm-12 col-12 mr-auto ml-auto  ">





            <div className={`form-group ${errors.name && "is-invalid" }`}>
                <label htmlFor="exampleFormControlInputName">{lang.contact_us.name}</label>
                <input onChange={this.onChange} name="name" value={this.state.name} required type="text"
                    className="form-control" id="exampleFormControlInputName" placeholder={lang.contact_us.name_placeholder} />
            </div>
            <div className="invalid-feedback">{errors.name}</div>

            <div className={`form-group  ${errors.email_from && "is-invalid" }`}>
                <label htmlFor="exampleFormControlInputEmail">{lang.contact_us.email}</label>
                <input onChange={this.onChange} name="email_from" value={this.state.email_from} required type="email"
                    className={locale?" phoneField_rtl  form-control":" form-control"} id="exampleFormControlInputEmail" placeholder={lang.auth.emailPlaceholder} />
            </div>
            <div className="invalid-feedback">{errors.email_from}</div>

            <div className={`form-group ${errors.message && "is-invalid" }`}>
                <label htmlFor="exampleFormControlTextareaMessage">{lang.contact_us.message}</label>
                <textarea onChange={this.onChange} required name="message" value={this.state.message}
                    className="form-control " id="exampleFormControlTextareaMessage" rows="6"></textarea>
            </div>
            <div className="invalid-feedback">{errors.message}</div>

            <div className="form-group">
                <label htmlFor="sendForm"></label>
                <button id="sendForm" className="btn btn-lg btn-outline-warning custom-detail-meal  btn-block  sendEmailButton  "
                    type="submit">{lang.contact_us.send}</button>
            </div>
        </form>
        }

        <div className=" HomeLocation row justify-content-center col-xl-4 col-lg-6 col-md-11 col-sm-12 col-12 mr-auto ml-auto mt-auto mb-auto socialPadding "
            style={{width:"100%" , height:"100%" }}>




            <div className="col-12 row justify-content-center  " > 


                <p className=" mb-3 text-center mr-4 "> <a
                        href="https://twitter.com/healthzoneksa?s=09">
                        <i className="fab fa-twitter socialSize "></i></a></p>

                {/* <p className=" mb-3 text-center mr-4 "> <a
                        href="https://youtube.com/">
                        <i className="fab fa-youtube socialSize socialYoutube"></i></a></p> */}

                <p className="  mb-3 text-center mr-4 "> <a
                        href="https://instagram.com/healthzoneksa?igshid=oebd41c22dr7">
                        <i className="fab fa-instagram socialSize socialInstagrame"></i></a></p>

            </div>

            <div className="col-12 row justify-content-center  " > 

            <p className="mt-1 mb-3 text-center "> <a className="addressColor"
                    href="https://www.google.com/maps/place/Health+zone+%D9%87%D9%8A%D9%84%D8%AB+%D8%B2%D9%88%D9%86%E2%80%AD/@24.8083683,46.6166743,17z/data=!3m1!4b1!4m5!3m4!1s0x3e2ee56a7c2f4f1f:0x3e8eb558a547e4b2!8m2!3d24.8083683!4d46.618863">
                    Anas Ibn Malik Rd, Al Malqa, Riyadh 13521</a></p>
            </div>


            <a className=""
                href="https://www.google.com/maps/place/Health+zone+%D9%87%D9%8A%D9%84%D8%AB+%D8%B2%D9%88%D9%86%E2%80%AD/@24.8083683,46.6166743,17z/data=!3m1!4b1!4m5!3m4!1s0x3e2ee56a7c2f4f1f:0x3e8eb558a547e4b2!8m2!3d24.8083683!4d46.618863">
                <img className=" HomeStoreLocationImg responsive" alt='healthZoneLocation' src={healthZoneLocation} /> </a>
        </div>
    </div>

)


}
}

const mapStateToProps = state => {
return {
loadingOrder: state.orderReducer.loading,
orders: state.orderReducer.orders,
auth: state.auth,
loadingSub: state.productReducer.loadingSubs,
subscriptions: state.productReducer.subscriptions,
all_meals:state.productReducer.all_meals,
loadingAllMeals:state.productReducer.loadingAllMeals,
supported_places:state.productReducer.supported_places,
loadingSupportedPlaces:state.productReducer.loadingSupportedPlaces,
errors: state.errorReducer.errors,
emailResponse: state.productReducer.emailResponse,
loadingEmail:state.productReducer.loadingEmail,
locale: state.productReducer.locale,


};
};

const mapDispatchToProps = dispatch => {
return {
sendEmail: (email) => dispatch(sendEmail(email))
};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);