import React, { Component } from "react";
import {withRouter,} from "react-router-dom"
import { connect } from "react-redux";
import nf from '../../assets/images/nf.png'

class Meals extends Component {
    state = {
    }




    render() {
        let meal = this.props.meal

        return (


            <div className=" p-0 col-lg-5 col-md-5 col-11 mr-auto ml-auto mb-5" >

                <div className="row no-gutters shadowCard NotselectedItemCard">
                
                    <div className="col m-auto ">
                        <div className="card-body p-0 ">
                            <img src={meal.image ? meal.image : nf}  alt="..."  style={{width:"133px", height:"133px"}} />
                        </div>
                    </div>

                    <div className="col-xl-7 col-lg-6 col-md-6 col-sm-8 col-6">
                        <div className="card-body pt-2 pb-2 pl-2">
                           
                            <h6 className="card-title mb-1 mt-3">{meal.name}</h6>
                            <p className="card-text mb-2" style={{fontSize:'larg'}} >quantity: {meal.count}</p>
                           
                        

                        </div>
                    </div>

                </div>
            </div>


        )
    }
}

const mapStateToProps = state => {
    return {



    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Meals));
  