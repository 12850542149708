import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter,Link } from "react-router-dom";

// Actions
import { loginUser } from "../../../store/actions/authActions";
import { resetErrors } from "../../../store/actions/errorActions";

// Components
import TextFieldGroup from "../../common/TextFieldGroup";

import ReactGA from 'react-ga';


import ar from '../../../assets/locales/ar.json'
import en from '../../../assets/locales/en.json'




class Login extends Component {
    state = {
      phone_number: "",
      password: ""
    };

  componentDidMount() {
    ReactGA.pageview(`Login`);

    if (this.props.auth.isAuthenticated) this.props.history.push("/");
    this.props.resetErrors();
  }

  componentDidUpdate() {
    if (this.props.auth.isAuthenticated) this.props.history.push("/");
    
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    let errorChanged = `${e.target.name}_changed`
    this.setState({[errorChanged]:false})
  }

  onSubmit = e =>{
    e.preventDefault();
    const user = this.state;
    let {from} = this.props.location.state || {from: {pathname: '/subscribe'}}

    this.props.loginUser(user, this.props.history, from);
    this.setState({
      phone_number_changed:true,
      password_changed:true,
    })
  }

  render() {
    const { errors} = this.props;

    let locale = this.props.locale
    let lang = locale ? ar : en

    return (
      <div className={locale? 'rtl login': "ltr login"}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center mb-4 logoColor"> {lang.auth.signIn}</h1>
              <p className="lead text-center"> {lang.auth.somethingClover}</p>
              <form noValidate onSubmit={this.onSubmit}>
                <TextFieldGroup
                  inputCustomClassName={locale?"phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}
                  errorMsgStyle="phoneField_rtl"
                  name="phone_number"
                  label= {<p className="requiredField" >{lang.auth.phoneNumber} <span className="requiredColor">  * </span> </p> } 
                  IconLeft='fas fa-mobile-alt icon-left'
                  placeholder={lang.auth.phoneNumberPlaceholder}
                  value={this.state.phone_number}
                  onChange={this.onChange}
                  errors={errors.phone_number || errors.non_field_errors}
                />
                <TextFieldGroup
                  inputCustomClassName={locale?"textFieldGroup_rtl":"textFieldGroup_ltr"}

                  name="password"
                  label= {<p className="requiredField" >{lang.auth.Passwrd} <span className="requiredColor">  * </span> </p> } 

                  placeholder={lang.auth.PasswrdPlaceholder}
                  IconLeft='fas fa-lock icon-left'
                  IconRight={ locale?'fa fa-eye icon-eye is-reverse_rtl': 'fa fa-eye icon-eye is-reverse'}
                  toggleID='password'
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  errors={this.state.password_changed  && (errors.password || errors.non_field_errors)}
                />
              
                <div className="col-md-12 mb-3">
                    <button  
                        type="submit"
                        className="btn btn-sm btn-outline-warning custom-detail-meal  btn-block   mx-2"
                        >
                        <strong className="ButtonText ">   {lang.auth.signIn}  </strong>   
                    </button>
                </div>

                


              
              <div className="mx-2 col-md-12 mb-3">
              <hr></hr>
                  <div className="float-left w-50 text-left">
                  <Link to={'/reset/password'}   className="loginFooter">{lang.auth.resetPassword}</Link>
                  </div>

                  <div className="float-left w-50  text-right">
                      <Link to={'/signup'}   className="loginFooter"> {lang.auth.signUp} </Link>
                  </div>
              </div>

              <br/>
              <br/>
              <br/>
              <br/>
              <br/>


              {/* <div className="col-md-12 mb-3">
                    <Link to={'/reset/password'}  
                        // type="button"
                        className="btn btn-sm btn-outline-warning custom-detail-meal  btn-block   mx-2"
                        > <strong className="ButtonText">  {lang.auth.resetPassword} </strong>   
                    </Link>
                </div>
                <div className="col-md-12 mb-3">
                    <Link to={'/signup'}  
                        // type="button"
                        className="btn btn-sm btn-outline-warning custom-detail-meal  btn-block   mx-2"
                        > <strong className="ButtonText">  {lang.auth.signUp} </strong>   
                    </Link>
                </div> */}









              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errorReducer.errors,
  locale: state.productReducer.locale,

});



export default withRouter( connect(
  mapStateToProps,
  { loginUser, resetErrors }
)(Login));


