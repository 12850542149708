import React, { Component } from "react";
import { connect } from "react-redux";

import Loading from "../Loading";
import { sendEmail } from "../../store/actions/productAction";
import Carousel from './Carousel'
import big from '../../assets/img/video/big.png'
import small_1 from '../../assets/img/video/small_1.png'
import small_2 from '../../assets/img/video/2.png'
// import ReactPlayer from "react-player";
import MultiCarousel from './MultiCarousel'
// import ReactCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SelectedMealDetails from '../Meals/SelectedMealDetails'
// import SupportedPlaces from './SupportedPlaces'
import { Link } from "react-router-dom";
import ContactUs from './ContactUs'
import ReactGA from 'react-ga';
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class Home extends Component {
    state={
    loadingSub:true,
    youtube:false,
    name:"",
    email_from:"",
    message:"",
    sendingEmail:false,
    // mounted:false
    }


    componentDidMount() {
    ReactGA.pageview('Home Page');

    }

    componentWillUnmount(){

}




    showYoutube=() => {
        this.setState({
        youtube:true
        })
        }

    closeYoutube=()=>{
        this.setState({
        youtube:false
        })
        }

    handleSubmit=(e)=>{
        this.setState({sendingEmail:true})
        e.preventDefault()
        let email= {
        name:this.state.name,
        email_from:this.state.email_from,
        message:this.state.message,
        }
        this.props.sendEmail(email)
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }



    componentDidUpdate(preProp){

        if(preProp.loadingEmail === true && this.props.loadingEmail === false && this.state.sendingEmail === true){
        this.setState({sendingEmail:false})
        }
    }




    render() {

    let locale = this.props.locale
    let lang = locale ? ar : en

    // const responsive = {
    //     desktop: {
    //     breakpoint: { max: 3000, min: 1024 },
    //     items: 3,
    //     slidesToSlide: 3 // optional, default to 1.
    //     },
    //     tablet: {
    //     breakpoint: { max: 1024, min: 464 },
    //     items: 2,
    //     slidesToSlide: 2 // optional, default to 1.
    //     },
    //     mobile: {
    //     breakpoint: { max: 464, min: 0 },
    //     items: 1,
    //     slidesToSlide: 1 // optional, default to 1.
    //     }
    // };




if ((Object.keys(this.props.subscriptions).length > 0 && this.props.loadingSub) || (Object.keys(this.props.all_meals).length > 0 && this.props.loadingAllMeals) || ( Object.keys(this.props.supported_places).length > 0 && this.props.loadingSupportedPlaces)) {
return <Loading />
}
else {




// const supportedPlaces = this.props.supported_places.count > 0 &&this.props.supported_places.results.map((supported_place
// , index) => (
// <SupportedPlaces key={index} supported_place={supported_place} index={index} />
// ));




return (
<div className=" ">
    {/* <div className="ContactUs col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 mr-auto ml-auto  ">
        <h1 className="deverHomeText logoColor ">Subscriptions </h1>
        <hr />
    </div> */}

    <br/>
    <br/>
    <br/>
    <div className="row justify-content-center  mr-auto ml-auto CarsoulHome">
        <Carousel />
    </div>






    {/* <div onClick={this.closeYoutube} className="modal custom fade YoutubePopUp" id="Youtube" tabIndex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="false">
        <div className="modal-dialog custom" style={{width:"100%" , margin:"0px"}} >
            <div className="modal-content" style={{backgroundColor:"#ffffff00" ,border:"none"}}>

                <div className="modal-body" style={{padding:"0px"}}>
                    <div className="wrapper">
                        { this.state.youtube &&

                        <ReactPlayer className="react-player"
                            url={`http://youtube.com/embed/yAl48Tk0Sno?showinfo=0&enablejsapi=1&origin=http://localhost:3000`}
                            width=" 100%" height="250px" playing={this.state.youtube} 
                            controls={true}


                        />
                        }
                    </div>
                </div>

            </div>
        </div>
    </div> */}


    <br />


    {/* <div className="ContactUs col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 mr-auto ml-auto  ">
        <h1 className="deverHomeText logoColor ">About Us </h1>
        <hr />
    </div> */}

    <br/>
    <br/>
    <div className="recepie_videoes_area" style={locale ? {textAlign:"right",direction:"rtl"} : {}}>
        <div className="container">
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 ">
                    <div className="recepie_info">
                        <h3>{lang.about_us.title}</h3>
                        <p>{lang.about_us.description}</p>
                        {/* <div className="video_watch d-flex align-items-center">
                            <div 
                                onClick={this.showYoutube} 
                                className="Youtube" 
                                style={locale ? {marginRight:"0px",marginLeft:"25px"} : {}}
                                data-toggle="modal"
                                data-target="#Youtube"> 
                                    <i className="fab fa-youtube"></i> 
                            </div>


                            <div className="watch_text">
                                <h4>{lang.about_us.watchVideo}</h4>
                                <p>{lang.about_us.watchVideo_des}</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="videos_thumb">
                        <div className="big_img">
                            <img src={big} alt="" />
                        </div>
                        <div className="small_thumb">
                            <img src={small_1} alt="" />
                        </div>
                        <div className="small_thumb_2">
                            <img src={small_2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className=" container ">

        <Link to={`/subscribe`} className=" ButtonBorder  btn btn-outline-danger  btn-lg btn-block ">
        <h1 className="SubNow">{lang.sub_now}</h1>
        </Link>
    </div>





    {/* <div className="ContactUs col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 mr-auto ml-auto  ">

        <h1 className="deverHomeText logoColor ">Our Meals </h1>
        <hr />
    </div> */}

    <br/>
    <br/>


    <br/>
    <br/>

    <div className="recepie_area ">

        <MultiCarousel />
    </div>







    <div className="modal fade" id="mealDetailHome" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="centerHeader logoColor  mb-0">{lang.meal_detail_pop}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-12 p-0">
                        <SelectedMealDetails />
                    </div>
                </div>
            </div>
        </div>
    </div>


















    {/* <div className="ContactUs col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 mr-auto ml-auto  pt-0 pb-0">

        <h1 className="deverHomeText logoColor text-center ">{lang.deliver_to} <i className="fas fa-shipping-fast"></i> </h1>
        <hr />
        <p className=" logoColor text-center">
        <small> {"  "} {lang.deliver_to_des}</small>

         </p>
    </div> */}



{/* supportedPlaces */}
    {/* <div className="dish_area ">
        <ReactCarousel swipeable={true} draggable={false} showDots={false} responsive={responsive} ssr={true} infinite={true} autoPlay={this.props.deviceType !=="mobile" ? true :
            false} autoPlaySpeed={3000} keyBoardControl={true} customTransition="transform 3000ms ease"
            transitionDuration={3000} containerClass="carousel-container" //
            removeArrowOnDeviceType={["tablet", "mobile" ]} deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style" itemClass="carousel-item-padding-40-px showTheImageOnTop ">

            {supportedPlaces}


        </ReactCarousel>
    </div> */}



    <div className="ContactUs col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12 mr-auto ml-auto  ">
        <h1 
        className=" logoColor text-center" 
        // style={locale ? {textAlign:"right",direction:"rtl"} : {}}
        id="contactUs"> 
        {lang.contact_us.title}  <i className="fas fa-mail-bulk"></i></h1>
        <hr />
    </div>


    <ContactUs/>









    <br />
    <br />
</div>
)


}
}
}
const mapStateToProps = state => {
return {
loadingOrder: state.orderReducer.loading,
orders: state.orderReducer.orders,
auth: state.auth,
loadingSub: state.productReducer.loadingSubs,
subscriptions: state.productReducer.subscriptions,
all_meals:state.productReducer.all_meals,
loadingAllMeals:state.productReducer.loadingAllMeals,
supported_places:state.productReducer.supported_places,
loadingSupportedPlaces:state.productReducer.loadingSupportedPlaces,
errors: state.errorReducer.errors,
emailResponse: state.productReducer.emailResponse,
loadingEmail:state.productReducer.loadingEmail,
selectedSub: state.productReducer.selectedSub,
locale: state.productReducer.locale,


};
};

const mapDispatchToProps = dispatch => {
return {
sendEmail: (email) => dispatch(sendEmail(email))
};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);