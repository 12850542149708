import React, { Component } from "react";
import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import { updateOrderMeals,fetchOrderDetail} from "../../store/actions/orderActions";
import Loading from "../Loading";
import MealsList from './MealsList'
import _ from 'lodash';
import ReactGA from 'react-ga';
import notfound from '../../assets/images/404.png'
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class OrderMealDetail extends Component {
  state = {
      total_slected:0,
      selected_meals_ids:[],
      prev_meals_ids:[],
      loading:false,
    //   mealsChecker:[],
    showMealDetail:false,
    clickedMeal:{}

  };

  componentDidMount() {

      let orderID = this.props.match.params.orderID;
      let dateID = this.props.match.params.dateID;
      ReactGA.pageview(`Change my order meals date: ${dateID} `);

      if (this.props.order === undefined || this.props.order.id !== + orderID){
        this.props.fetchOrderDetail(orderID)
    }

      
      if  (this.props.order !== undefined && this.props.order.order_meals ) {
    let order_meal = this.props.order.order_meals.find(meal => meal.date === dateID) || {};
    this.setState({
        order_meal: order_meal,
    });
}
}
  componentDidUpdate(prevProps) {
      let dateID = this.props.match.params.dateID;
      
    if (prevProps.order !== this.props.order) {

    let order_meal = this.props.order.order_meals.find(meal => meal.date === dateID) || 404


    this.setState({
        order_meal: order_meal,
    });
}
}


IncreaseTotalSelected =  () =>{
    //  this.setState({
    //     total_slected: this.state.total_slected + 1
    // })
    this.setState((prevState, props) => ({
        total_slected: prevState.total_slected + 1
    })); 
}

DecreaseTotalSelected = () =>{
    // this.setState({
    //     total_slected: this.state.total_slected - 1
    // })
    this.setState((prevState, props) => ({
        total_slected: prevState.total_slected - 1
    })); 
}

AddToTheList = (id) =>{


    this.state.selected_meals_ids.push(id)
    let slectedLength = this.state.prev_meals_ids.length
    
    let number_of_meals = this.props.order.subscription.number_of_meals
    
    if(slectedLength !== number_of_meals){
        this.state.prev_meals_ids.push(id)
    }

    
}

RemoveFromTheList = (id) =>{
    var index = this.state.selected_meals_ids.indexOf(id);
    this.state.selected_meals_ids.splice(index, 1);
}
submit = () =>{
    let id = this.props.location.state.order_meal_id
    let meals = this.state.selected_meals_ids
    let history = this.props.history
    let orderID = this.props.match.params.orderID;

    this.props.updateOrderMeals(id,meals,history,orderID);
    this.setState({
        loading:true
    })
}

handleClickedMeal=(meal)=>{
    this.setState({clickedMeal:meal , showMealDetail:true})
}


    render() {
        let order_meal = this.state.order_meal

        let locale = this.props.locale
        let lang = locale ? ar : en
        
        
        if (order_meal === 404 ) {
            
            return <div>
                <img src={notfound} alt="bg" className="payment-img"/>
            </div>
            }
        
        if (order_meal === undefined ) {
            return <Loading />
        }
        if (this.props.loading ) {
            return <Loading />
        } else {

          
            let number_of_meals = this.props.order.subscription.number_of_meals



            
            
            let dateID = this.props.match.params.dateID 
            let orderID = this.props.match.params.orderID;
            let total_slected = this.state.total_slected
            
            let UpdateHiddr = false 
            
            var mealDate = new Date(dateID);
            var todaysDate = new Date()

            mealDate.setHours(0,0,0,0)
            todaysDate.setHours(0,0,0,0)

            if (mealDate <= todaysDate ){
                UpdateHiddr = true
            }


            let changeChecker = _.difference(this.state.selected_meals_ids, this.state.prev_meals_ids).length === 0

            var backBottomDisable = false

            if (total_slected === number_of_meals){
               if(changeChecker){
                   backBottomDisable = true
               }

            }
            else{
               backBottomDisable = true
        
            }


            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

            let DATE = locale? mealDate.toLocaleDateString('ar-EG',options ) : mealDate.toLocaleDateString(undefined, options)
    


            const mealsList =  this.state.order_meal.meals.map( meal => (
                <MealsList key={meal.id} showMealDetail={this.state.showMealDetail} clickedMeal={this.state.clickedMeal}  handleClickedMeal={this.handleClickedMeal} meal={meal} RemoveFromTheList={this.RemoveFromTheList} AddToTheList={this.AddToTheList} total_slected={this.state.total_slected} number_of_meals={number_of_meals} IncreaseTotalSelected={this.IncreaseTotalSelected} DecreaseTotalSelected={this.DecreaseTotalSelected} />
               
                ));
            
            let history = this.props.history

            let Header = ''
            if (number_of_meals === 1){
                Header = `${lang.Select} ${number_of_meals} ${lang.nom} ` 
               }
               else{
                Header = `${lang.Select} ${number_of_meals} ${lang.noms} ` 
               }


        return (
                
            <div className="container " >
                <div className="row justify-content-md-center " >
                    <div className="col-lg-9 col-12 ">
                    <br/>
                    <br/>
                    {UpdateHiddr ?         
                    <h4 className="centerHeader logoColor yourPlane mb-4">{DATE}</h4>
                    :
                    <>                
                    <h4 className="centerHeader logoColor yourPlane mb-4">{Header}</h4>
                    <h5 className="centerHeader logoColor  mb-4">{DATE} </h5>
                    </>
                    }

                        {/* <h4 className="centerHeader logoColor yourPlane mb-4">{Header}</h4>
                        <h5 className="centerHeader logoColor  mb-4">{DATE} </h5> */}
                        <hr className="mb-4"/>
                        <div className="row justify-content-md-center mr-0 ml-0" >
                            {mealsList}
                        </div>






<div className="row justify-content-center recomendedMeal">

<button  
    onClick={()=>history.push(`/orders/${orderID}/`)} 
    className="btn btn-sm btn-outline-warning custom-detail-meal     mr-3 col-md-4  col-sm-4  col-5"
    style={{marginTop:'.5rem'} }> <strong className="ButtonText"> <i className="far fa-calendar-alt mr-1"></i> {lang.Back}</strong>   
</button>



                        {UpdateHiddr ? "" : 
                        
                        changeChecker ?                         
                        <button 
                        type="button" 
                        disabled={backBottomDisable}
                        style={{marginTop:'.5rem'} }

                        className="btn btn-sm btn-outline-warning custom-detail-meal      col-md-4  col-sm-4  col-5  "
                        > 
                            <strong> <i className="fas fa-wrench mr-2"></i>  {lang.Update} </strong> 
                        </button> : 
                        
                        <button 
                        style={{marginTop:'.5rem'} }
                        type="button" 
                        onClick={this.submit}  
                        disabled={backBottomDisable}

                        className="btn btn-sm btn-outline-warning custom-detail-meal      col-md-4  col-sm-4  col-5  "
                        > 
                            <strong> <i className="fas fa-wrench mr-2"></i>  {lang.Update} </strong> 
                        </button> 
                        
                        
                        }


                        </div>
                        
                    </div>
                </div>
                <br/>
              <br/>
            </div>

                );

        }
    } 
    }
  const mapStateToProps = state => {
    return {
      loading: state.orderReducer.loadingOrder,
      orders: state.orderReducer.orders,
      auth: state.auth,
      order: state.orderReducer.order,
      
      locale: state.productReducer.locale,

  
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      updateOrderMeals: (id, meals, history,orderID) => dispatch(updateOrderMeals(id, meals, history,orderID)),
      fetchOrderDetail: (orderID) => dispatch(fetchOrderDetail(orderID)),

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderMealDetail));
  