import React, { Component } from "react";
import { connect } from "react-redux";
import CarouselItems from './CarouselItems'
// import CarouselIndicators from './CarouselIndicators'
import notFoundSubImg from '../../assets/images/notFoundSubImg.svg'

class Carousel extends Component {
  render() {
    
        
        const carouselItems = Object.keys(this.props.subscriptions).length > 0 && this.props.subscriptions.count > 0 && this.props.subscriptions.results.map((subscription , index) => (
          <CarouselItems key={subscription.id} subscription={subscription} index={index} />
        ));

        
        const myImage = {  backgroundImage:`url(${notFoundSubImg})` }

        const notFoundSub = (
            <div 
            className=" mb-4 pr-0 pl-0 card bg-light text-black  cardStyle  "
            style={ myImage }
            >
            </div> 
          )
      return (      
                <div id="carouselExampleInterval" data-ride="carousel"  data-interval="10000" className="carousel slide col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  cardStyle custom-crousol-card  crousolPaddingRL " >
                    
                                        
                    {/* <ol className="carousel-indicators">
                        {carouselIndicators}
                    </ol> */}
                    
                    
                    <div className="carousel-inner  cardStyle  ">
                        {carouselItems ? carouselItems : notFoundSub}
                    </div>

                    <button className=" react-multiple-carousel__arrow react-multiple-carousel__arrow--left subsCarsoul" href="#carouselExampleInterval"  data-slide="prev">

                    </button>

                    <button className=" react-multiple-carousel__arrow react-multiple-carousel__arrow--right subsCarsoul" href="#carouselExampleInterval"  data-slide="next">

                    </button>

                </div>

      )

    
    }
  }

const mapStateToProps = state => {
  return {
    loadingOrder: state.orderReducer.loading,
    orders: state.orderReducer.orders,
    auth: state.auth,
    loadingSub: state.productReducer.loadingSubs,
    subscriptions: state.productReducer.subscriptions,
    locale: state.productReducer.locale,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    // fetchOrders: () => dispatch(fetchOrders())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);
