import React from "react";
import { connect } from "react-redux";

import Loading from "../Loading";
import 'react-day-picker/lib/style.css';
import {fetchSupportedPlaces,postAddress,addressType, fetchAddressesList,handleSeletedDay,handleLoadingMealsStatus,change_selected_date_to_true ,handleNextStep,handleSeletedStartDate,fetchMeals,handlePreviousStep } from "../../store/actions/productAction";
import  {notify} from '../Notifications'

import Map from './Map'

import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

// import Notifications, {notify} from '../Notifications'
// import { async } from "q";
import ReactGA from 'react-ga';

class Address extends React.Component {
  state = {
      date:"",
      isSelectedDatedChanged:false,
      
      supportedCities:['Al Jubayl','Al Jubail','Riyadh'],

      address_type:'Home',
      default:false,

      map : {
        address_type: '',
        address: '',
        area: '',
        city: '',
        county: '',
        state:'',
        country: '',
        postal_code: '',
        lat: 0.0,
        lng: 0.0,
        mapUrl: '',
        default: false
    }
  };






   async componentDidMount() {
    ReactGA.pageview('Add Your Address (Google Map)');

  }



continue = e => {
  e.preventDefault();
  // this.props.nextStep();
  this.props.handleNextStep(2)
}

back = e => {
    e.preventDefault();
    // this.props.prevStep();
    this.props.handlePreviousStep(1);
}


onAddressNameChange = event =>
this.setState({ addressName: event.target.value });


handleAddAddress = async (event)=>{
  event.preventDefault();

  let city = this.props.city
  // let area = this.props.area


  let found = this.props.supported_places.results.find((place) => 
    
    (  place.name_alt === city|| place.name_en === city||  place.name_ar === city) && (  place.country === this.props.country || place.country_ar === this.props.country )
  )


 
    if(  found === undefined) 

    {
      
    let locale = this.props.locale
    let lang = locale ? ar : en
      notify(lang.errors.addressNotSupported, 'danger')
    } 
    else{
    this.createAddress()
  }
}


createAddress = async () => {
  
  let locale = this.props.locale
  let lang = locale ? ar : en
  notify(lang.sucssess.addressAdded,'success')
  await this.setState({
    map : {
      address_type: this.state.address_type,
      address: this.props.address,
      area: this.props.area,
      city: this.props.city,
      county: this.props.county,
      state:this.props.state,
      country: this.props.country,
      postal_code: this.props.postal_code,
      lat: this.props.lat,
      lng: this.props.lng,
      mapUrl: this.props.mapUrl,
      default: this.state.default,
  }
  })




  await this.props.postAddress(this.state.map)

  this.props.addressType(this.state.address_type)
  this.props.fetchAddressesList()

}

onAddressTypeChange = event =>{
  this.setState({ [event.target.name]: event.target.value });
}

onIsDefaultChange = event =>{
  this.setState({ [event.target.name]: !this.state.default });
}


  render() {


    let locale = this.props.locale
    let lang = locale ? ar : en


    if (this.props.loading) {

      return <Loading />;
    } else {
        return (




<div className="container">

  <div className="row justify-content-md-center">
    <div className="col-md-12 col-sm-12 col-lg-12 pl-0 pr-0 ">
    <Map  />
    </div>


    <div className="col-md-12 col-sm-12 col-lg-12 pl-0 pr-0">
    <h6 className=" logoColor  mb-8">{lang.map.deliverTo}</h6>
    <p className="mb-0">{this.props.address}</p>
    <p>{this.props.area}, {this.props.city}</p>


    <form >
      <div className="form-group">
          <h6 className=" logoColor " >{lang.map.addressType}</h6>

          <div className="custom-control custom-radio custom-control-inline">
          <input className="form-check-input" type="radio" onChange={this.onAddressTypeChange} name='address_type' id="exampleRadios1"  value='Home' defaultChecked />
          <label className="form-check-label" style={locale?{"marginRight":"26px"}:{"marginLeft":"10px"}} htmlFor="exampleRadios1">
          {lang.map.home}
          </label>
          </div>

          <div className="custom-control custom-radio custom-control-inline">
          <input className="form-check-input" type="radio" onChange={this.onAddressTypeChange} name='address_type' id="exampleRadios2" value='Work' />
          <label className="form-check-label" style={locale?{"marginRight":"26px"}:{"marginLeft":"10px"}} htmlFor="exampleRadios2">
          {lang.map.work}
          </label>
          </div>

          <div className="custom-control custom-radio custom-control-inline pb-3">
          <input className="form-check-input" type="radio" onChange={this.onAddressTypeChange} name='address_type'  id="exampleRadios3" value='Friend'  />
          <label className="form-check-label" style={locale?{"marginRight":"26px"}:{"marginLeft":"10px"}} htmlFor="exampleRadios3">
          {lang.map.friend}
          </label>
          </div>


          <h6 className=" logoColor ">{lang.map.isDefault}</h6>


          <div className="custom-control custom-radio custom-control-inline">
          <input className="form-check-input" type="radio" onChange={this.onIsDefaultChange} name='default' id="exampleRadios4" value='true' />
          <label className="form-check-label" style={locale?{"marginRight":"26px"}:{"marginLeft":"10px"}} htmlFor="exampleRadios4">
          {lang.map.yes}
          </label>
          </div>

          <div className="custom-control custom-radio custom-control-inline pb-3">
          <input className="form-check-input" type="radio" onChange={this.onIsDefaultChange} name='default'  id="exampleRadios5" value='false'  defaultChecked />
          <label className="form-check-label" style={locale?{"marginRight":"26px"}:{"marginLeft":"10px"}} htmlFor="exampleRadios5">
          {lang.map.no}
          </label>
          </div>

          <div className="modal-footer">
            <button onClick={this.handleAddAddress}  type="submit" className="btn btn-outline-warning btn-lg btn-block custom-button-new-address" data-target="#selectAddress" data-dismiss="modal" ><i className="fas fa-plus "></i> {lang.map.create}</button>
          </div>
      </div>
    </form>

    </div>


  </div>


</div>














        )


    }
  }
}


const mapStateToProps = state => {
    return {
        subscriptions: state.productReducer.subscriptions,
        selectedStartDay: state.productReducer.selectedStartDay,
        selectedSub: state.productReducer.selectedSub,
        day: state.productReducer.day,
        loading: state.productReducer.loadingUserAddress,
        isSelectedSubChanged: state.productReducer.isSelectedSubChanged,

        map:state.productReducer.map,
        address:state.productReducer.address,
        area:state.productReducer.area,
        city:state.productReducer.city,
        state:state.productReducer.state,
        lat:state.productReducer.lat,
        lng:state.productReducer.lng,
        mapUrl:state.productReducer.mapUrl,
        country:state.productReducer.country,
        county:state.productReducer.county,
        postal_code:state.productReducer.postal_code,
        supported_places:state.productReducer.supported_places,
        locale: state.productReducer.locale,


    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleSeletedStartDate: (date) => dispatch(handleSeletedStartDate(date)),
        handleSeletedDay: (day) => dispatch(handleSeletedDay(day)),
        fetchMeals: (date,id) => dispatch(fetchMeals(date,id)),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        handleLoadingMealsStatus: () => dispatch(handleLoadingMealsStatus()),
        change_selected_date_to_true: () => dispatch(change_selected_date_to_true()),
        postAddress: (address) => dispatch(postAddress(address)),
        addressType: (type) => dispatch(addressType(type)),
        fetchAddressesList: () => dispatch(fetchAddressesList()),
        fetchSupportedPlaces: () => dispatch(fetchSupportedPlaces()),

        

    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Address);
  