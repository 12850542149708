import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { passwordValidate} from "../../../store/actions/authActions";
import { resetErrors } from "../../../store/actions/errorActions";

// Components
import TextFieldGroup from "../../common/TextFieldGroup";

import 'react-day-picker/lib/style.css';


import ar from '../../../assets/locales/ar.json'
import en from '../../../assets/locales/en.json'

class ResetPasswordStepTwo extends Component {
    state = {

        password: "",
        password2:"",

      };
    




  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    
    let errorChanged = `${e.target.name}_changed`
    this.setState({[errorChanged]:false})


  }

  onSubmit = e =>{
    e.preventDefault();
    this.props.passwordValidate({
        phone_number:this.props.auth.reset_phone_number,
        password: this.state.password,
        password2:this.state.password2
      

    }, 3);

    this.setState({
      password_changed:true,
      password2_changed:true,
    })
    this.props.resetErrors()

  }

   TogglePassword = (elementId) => {
    var x = document.getElementById(elementId);
    if (x.type === "password") {
        x.type = "text";
    } else {
        x.type = "password";
    }
}


  render() {
    const { errors, } = this.props;
    
    let locale = this.props.locale
    let lang = locale ? ar : en

    return (


      <div className={locale? 'rtl login': "ltr login"}>
    <div className="container">
      <div className="row">
        <div className="col-md-8 m-auto">
          <h1 className="display-4 text-center mb-4 logoColor">{lang.auth.newPassword}</h1>
          <p className="lead text-center">{lang.auth.somethingClover}</p>
          <form className="needs-validation"  onSubmit={this.onSubmit}>

                                         

                          <TextFieldGroup
                            inputCustomClassName={locale?"textFieldGroup_rtl":"textFieldGroup_ltr"}

                            name="password"
                            required ={true}
                            label={<p className="requiredField">{lang.auth.Passwrd}  <span className="requiredColor"> * </span> </p> } 
                            placeholder= { lang.auth.PasswrdPlaceholder}
                            IconLeft='fas fa-lock icon-left'
                            IconRight={ locale?'fa fa-eye icon-eye is-reverse_rtl': 'fa fa-eye icon-eye is-reverse'}
                            toggleID='password'
                            type="password"
                            value={this.state.password}
                            onChange={this.onChange}
                            errors={this.state.password_changed  && (errors.password || errors.non_field_errors)}
                          />
                          
                          <TextFieldGroup
                            inputCustomClassName={locale?"textFieldGroup_rtl":"textFieldGroup_ltr"}

                            name="password2"
                            required ={true}
                            label={<p className="requiredField">{lang.auth.confirmPasswrd}  <span className="requiredColor"> * </span> </p> } 
                            placeholder= { lang.auth.PasswrdPlaceholder}
                            IconLeft='fas fa-lock icon-left'
                            IconRight={ locale?'fa fa-eye icon-eye is-reverse_rtl': 'fa fa-eye icon-eye is-reverse'}
                            toggleID='password2'
                            type="password"
                            value={this.state.password2}
                            onChange={this.onChange}
                            errors={this.state.password2_changed && (errors.password2 || errors.non_field_errors || errors[0])}
                          />

            <div className="col-md-12 mb-3">
              <button type="submit" className="btn btn-lg btn-outline-warning custom-detail-meal  btn-block  mt-4 mx-2" > <strong>{lang.auth.verify}</strong></button>       
            </div>
          
          </form>

        </div>
      </div>
    </div>
  </div>
    );
  }
}



const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errorReducer.errors,
  date_of_birth: state.productReducer.date_of_birth,
  new_user: state.auth.new_user,
  locale: state.productReducer.locale,


});

// const mapDispatchToProps = dispatch => {
//   return {
//     signupSendOTP: (email) => dispatch(signupSendOTP(email)),
//     handleNextRegistrationStep: (step) => dispatch(handleNextRegistrationStep(step))
//   };
// };

export default connect(
  mapStateToProps,
  {  resetErrors,passwordValidate }
)(ResetPasswordStepTwo);
