import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import healthzone from "../../assets/images/healthZoneLogo.png"
import { logoutUser } from "../../store/actions/authActions";
import { handleChangeLocal } from "../../store/actions/productAction";
import $ from 'jquery';
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class Navbar extends Component {


  state={
    language:"En"
  }


componentDidMount(){
// this.toggleClass()
if($('.menu-trigger').length){
  $(".menu-trigger").on('click', function() {	
    $(this).toggleClass('active');
    $('.header-area .nav').slideToggle(200);
  });
}
}



handleChangeLocal=()=>{
  this.setState({language: this.props.locale?"ع":"En"})
  this.props.handleChangeLocal(this.props.locale);

}



toggleClass = () => {
  $(".menu-trigger").removeClass('active');
  // $(".menu-trigger").toggleClass('active');
  $('.nav').css('display', 'none');
}


  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser(this.props.history);
    $(".menu-trigger").removeClass('active');
    $('.nav').css('display', 'none');
  }

  render() {
    let locale = this.props.locale
    let lang = locale ? ar : en
    const { isAuthenticated, user } = this.props.auth;
    const authLinks = (
      
      <ul className="nav">

        <li className="" >
        <NavLink to={`/profile`}  exact={true} onClick={()=>this.toggleClass()} className="nav-link "  >  <i  className="fas fa-user mr-1"></i> {lang.nav.myAccount}</NavLink>
        </li>


        <li className="">
        <NavLink to={`/subscribe`} onClick={()=>this.toggleClass()} className="nav-link" >  <i className="fas fa-concierge-bell mr-1"></i>{lang.nav.subscribe}  </NavLink>
        </li>
        <li className="">
        <NavLink to={`/orders`} onClick={()=>this.toggleClass()} className="nav-link" >  <i className="fas fa-bell mr-1"></i> {lang.nav.my_orders}  </NavLink>
        </li>
        <li className=" logout">
        <button  className="nav-link logout"  onClick={this.onLogoutClick.bind(this)} >  <i className="fas fa-sign-out-alt mr-2"></i> {lang.nav.logout}  </button>
        </li>

      </ul>

    );


    const StaffLinks = (
      
      <ul className="nav">

        <li className="">
        <NavLink to={`/profile`} exact={true} onClick={()=>this.toggleClass()} className="nav-link " >  <i  className="fas fa-user mr-1"></i> {lang.nav.myAccount}</NavLink>
        </li>

        <li className="">
        <NavLink to={`/logistic`} onClick={()=>this.toggleClass()} className="nav-link" >  <i className="fas fa-concierge-bell mr-1"></i> {lang.nav.logistic}   </NavLink>
        </li>

        <li className="">
        <NavLink to={`/kitchen`} onClick={()=>this.toggleClass()} className="nav-link" >  <i className="fas fa-bell mr-1"></i> {lang.nav.kitchen}  </NavLink>
        </li>

        <li className=" logout" >
        <button  className="nav-link logout"  onClick={this.onLogoutClick.bind(this)} >  <i className="fas fa-sign-out-alt mr-2"></i> {lang.nav.logout}  </button>
        </li>

      </ul>

    );


    const anonymousLinks = (
      <ul className="nav">
        <li className="">
        <NavLink to={`/subscribe`} onClick={()=>this.toggleClass()} className="nav-link" >  <i className="fas fa-concierge-bell mr-1"></i> {lang.nav.subscribe}   </NavLink>
        </li>
        <li className="">
        <NavLink to={`/signup`} onClick={()=>this.toggleClass()} className="nav-link" > {lang.nav.signup}  </NavLink>
        </li>
        <li className="">
        <NavLink to={`/login`} onClick={()=>this.toggleClass()} className="nav-link" > {lang.nav.signin}  </NavLink>
        </li>
      </ul>
    );

    return (

      // <button onClick={()=>this.props.history.push('/')} className="user-btn-circle"><i  className="fas fa-user"></i></button>

      <header className="header-area header-sticky wrapper" >
      <div className="container nvaCustomContainer">
          <div className="row" style={{background:"#ffffff00"}}>
              <div className="col-12">
                  <nav className="main-nav">
                      <Link to="/" onClick={()=>this.toggleClass()} className="logo" > <img className="hideBackground  " alt="healthzone" src={healthzone}></img> <em> </em> </Link>
                      
                      <button onClick={()=>this.handleChangeLocal()} className="btn-circle">{this.state.language}</button>
                      
                      {/* <button onClick={()=>this.props.history.push('/')} className="user-btn-circle"><i  className="fas fa-user"></i></button> */}

                      {!isAuthenticated?anonymousLinks: user.is_staff ? StaffLinks :authLinks }
                      <div className='menu-trigger'>
                          <span>Menu</span>
                      </div>
                  </nav>
              </div>
          </div>
      </div>
  </header>







    );
  }
}

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired

};

const mapStateToProps = state => ({
  auth: state.auth,
  locale: state.productReducer.locale,

});

export default withRouter(
  connect(
    mapStateToProps,
    { logoutUser ,handleChangeLocal}
  )(Navbar)
);
