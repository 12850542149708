import React, { Component } from "react";
import {withRouter,} from "react-router-dom"
import { connect } from "react-redux";
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class MyDatesList extends Component {
  state = {
    selectedSub:0
  };

    render() {
      let locale = this.props.locale
      let lang = locale ? ar : en

      const dates = this.props.dates

      let title = ''
      let title_ar = ''
      dates.meals.forEach(meal => {
        let selected = `chosen_${dates.date}_${meal.id}`
        if (this.props.productReducer[selected]){
          title += `${meal.name} + `
          title_ar += `${meal.name_ar} + `
        }

      })

      let cleanTitle = title.slice(0,-2)
      let cleanTitle_ar = title_ar.slice(0,-2)



      var myday = new Date(dates.date);
      var mm = String(myday.getMonth() + 1).padStart(2, '0'); //January is 0!
      var dd = String(myday.getDate()).padStart(2, '0');



      var weekday = new Array(7);
      weekday[0] = lang.weekday.Sunday;
      weekday[1] = lang.weekday.Monday;
      weekday[2] = lang.weekday.Tuesday;
      weekday[3] = lang.weekday.Wednesday;
      weekday[4] = lang.weekday.Thursday;
      weekday[5] = lang.weekday.Friday;
      weekday[6] = lang.weekday.Saturday;

      var fullDay = weekday[myday.getDay()];

      var dateOnly = dd  + '-' +  mm     ; 




      // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    
      // let mealDate = locale? myday.toLocaleDateString('ar-EG',options ) : myday.toLocaleDateString(undefined, options)
    
    

      return (






            <div className="card p-0 col-lg-12 col-md-12 col-12 mr-auto ml-auto mb-4 allMealsShadow " 
              >

                <div className='row no-gutters '>
                
                    <div className="col m-auto ">
                        <div className="card-body p-0 ">
                            <p className="card-text centerHeader mb-0">{fullDay}</p>
                            <p className="card-text centerHeader">{dateOnly}</p>
                            {/* <p className="card-text centerHeader">{mealDate}</p> */}
                        </div>
                    </div>

                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-8 col-8">
                        <div className="card-body pt-2 pb-2 pl-2">
                           
                            <p className="card-title mb-1 centerHeader">{locale?cleanTitle_ar:cleanTitle}</p>
                                                      
                        </div>
                    </div>

                </div>
            </div>




      );
    }
  }
  
  
  const mapStateToProps = state => {
    return {
      productReducer: state.productReducer,
      locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyDatesList));
  