import React, { Component } from "react";
import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import { handleSeletedSub } from "../../store/actions/productAction";
import { handleNextStep } from "../../store/actions/productAction";

import notFound from '../../assets/images/nf.png'

import 'react-day-picker/lib/style.css';

import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class SubscriptionsList extends Component {
  state = {
  };



checkSelected = async (sub) => {
  await this.props.handleSeletedSub(sub);
  this.props.handleNextStep(3);

}






    render() {
      let locale = this.props.locale
      let lang = locale ? ar : en
      const selectedSub = this.props.selectedSub
      const subscription = this.props.subscription;
      let customClass = locale ? "textCaptionBody arDir": "textCaptionBody "


      const StyleTrue = {paddingTop:"9px", paddingBottom:"9px", borderRadius:"30px 0px 60px 0px" , borderColor:"#b9702a", border:"3px solid", backgroundImage:`url(${subscription.image? subscription.image:notFound})`,boxShadow: "rgb(199, 137, 124) -6px 7px 12px 0px"}
      const StyleFalse = {paddingTop:"9px", paddingBottom:"9px", borderRadius:"30px 0px 60px 0px", backgroundImage:`url(${subscription.image? subscription.image:notFound})` , boxShadow: "rgba(105, 103, 103, 0.82) -6px 7px 12px 0px"}
      return (

            <div 
                id = {subscription.id}  
                className="col-xl-8 col-lg-9 col-md-11 col-sm-12 col-11 mb-4 pr-0 pl-0  bg-light text-black  cardStyle  custom-card-style"
                style={ selectedSub.id === subscription.id ? StyleTrue : StyleFalse }
                onClick={ () => this.checkSelected(subscription)} 
                >
 
              <div className="carousel-caption d-md-block textCaption">
                  <h3 className={customClass}>{locale ? subscription.name_ar: subscription.name}</h3>
                  <h5 className={customClass}>{locale ? subscription.period_name_ar : subscription.period_name}, {locale ? subscription.displayed_number_ar:subscription.displayed_number},{lang.no}  {subscription.number_of_meals} { subscription.number_of_meals > 1 ? lang.noms : lang.nom}</h5>
                  <h5 className={customClass} >{subscription.price}  {lang.currency}</h5>
              </div> 
            </div>          



      );
    }
  }
  
  
  const mapStateToProps = state => {
    return {
      selectedSub: state.productReducer.selectedSub,
      step: state.productReducer.step,
      locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleSeletedSub: (sub) => dispatch(handleSeletedSub(sub)),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionsList));
  