import { combineReducers } from "redux";

// Reducers
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import productReducer from "./productReducer";
import orderReducer from "./orderReducer";

export default combineReducers({
  auth: authReducer,
  errorReducer: errorReducer,
  productReducer:productReducer,
  orderReducer:orderReducer,
});
