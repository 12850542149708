import React, { Component } from "react";
import { connect } from "react-redux";


class TermsAndConditionsEn extends Component {

  render() {

    return (
        <div className="container">


<p className="ltrTextTerms">

Welcome to Masarat as Saha website for provision of Health Zone brand’s meals. 
The website/app's purpose is to serve our customers. 
The use of this website/app is subject to the following terms and conditions (“Terms and Conditions” or “Terms of Use”)
Please read the following terms and conditions carefully before using this website/app
As the use of this website/app by the user is considered an acceptance of such terms and conditions. 
If you do not agree to these terms, and conditions, please do not use this website.

By using, visiting, shopping/ordering, or browsing website or app, you are accepting, without any restrictions, such terms and conditions, which constitute a binding contract between both you and Health Zone.

(jointly referred to as "Health Zone" or "We”) and the user (referred to as “You” or “User”). 

Terms and conditions including terms of ordering via website/app, in addition to the privacy policy and other policies constitute the entire agreement (Agreement).

</p>


<br/>
<br/>
<h4 className="ltrHeaderTerms">General Provisions</h4>

<p className="ltrTextTerms">

These terms and conditions govern the use of website/app, purchase of items via the website/app, and use/purchase of any services from or via the website/app.
Such terms and conditions are subject to changes at any time without notice by Health Zone. 
Further, the most recent terms and conditions are applicable. Accordingly, reviewing the terms and conditions published on the website/app from time to time is highly recommended. 
As your use of the website is considered an acceptance of the agreement, reviewing the agreement documents in detail is advised. 
Further, in any event, the lack of the optional review of the agreement documents neither change nor disaffirm your acceptance of the agreement.
Moreover, you agree to comply with all applicable laws related to your use of the website, and agree that all data provided by you is true and accurate according to your absolute knowledge and information. 
Furthermore, you acknowledge that you do not rely on any declarations and/or guarantees that have not been expressly indicated in the terms and conditions.

</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Placing Orders</h4>

<p className="ltrTextTerms">

Via the app or website, user can place orders, select available meals, and specify the place and date of delivery provided that the selected delivery times range from 08:00 a.m. to 05:00 p.m. on Sundays to Thursdays taking into consideration that specified delivery time may vary.
Health Zone may cancel the order sometimes including but not limited to, in the event of non-availability of meals or delivery range is out of our delivery area. In this case, customer will not pay for the meal and the full amount will be refunded in the event of the payment. Knowing that you may reschedule your meal delivery date, or change the meal any day until 03:00 p.m. of the day before the actual meal receipt date by referring to customer service, or via website or app.


</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Description</h4>

<p className="ltrTextTerms">

Although the description of meals presented on the app is accurately identical to the meal’s contents, in some events some ingredients may be replaced by other similar ingredients, if they are unavailable.
The shown images are mainly for marketing purposes. This will not contradict our commitment to provide meals at their best.

</p>


<br/>
<br/>
<h4 className="ltrHeaderTerms">Delivery </h4>

<p className="ltrTextTerms">


Now, available in Riyadh only. Please refer to customer service to confirm delivery areas.

Delivery times start from 8:00 a.m. to 5:00 p.m. on Sundays to Thursdays taking into consideration that delivery time specified by customer may vary by a maximum of 30 minutes, either before or after the set delivery time. 
If the delay exceeds such period, customer will have the right to reschedule meal delivery or cancel the order; the amount will be refunded or the meal will be delivered through the branch. The food delivery deadline is 08:00 p.m.
Customer should be available at the delivery time and ensure receipt of the meal. In the event of an inability to reach you or your representative, we will try to contact you on your registered phone number. 
If we could not reach you, the order would be effective, and you would be responsible for full payment. 
Further, we would not be responsible for your absence at the time of delivery or failure to authorize a representative.
To ensure meal quality, we recommend eating it immediately upon delivery, otherwise, the meal should be preserved immediately upon receipt in the refrigerator to maintain the meal health and nutritional value for a period that does not exceed 24 hours, and refraining from eating it after that.
Health Zone exerts due diligence to ensure the delivery of your order within the agreed period. However, we will not be responsible in the event of delay due to bad weather and/or traffic jams, or any force majeure.


</p>


<br/>
<br/>
<h4 className="ltrHeaderTerms">Payment</h4>

<p className="ltrTextTerms">



All prices are in Saudi riyals. Prices are correct as indicated on the website and app. Further, prices include delivery fees within the delivery area and VAT. Health Zone has the right to review and amend prices from time to time. 

Payment may be made online (credit card, MasterCard or mada Pay).
Customer should pay the subscription fee in advance at the commencement of subscription, and payment should not be postponed to the middle or end of the subscription period.

Subscription is considered active in case of the completion of selecting all meals and processing payment through any indicated method.

Credit or prepaid cards used by customer for payment via electronic payment gateway on the Health Zone website or app should be owned by the customer. In the event that the card is owned by another person, customer should use it legally upon the card holder’s approval.

</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Discounts & Offers</h4>

<p className="ltrTextTerms">



Health Zone is not responsible for any discount codes that are not provided by its official representatives from sales and marketing or customer service departments.
Company discounts are provided exclusively for company individuals, who have been granted discount codes. 
Further, any use of these codes outside the company will not be activated, and Health Zone will have the right to collect full subscription fees.
Customer should not use any discount code if there is a discount from Health Zone on the subscription.
Customer should not use more than one discount nor code on the same transaction. 
Only one discount or code should be used for the one subscription. 

</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Subscription Cancellation</h4>

<p className="ltrTextTerms">


In case of cancelling the subscription during the paid period, the amount will be refunded to customer after deducting 10% of the paid amount as administrative registration fees. 
In case of subscription utilization, meal price, from which customer benefited, will be deducted as indicated in the menu. Customer is not entitled to unsubscribe after using 60% of the subscription. 
</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Health Zone’s Membership System  </h4>

<p className="ltrTextTerms">


Customer should fill registration form correctly in terms of name, address, mobile number, e-mail, and password to be specified by the member, as “username” and “password” are limited to the members exclusively. 
Membership is exclusive for the user, and he/she is advised not entitled to share it with others. 
Further, the user is severally responsible for such membership. Health Zone does not allow customer to assign the same username and password to the same membership. 
Further, the user is severally responsible for choosing and protecting his/her password as well as any issues that may arise from using the password. 
The user guarantees validity, accuracy, effectiveness, and completion of the provided personal information upon use of this website/app. 
Further, customer immediately informs us of any change to his/her personal information by updating membership information via the website or by contacting customer service. Your use of the Health Zone website, app, or communication is considered an agreement not to pretend to be any other person, entity, or a fake name that you are not authorized to use.  
</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Information Use & Share</h4>
<p className="ltrTextTerms">
Health Zone may share the customer’s phone number and location with the delivery representative "Delivery Company" for the provision of fast and correct delivery service. By accepting the agreement, you agree that Health Zone uses your contact information to promote and inform you of offers and services provided by us from time to time.
</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Intellectual Property Rights</h4>
<p className="ltrTextTerms">

All intellectual property rights relating to the website and app (including but not limited to logo, design, layout, programming, and texts) are owned by us, and should not be copied without prior written approval. 
However, such information may be personally shared with others for a non-economic purpose directly without referring to us.

</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Responsibilities of Members </h4>
<p className="ltrTextTerms">

Members are responsible for their ideas, opinions, and personal data mentioned while using the Health Zone website or app.
Additionally, they are responsible for all files uploaded to Health Zone as well as sent personal information.
In any way, Health Zone is not responsible for such files or accessing unauthorized services provided via Health Zone or in any other way than specified by Health Zone. 
Members should not change the software in any way and should compensate Health Zone for all material and moral damages in the event of noncompliance with the above-mentioned terms.
Members should not harass and/or threaten other users or any employee of Health Zone, and should refrain from acting in any way affecting the use of services by other users. 
Further, members should not send, print, distribute or circulate any immoral or illegal material, or information that may cause harm to names of any person or institution, advertisement, sale or offer for sale of any product and service.
Furthermore, members should not participate in any harmful acts such as scanning, competition, or chain letters. In addition, members should not send any information or programs that may damage information or software on other users' devices.

All records and materials obtained by using Health Zone services are included in the user’s agreement, and the user is entirely responsible for any damage or loss of information or other loss resulting from the user's device. 

Besides, the user is not entitled to claim compensation for any loss arising from the use of service. Further, the user should not use Health Zone services for any commercial or advertising purposes without obtaining the administration’s written approval.


</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Change of terms and conditions </h4>
<p className="ltrTextTerms">

We reserve the right, at our sole discretion, to change or amend these terms and conditions from time to time, and any change will be effective upon being published on the website and app. 
In the event of continuous use of the app, amended terms and conditions will be considered accepted by you. It is customers responsibility to check and revise policy periodically.
</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Force Majeure </h4>
<p className="ltrTextTerms">
We will not bear the responsibility arising from failure or delay of implementation of any obligation entrusted to us under these terms and conditions or terms of ordering via website or app resulting from events and circumstances of force majeure that is beyond our control.

</p>

<br/>
<br/>
<h4 className="ltrHeaderTerms">Governing Law</h4>

<p className="ltrTextTerms">

This agreement, and any issues and disputes arising under these terms and conditions will be interpreted in accordance with Saudi Arabia laws.</p>

<br/>
<br/>

<h4 className="ltrHeaderTerms">Notices & Notifications</h4>
<p className="ltrTextTerms">
Health Zone’s notices and notifications should be sent to the user via their registered e-mails, WhatsApp, SMS, the website, or app.

</p>

<br/>
<br/>

<p className="text-center"> The End.. </p>





      </div>
    );
  }
}



const mapStateToProps = state => ({
});

// const mapDispatchToProps = dispatch => {
//   return {
//     signup: (email) => dispatch(signup(email)),
//     handleNextRegistrationStep: (step) => dispatch(handleNextRegistrationStep(step))
//   };
// };

export default connect(
  mapStateToProps,
)(TermsAndConditionsEn);
