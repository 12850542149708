import React from "react";
import { connect } from "react-redux";

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { handleSeletedBirthday,handleLoadingMealsStatus,change_selected_date_to_true ,handleNextStep,handleSeletedStartDate,fetchMeals,handlePreviousStep } from "../../store/actions/productAction";
import YearMonthForm from './YearMonthForm'


class SelectBirthday extends React.Component {
    state = {
        date:"",
        isSelectedDatedChanged:false,
        month: new Date(new Date().getFullYear(), 0),
    };
    

  
  
     componentDidMount() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var nn = String(today.getDay())
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var date = yyyy + '-' + mm + '-' + dd; 
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() + 1)
    
      const tt = tomorrow.getMonth() + 1
      this.setState({date:date,nn:nn, dd:dd,mm:mm,yyyy:yyyy, today:today, tomorrow:tomorrow  ,tt:tt});
  
  
  
  
    }
  
  
    

  

  handleDayClick = (day,  { selected, disabled }) => {
      let formatedDay = day.toISOString().substring(0, 10)

      if (disabled) {
          this.setState({day:undefined})
          this.setState({birthday:undefined})
          this.props.handleSeletedBirthday(null)

          
          return;
        }
        if (selected) {
            this.setState({day:day})
            this.props.handleSeletedBirthday(formatedDay)
            this.setState({birthday:formatedDay})
            
            return;
        }
        
        this.setState({day:day})
        this.setState({birthday:formatedDay})
        this.props.handleSeletedBirthday(formatedDay)

    }
  
    handleYearMonthChange = (month) =>{
        this.setState({ month });
      }

  render() {
    return (
        <div className="row justify-content-center aabb " >        
        <DayPicker
        className="Birthday"
        month={this.state.month}
        onDayClick={this.handleDayClick}
        selectedDays={this.state.day}
        initialMonth={this.state.today}
        toMonth={this.state.today}
        showOutsideDays
        enableOutsideDaysClick
        showPreviousYear
        showNextYear
          captionElement={({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={this.handleYearMonthChange}
            />
          )}
        />

      </div>
);
}
}


const mapStateToProps = state => {
    return {
        subscriptions: state.productReducer.subscriptions,
        selectedStartDay: state.productReducer.selectedStartDay,
        selectedSub: state.productReducer.selectedSub,
        day: state.productReducer.day,
        loading: state.productReducer.loading,

        isSelectedSubChanged: state.productReducer.isSelectedSubChanged,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleSeletedStartDate: (date) => dispatch(handleSeletedStartDate(date)),
        handleSeletedBirthday: (day) => dispatch(handleSeletedBirthday(day)),
        fetchMeals: (date,id) => dispatch(fetchMeals(date,id)),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        handleLoadingMealsStatus: () => dispatch(handleLoadingMealsStatus()),
        change_selected_date_to_true: () => dispatch(change_selected_date_to_true()),

        

    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(SelectBirthday);
  