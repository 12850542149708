
import React, { Component } from "react";
import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import {deliveryOption,handlePayment, fetchCoupon,handlePreviousStep} from "../../store/actions/productAction";
import MyMeals from "../MyMeals";
import  {notify} from '../Notifications'
import Loading from "../Loading";
import SelectAddress from '../Address/SelectAddress'
import Login from '../auth/Login'
import InternalErrorIMG from '../../assets/images/500.jpg'
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class Checkout extends Component {
    state = {
        DeliveryOption:'P',
        option1:'P',
        option2:'D',
        code:'',
        showAll:false,
        loading:false,
        redirect: false,
        timer:10
    }




    componentDidMount(){
        this.setState({
            width:window.innerWidth,
            height:window.innerHeight
        })
    }

    back = e => {
        e.preventDefault();
        // this.props.prevStep();
        this.props.handlePreviousStep(4);
      }
      
    //   onDeliveryChange = event =>
    //   this.setState({ [event.target.name]: event.target.value });

    onDeliveryChange = event =>{
        // this.setState({ DeliveryOption: event.target.value });
        this.props.deliveryOption(event.target.value)
    }

    onPromoChange = event =>{
        this.setState({ code: event.target.value });
        // this.props.deliveryOption(event.target.value)
    }

    handleCoupon = async () =>{
        let locale = this.props.locale
        let lang = locale ? ar : en

        await this.props.fetchCoupon(this.state.code)
        this.setState({temp:this.state.code})

        if (this.props.coupon){
            notify(lang.sucssess.couponAdded, 'success')

        }
        else if (this.props.errors){
            
            notify( lang.errors.couponNotFound, 'danger')
        }
    }

    handleShowAll = ()=>{
        this.setState({showAll:!this.state.showAll})
    }

    checkout = () =>{

        let locale = this.props.locale
        let lang = locale ? ar : en

        
        if (this.props.DeliveryOption === "D" && this.props.address_type === ""){
            notify( lang.errors.validDeliveryAddress, 'danger')
        }

        else{

            var selectedMeals = Object.keys(this.props.productReducer)
            .filter(element => element.startsWith("chosen_") && this.props.productReducer[element] === true)
            .map(element=> element.slice(7,).split("_"))
    
            this.props.handlePayment(this.props.selectedSub.id, this.props.DeliveryOption, this.props.coupon ? this.props.coupon.code :null, selectedMeals, this.props.address_type, this.props.startDate)
            this.setState({loading:true})
        }

    }

    render() {
        const { errors} = this.props;
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };


        // if (this.props.DeliveryOption === 'D' && !this.props.isAuthenticated ) {
        //     this.props.history.push('/login') 
        // } 

        let locale = this.props.locale
        let lang = locale ? ar : en

        let chosen_address = ""
        let address_type = this.props.address_type
         if (address_type === "Home" ){
             chosen_address = lang.home
         }
         if (address_type === "Friend" ){
             chosen_address = lang.friend
         }
         if (address_type === "Work" ){
             chosen_address = lang.work
         }


        let subTotal = 0
        if (this.props.DeliveryOption === 'D'){
            subTotal= this.props.selectedSub.delivery_fee + this.props.selectedSub.price
        }
        else{
            subTotal= this.props.selectedSub.price            
        }
        
        let coupon = this.props.coupon && this.props.coupon.code && this.props.coupon.amount * subTotal

        if(coupon){
            subTotal = subTotal - coupon
        }
        
        let vat = subTotal * this.props.selectedSub.vat

        let total = vat + subTotal 


        let showAll = this.state.showAll? lang.checkout.show_less : lang.checkout.show_all
        let showIcon = this.state.showAll? <i className={locale?"fas fa-minus ml-2 ":"fas fa-minus mr-2 "} style={{color:"#a4a0a1"}}></i>   : <i className={locale?"fas fas fa-plus ml-2 ":"fas fas fa-plus mr-2 "} style={{color:"#a4a0a1"}}></i>
        let showClass = this.state.showAll? "ShowAllMeals" : "MyMeals"






        if (this.props.errors.code === "500"){
            return <div className="row justify-content-center">
                        <img src={InternalErrorIMG} alt="bg" className="payment-img col-6"/>
                        <h6 className="redirectTimerText col-12"> {this.props.errors.response} Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6>
                    </div>            
        } 
        

        if (this.state.loading) {
            return <Loading />
          } else {


        return (
                <div className="  container  col-lg-7 col-md-10  mb-3" 
                style={locale?{direction:"rtl",textAlign:"right",width:"100%", maxWidth:""} :  {width:"100%", maxWidth:""}}  >
                <button onClick={this.handleShowAll} className="btn btn-white hideShdow pl-0 pr-0" > <strong className={this.state.showAll? "zoomOut " : "zoomIn"}> {showIcon}  <i style={{color:"#c98e82"}}>{showAll} </i> </strong>  </button>
                

                <div className={showClass}>
                <MyMeals/>
                </div>

                <hr></hr>
                    <label className="form-check-label mb-3" htmlFor="formLabel">
                    <strong className="mb-1">   <i style={{color:"#a4a0a1"}} 
                    className={locale? "fas fa-people-carry ml-2":"fas fa-people-carry mr-2"}></i> 
                    <i style={{color:"#c98e82"}}> {lang.checkout.deliver_option.title} </i> </strong>
                    </label>

                    <div className={locale?"form-check mr-1 mb-2":"form-check ml-1 mb-2"}>
                        <input className="form-check-input" type="radio" onChange={this.onDeliveryChange} name='DeliveryOption' id="exampleRadios6" value={this.state.option1} defaultChecked={this.props.DeliveryOption === this.state.option1}  />
                        <label className="form-check-label" htmlFor="exampleRadios6" style={locale ?{marginRight: "29px"}:{marginLeft: "14px"}}>
                            {lang.checkout.deliver_option.pickup}
                        </label>
                    </div>

                    <div className={locale?"form-check mr-1":"form-check ml-1"} >
                        <input  data-toggle="modal" data-target="#selectAddress" className="form-check-input" type="radio" onChange={this.onDeliveryChange} name='DeliveryOption'  id="exampleRadios7" value={this.state.option2}  defaultChecked={this.props.DeliveryOption === this.state.option2}/>
                        <label className="form-check-label" htmlFor="exampleRadios7" style={locale ?{marginRight: "29px"}:{marginLeft: "14px"}}>
                        {(this.props.DeliveryOption === "D" && this.props.address_type === "") ?  lang.errors.validDeliveryAddress : lang.checkout.deliver_option.delivery }  {chosen_address}
                        </label>
                    </div>
                <hr></hr>
                

                <div className="modal  fade"   id="selectAddress" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"  style={locale ? {direction:"rtl",textAlign:"righ"}:{direction:"ltr",textAlign:"left"}}>
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header" style={{direction:"ltr",textAlign:"left"}}>
                          <h4 className="centerHeader logoColor  mb-0">
                              
                              
                              {this.props.isAuthenticated ?  lang.checkout.slect_your_address : lang.auth.signIn}
                              </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body mt-0 col-12">

                          {(this.props.DeliveryOption === 'D' && this.props.isAuthenticated ) && <div className="col-12 p-0"> <SelectAddress/> </div>  }
                          {(this.props.DeliveryOption === 'D' && !this.props.isAuthenticated ) && <div className="col-12 p-0"> <Login/> </div>  }

                          </div>

                        </div>
                      </div>
                </div>

                    
                    <strong >   <i style={{color:"#a4a0a1"}} className={locale ? "fas fa-percent ml-2":"fas fa-percent mr-2"}></i> <i style={{color:"#c98e82"}}> {lang.checkout.promoCode}</i> </strong>

                    <div className={`input-group mb-1 mt-2 ${errors.detail && "is-invalid"}`}>
                        <input type="text" className="form-control noRadius" onChange={this.onPromoChange} value={this.state.code}  aria-describedby="button-addon2"/>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary noRadius"  onClick={()=>this.handleCoupon()} type="button" id="button-addon2">{lang.checkout.activate}</button>
                        </div>
                    </div>
                    {/* <div className="invalid-feedback">{this.state.code === this.state.temp? errors.detail: '' }</div> */}

                <hr></hr>


                <strong className="">   <i style={{color:"#a4a0a1"}} className={locale?"fas fa-money-bill-alt ml-2":"fas fa-money-bill-alt mr-2"}></i> <i style={{color:"#c98e82"}}> {lang.checkout.priceDetail.title}</i> </strong>
                




                <table className="table custom-border table-bordered  table-sm  mt-3  col-lg-8 col-md-8  table-borderless">


                    <tbody className="">
                        <tr>
                            <td className="">{lang.startDate}</td>
                            <td className="pl-2 ">= {locale? this.props.day.toLocaleDateString('ar-EG',options ) : this.props.day.toLocaleDateString(undefined, options)}    </td>
                        </tr>
                        <tr>
                            <td className="">{lang.endDate}</td>
                            <td className="pl-2 ">= {locale? this.props.endDay.toLocaleDateString('ar-EG',options ) : this.props.endDay.toLocaleDateString(undefined, options)}   </td>
                        </tr>
                        <tr>
                            <td className="">{lang.checkout.priceDetail.sub}</td>
                            <td className="pl-2 ">= {this.props.selectedSub.price} {lang.currency}</td>
                        </tr>

                        {this.props.DeliveryOption === 'D'? 
                        <tr>
                            <td className="">{lang.checkout.priceDetail.delivery}</td>
                            <td className="pl-2 ">= {this.props.selectedSub.delivery_fee} {lang.currency}</td>
                        </tr> : null
                        }

                        {coupon ?    
                            <tr>
                                <td className="">{lang.checkout.priceDetail.coupon}</td>
                                <td className="pl-2 ">= {this.props.selectedSub.delivery_fee} {lang.currency}</td>
                            </tr>: null
                        }

                        <tr>
                            <td className="">{lang.checkout.priceDetail.subTotal}</td>
                            <td className="pl-2 ">= {subTotal} {lang.currency}</td>
                        </tr>

                        <tr>
                            <td className="">{lang.checkout.priceDetail.vat}</td>
                            <td className="pl-2 ">= {vat} {lang.currency}</td>
                        </tr>

                        <tr>
                            <th className="" >{lang.checkout.priceDetail.total}</th>
                            <th className=" pl-2 ">= {total} {lang.currency}</th>
                        </tr>

                    </tbody>


                </table>



                <hr></hr>

<br></br>
                <div 
                className="row justify-content-center recomendedMeal" 
                style={locale ? {direction:"ltr",textAlign:"center"}:{}}

                >
                        <button  
                            onClick={this.back} 
                            className="btn btn-sm btn-outline-warning custom-detail-meal     mr-3 col-md-5  col-sm-4  col-5"
                            style={{marginTop:'.5rem'} }> <strong className="ButtonText"> <i className="far fa-edit mr-2"></i>  {lang.checkout.editMeals}</strong>   
                        </button>

                        <button  
                            // style={locale ? {direction:"rtl",textAlign:"center",marginTop:'.5rem'}:{marginTop:'.5rem'}}
                            onClick={this.checkout} 
                            className="btn btn-sm btn-outline-warning custom-detail-meal      col-md-5  col-sm-4  col-5 "
                            style={{marginTop:'.5rem'} }
                            >
                            <strong  className="ButtonText ">   {lang.checkout.checkOut} <i className="fas fa-credit-card ml-2" ></i> </strong>   
                        </button>
                </div>

                <br/>
              <br/>
            </div>


        )
    }
}
}


// Checkout.propTypes = {
//     // loginUser: PropTypes.func.isRequired,
//     // resetErrors: PropTypes.func.isRequired,
//     // auth: PropTypes.object.isRequired,
//     errors: PropTypes.object.isRequired
//   };


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,

        selectedMealDetail: state.productReducer.selectedMealDetail,
        loading: state.productReducer.loadingSelectedMealDetail,
        DeliveryOption: state.productReducer.DeliveryOption,
        selectedSub: state.productReducer.selectedSub,
        coupon: state.productReducer.coupon,
        errors: state.errorReducer.errors,
        address_type: state.productReducer.address_type,
        productReducer: state.productReducer,
        startDate:state.productReducer.selectedStartDay,
        locale: state.productReducer.locale,

        day: state.productReducer.day,
        endDay: state.productReducer.endDay,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        deliveryOption: (DP) => dispatch(deliveryOption(DP)),
        fetchCoupon: (code) => dispatch(fetchCoupon(code)),
        handlePayment: (sub_id,delivery,coupon, selectedMeals,address_type,startDate) => dispatch(handlePayment(sub_id,delivery,coupon, selectedMeals,address_type,startDate)),

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));
  