import React from "react";
import { connect } from "react-redux";

import 'react-day-picker/lib/style.css';
import { handleSeletedDay,handleLoadingMealsStatus,change_selected_date_to_true ,handleNextStep,handleSeletedStartDate,fetchMeals,handlePreviousStep } from "../../store/actions/productAction";

const currentYear = new Date().getFullYear();
const fromMonth = new Date(1900, 0);
const toMonth = new Date(currentYear + 0, 11);

function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }


  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className="DayPicker-Caption">
      <select name="month" className="DayPickerMonth" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      
      <select name="year"  onChange={handleChange} value={date.getFullYear()}>

        {years.map(year => (
          <option key={year} value={year} >
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        subscriptions: state.productReducer.subscriptions,
        selectedStartDay: state.productReducer.selectedStartDay,
        selectedSub: state.productReducer.selectedSub,
        day: state.productReducer.day,
        loading: state.productReducer.loading,
        isSelectedSubChanged: state.productReducer.isSelectedSubChanged,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleSeletedStartDate: (date) => dispatch(handleSeletedStartDate(date)),
        handleSeletedDay: (day) => dispatch(handleSeletedDay(day)),
        fetchMeals: (date,id) => dispatch(fetchMeals(date,id)),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        handleLoadingMealsStatus: () => dispatch(handleLoadingMealsStatus()),
        change_selected_date_to_true: () => dispatch(change_selected_date_to_true()),

        

    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(YearMonthForm);
  