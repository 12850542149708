import React from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'


const Footer = props => {
  
  let locale = props.locale
  let lang = locale ? ar : en
  return (
    // <footer className="bg-light text-secondary mt-5 p-2 text-center myFooter">
    //   Copyright &copy; {new Date().getFullYear()} Hamad Almogbl
    // </footer>


    <footer className="bg-light text-secondary mt-5 p-2 text-center myFooter" style={{zIndex:"10"}}>

<div className="  row justify-content-center  " style={locale ? {direction:"rtl"}:{}}>


  <div className="pr-2 pl-2" >      
    {/* Copyright © HealthZone {new Date().getFullYear()} All rights reserved. */}
    {lang.copyright_co}
  </div>

  <div className="" >      
    {/* Developed By Hamad Almogbl  */}
    {lang.copyright_dev}

    <a className=" text-secondary " href="https://twitter.com/halmogbl"> <i className="fab fa-twitter ml-2"></i> </a>
    <a className=" text-secondary " href="https://www.linkedin.com/in/hamad-almogbl/"> <i className="fab fa-linkedin-in ml-2"></i> </a>
  </div>

</div>



    </footer>

  );
};



const mapStateToProps = state => ({
  locale: state.productReducer.locale,

});

export default withRouter(
  connect(mapStateToProps)(Footer)
);