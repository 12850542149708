import React from "react";
import { connect } from "react-redux";

import Loading from "../Loading";
import 'react-day-picker/lib/style.css';
import {postAddress,fetchAddressesList, handleSeletedDay,handleLoadingMealsStatus,change_selected_date_to_true ,handleNextStep,handleSeletedStartDate,fetchMeals,handlePreviousStep } from "../../store/actions/productAction";
import Address from './index'
import ReactGA from 'react-ga';

import AddressesList from './AddressesList'

import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class SelectAddress extends React.Component {
  state = {
    supportedCities:['Al Jubayl','Riyadh'],
    showMap:false
  };

   async componentDidMount() {

    ReactGA.pageview('Selecet Your Address');

    if ( this.props.UserAddress.count === 0){
      await this.props.fetchAddressesList()
    } 
  }
    
  async shouldComponentUpdate(nextProps, nextState){

  if (this.props.loading !== nextProps.loading) {
    return true;
  }
  if (this.props.created_address !== nextProps.created_address) {

    return true;
  }

  return false
}


continue = e => {
  e.preventDefault();
  this.props.handleNextStep(2)
}

back = e => {
    e.preventDefault();
    this.props.handlePreviousStep(1);
}


handleClick=()=>{
}


handleChange = event => {
  this.setState({ AddressName:event.target.value });

};

handleShowMap = () =>{
  this.setState({showMap:true})
}

render() {


  let locale = this.props.locale
  let lang = locale ? ar : en
  
  if (this.props.loading) {
    return (<Loading />)
  } 
  else {  


      const ListOfAddresses = this.props.UserAddress.count > 0 && this.props.UserAddress.results.map( myAddress => (

        <AddressesList key={myAddress.id} myAddress={myAddress}  />
      
      )
      )
      return (

        <div className="container YourDeleviryLocationContainer">



                      <br></br>
  
                          <div className="row justify-content-center " >
                                {ListOfAddresses}
                          </div>

                          <div className=" mt-3 b-0  col-lg-10 m-auto" >
                              
                              <button onClick={this.handleShowMap} data-toggle="modal" data-target="#client_names" className="btn btn-outline-warning mt-2  btn-lg btn-block custom-button-new-address"  type="button" > 
                              
                              
                              <i className="fas fa-plus mr-2 "></i> {lang.map.add_or_update}
                              </button>
                          </div>




                <div className="modal popUpMargin fade" id="client_names" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog ModalMaxWidth" role="document">
                        <div className="modal-content">
                          <div className="modal-header" style={{direction:"ltr",textAlign:"left"}}>
                          <h4 className="centerHeader logoColor  mb-0">{lang.map.add_address}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                          {this.state.showMap && <div className="col-12 p-0"> <Address/> </div> }
                          
                          </div>

                        </div>
                      </div>
                </div>



          </div>
)

}
}
}


const mapStateToProps = state => {
    return {
        subscriptions: state.productReducer.subscriptions,
        selectedStartDay: state.productReducer.selectedStartDay,
        selectedSub: state.productReducer.selectedSub,
        day: state.productReducer.day,
        isSelectedSubChanged: state.productReducer.isSelectedSubChanged,

        map:state.productReducer.map,
        address:state.productReducer.address,
        area:state.productReducer.area,
        city:state.productReducer.city,
        state:state.productReducer.state,
        lat:state.productReducer.lat,
        lng:state.productReducer.lng,
        mapUrl:state.productReducer.mapUrl,
        country:state.productReducer.country,
        county:state.productReducer.county,
        postal_code:state.productReducer.postal_code,

        UserAddress:state.productReducer.UserAddress,
        loading:state.productReducer.loadingUserAddress,
        created_address:state.productReducer.created_address,
        locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleSeletedStartDate: (date) => dispatch(handleSeletedStartDate(date)),
        handleSeletedDay: (day) => dispatch(handleSeletedDay(day)),
        fetchMeals: (date,id) => dispatch(fetchMeals(date,id)),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        handleLoadingMealsStatus: () => dispatch(handleLoadingMealsStatus()),
        change_selected_date_to_true: () => dispatch(change_selected_date_to_true()),
        fetchAddressesList: () => dispatch(fetchAddressesList()),
        postAddress: (address) => dispatch(postAddress(address)),

        

    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(SelectAddress);
  