
import React, { Component } from 'react';

import ResetPasswordStepThree from './ResetPasswordStepThree';
import ResetPasswordStepOne from './ResetPasswordStepOne';
import ResetPasswordStepTwo from './ResetPasswordStepTwo';


import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import { Redirect } from 'react-router';
import ReactGA from 'react-ga';
import { handleResetPasswordSteps} from "../../../store/actions/authActions";


export class ResetPasswordSteps extends Component {


    componentWillUnmount(){
        this.props.handleResetPasswordSteps(1)
    }

    showStep = () => {

        if(this.props.auth.reset_password_step === 1){
            ReactGA.pageview(`/reset/password/check`);
            return (<ResetPasswordStepOne 
            />);

        }


        if(this.props.auth.reset_password_step === 2){
            ReactGA.pageview(`/reset/password/new password`);

            return (<ResetPasswordStepTwo 
            />);
        }

        if(this.props.auth.reset_password_step ===3){
            ReactGA.pageview(`/reset/password/OTP`);

            return (<ResetPasswordStepThree 
            />);
        }


    }

    render(){
        const { isAuthenticated } = this.props.auth;

        return isAuthenticated ? <Redirect to="/subscribe" /> :
            <>
                {this.showStep()}
            </>
    }
}


  
const mapStateToProps = state => {
    return {
      auth: state.auth

    };
  };
  
const mapDispatchToProps = dispatch => {
  return {
    handleResetPasswordSteps: (step) => dispatch(handleResetPasswordSteps(step))
  };
};
  export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ResetPasswordSteps));
  