import React, { Component } from "react";
import { connect } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import "../../assets/css/styles.css";

// Actions
import { signUpNoOTP } from "../../../store/actions/authActions";
import { resetErrors } from "../../../store/actions/errorActions";

// Components
import TextFieldGroup from "../../common/TextFieldGroup";
import 'react-day-picker/lib/style.css';
import SelectBirthday from '../../SelectBirthday'
import TermsAndConditionsAr from '../TermsAndConditions'
import TermsAndConditionsEn from '../TermsAndConditionsEn'

import ar from '../../../assets/locales/ar.json'
import en from '../../../assets/locales/en.json'


class Signup extends Component {
    state = {
        first_name: "",
        last_name: "",
        phone_number: "",
        password: "",
        password2:"",
        email:"",
        email2:"",
        date_of_birth: null,
        weight: null,
        height: null,
        level_of_physical_activity: "",
        blood_type: "",
        sex: ""
      };
    

  componentDidMount(){
    let new_user = this.props.new_user
    new_user.phone_number && 
    this.setState({
      first_name: new_user.first_name,
      last_name: new_user.last_name,
      phone_number: new_user.phone_number,
      // password: new_user.password,
      // password2:new_user.password2,
      email:new_user.email,
      email2:new_user.email2,
      date_of_birth: new_user.date_of_birth,
      weight: new_user.weight,
      height: new_user.height,
      level_of_physical_activity: new_user.level_of_physical_activity,
      blood_type: new_user.blood_type,
      sex: new_user.sex

    })
  }



  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    
    let errorChanged = `${e.target.name}_changed`
    this.setState({[errorChanged]:false})


  }

  onSubmit = e =>{
    e.preventDefault();
    this.props.signUpNoOTP({
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        phone_number: this.state.phone_number,
        password: this.state.password,
        password2:this.state.password2,
        date_of_birth: this.state.date_of_birth,
        email: this.state.email,
        email2: this.state.email2,
        weight: this.state.weight,
        height: this.state.height,
        level_of_physical_activity: this.state.level_of_physical_activity,
        blood_type: this.state.blood_type,
        sex: this.state.sex,
      

    });

    this.setState({
      phone_number_changed:true,
      password_changed:true,
      password2_changed:true,
      first_name_changed:true,
      last_name_changed:true,
      blood_type_changed:true,
      height_changed:true,
      weight_changed:true,
      level_of_physical_activity_changed:true,
      sex_changed:true,
      email_changed:true,
      email2_changed:true,
      date_of_birth_changed:true,
    })




    this.props.resetErrors()

  }

   TogglePassword = (elementId) => {
    var x = document.getElementById(elementId);
    if (x.type === "password") {
        x.type = "text";
    } else {
        x.type = "password";
    }
}


UNSAFE_componentWillReceiveProps(nextProps) {
  const date_of_birth =  this.props.date_of_birth
  if(date_of_birth !== nextProps.date_of_birth) {
    this.setState({date_of_birth:nextProps.date_of_birth,
      date_of_birth_changed:true,})
    }
  }
  render() {
    const { errors, } = this.props;

    let locale = this.props.locale
    let lang = locale ? ar : en
    let inputCustomClassName= locale?" textFieldGroup_rtl":"textFieldGroup_ltr"
    return (
      <div className={locale? 'rtl login': "ltr login"}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center mb-4 logoColor">{lang.auth.signUp}</h1>
              <p className="lead text-center">{lang.auth.somethingClover}</p>

              <form className="needs-validation"  onSubmit={this.onSubmit} >
                <div className="form-row">
                    <div className="form-group col-md-6">
                      <TextFieldGroup
                        inputCustomClassName={locale?" phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}
                        errorMsgStyle="phoneField_rtl"

                        name="phone_number"
                        IconLeft='fas fa-mobile-alt icon-left'

                        label={<p className="requiredField" >{lang.auth.phoneNumber}   <span className="requiredColor"> * </span> </p> }
                        placeholder={lang.auth.phoneNumberPlaceholder}
                        value={this.state.phone_number}
                        onChange={this.onChange}
                        errors={this.state.phone_number_changed  &&(errors.phone_number || errors.non_field_errors)}
                        required ={true}
                      />
                    </div>
                    <div className="form-group col-md-6">



                      <TextFieldGroup
                        inputCustomClassName={inputCustomClassName}


                        name="date_of_birth"
                        IconLeft='far fa-calendar-alt icon-left'
                        type="text"
                        label={<p className="requiredField">{lang.auth.DOB}   <span className="requiredColor"> * </span> </p> }
                        placeholder="YYYY-MM-DD"
                        value={this.state.date_of_birth || ""}
                        dataTarget="#mealDetail"
                        dataToggle="modal"
                        // readOnly={true}
                        required={true}
                        onChange={this.onChange}
                        errors={this.state.date_of_birth_changed && (errors.date_of_birth)}
                      />
                    </div>
                      <div className="form-group col-md-6">

                      <TextFieldGroup
                                  
                        inputCustomClassName={inputCustomClassName}
                        name="first_name"
                        IconLeft='fas fa-user icon-left'
                        type="text"
                        label={<p className="requiredField">{lang.auth.firstName}    <span className="requiredColor"> * </span> </p> }
                        placeholder={lang.auth.firstNamePlaceholder}
                        value={this.state.first_name}
                        required ={true}

                        onChange={this.onChange}
                        errors={this.state.first_name_changed && (errors.first_name || errors.non_field_errors)}
                      />
                      </div>
                      <div className="form-group col-md-6">

                      <TextFieldGroup
                        inputCustomClassName={inputCustomClassName}

                        name="last_name"
                        IconLeft='far fa-user icon-left'
                        type="text"
                        label={<p className="requiredField">{lang.auth.lastName}   <span className="requiredColor"> * </span> </p> }
                        required ={true}

                        placeholder={lang.auth.lastNamePlaceholder}
                        value={this.state.last_name}
                        onChange={this.onChange}
                        errors={this.state.last_name_changed && (errors.last_name || errors.non_field_errors)}
                      />
                      </div>

                    <div className="form-group col-md-6">

                      <TextFieldGroup
                                  
                        inputCustomClassName={inputCustomClassName}
                        name="password"
                        IconLeft='fas fa-lock icon-left'
                        IconRight={ locale?'fa fa-eye icon-eye is-reverse_rtl': 'fa fa-eye icon-eye is-reverse'}
                        type="password"
                        toggleID='password'
                        label= {<p className="requiredField" >{lang.auth.Passwrd} <span className="requiredColor">  * </span> </p> } 
                        placeholder={lang.auth.PasswrdPlaceholder}
                        value={this.state.password}
                        required ={true}

                        onChange={this.onChange}
                        errors={this.state.password_changed  && (errors.password || errors.non_field_errors)}
                      />

                      </div>

                      <div className="form-group col-md-6">

                      <TextFieldGroup
                                  
                        inputCustomClassName={inputCustomClassName}
                        name="password2"
                        IconLeft='fas fa-lock icon-left'
                        IconRight={ locale?'fa fa-eye icon-eye is-reverse_rtl': 'fa fa-eye icon-eye is-reverse'}
                        type="password"
                        toggleID='password2'
                        label= {<p className="requiredField" >{lang.auth.confirmPasswrd} <span className="requiredColor">  * </span> </p> } 
                        required ={true}

                        placeholder={lang.auth.PasswrdPlaceholder}
                        value={this.state.password2}
                        onChange={this.onChange}
                        errors={this.state.password2_changed && (errors.password2 || errors.non_field_errors)}
                      />

                      </div>
                      <div className="form-group col-md-6">
                      <TextFieldGroup
                        inputCustomClassName={locale?" phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}


                        name="email"
                        IconLeft='far fa-user icon-left'
                        type="email"
                        label= {<p className="requiredField">  {lang.auth.email}   <span className="requiredColor"> * </span> </p> }
                        required ={true}

                        placeholder={lang.auth.emailPlaceholder}
                        value={this.state.email}
                        onChange={this.onChange}
                        errors={this.state.email_changed && (errors.email || errors.non_field_errors)}
                      />
                      </div>
                      <div className="form-group col-md-6">
                      <TextFieldGroup
                                  
                        inputCustomClassName={locale?" phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}

                        name="email2"
                        IconLeft='far fa-user icon-left'
                        type="email"
                        label={<p className="requiredField">{lang.auth.confirmEmail}  <span className="requiredColor"> * </span> </p> }
                        required ={true}

                        placeholder={lang.auth.emailPlaceholder}
                        value={this.state.email2}
                        onChange={this.onChange}
                        errors={this.state.email2_changed && (errors.email2 || errors.non_field_errors)}
                      />
                      </div>


                      <div className="form-group col-md-6">
                      <TextFieldGroup
                                  
                        inputCustomClassName={locale?" phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}
                        name="height"
                        IconLeft='fas fa-arrows-alt-v icon-left'
                        type="number"
                        label={lang.auth.height}
                        placeholder={lang.auth.heightPlaceholder}
                        value={this.state.height || ""}
                        onChange={this.onChange}
                        errors={this.state.height_changed && (errors.height || errors.non_field_errors)}
                      />
                      </div>
                      <div className="form-group col-md-6">
                      <TextFieldGroup
                                  
                        inputCustomClassName={locale?" phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}
                        name="weight"
                        IconLeft='fas fa-weight icon-left'
                        type="number"
                        label={lang.auth.weight}
                        placeholder={lang.auth.weightPlaceholder}
                        value={this.state.weight || ""}
                        onChange={this.onChange}
                        errors={this.state.weight_changed && (errors.weight || errors.non_field_errors)}
                      />
                      </div>

      

      <div className="form-group col-md-6">

      <div className="col-md-12 mb-3">
      <label className= " mx-2" htmlFor='blood_type'>{lang.auth.bloodType}</label>
      <div className="input-group mx-2" >
      <i className='fas fa-notes-medical icon-left'></i>  


          <select 
          id="blood_type"
          name="blood_type"
          className= {`custom-select ${inputCustomClassName} ${ this.state.blood_type_changed &&(errors.blood_type || errors.non_field_errors)  && "is-invalid"}`} 
            
          onChange={this.onChange}

          >
            <option value="">{lang.choose}</option>
            <option value="AP">A+</option>
            <option value="AN">A-</option>
            <option value="OP">O+</option>
            <option value="ON">O-</option>
            <option value="BP">B+</option>
            <option value="BN">B-</option>
            <option value="ABP">AB+</option>
            <option value="ABN">AB-</option>
          </select>
            {(this.state.blood_type_changed && errors.blood_type ) && errors.blood_type.map((error ,  index) => 
                  <div className="invalid-feedback" key={index}>
                  <ul style={{paddingLeft:"18px", marginTop:"0px", marginBottom:"0px"}}>
                    <li >
                      {error}
                    </li>
                  </ul>
                  </div>)
              }
            </div>
        </div>
      </div>



      <div className="form-group col-md-6">
      <div className="col-md-12 mb-3">
      <label className=" mx-2" htmlFor='level_of_physical_activity'>{lang.auth.LOPA}</label>
      <div className="input-group mx-2">
      <i className='fas fa-running icon-left'></i>  


          <select 
          id="level_of_physical_activity"
          name="level_of_physical_activity"
          className={`custom-select ${inputCustomClassName} ${ this.state.level_of_physical_activity_changed &&(errors.level_of_physical_activity || errors.non_field_errors)  && "is-invalid"}`} 
            
          onChange={this.onChange}

          >
            <option value="">{lang.choose}</option>
            <option value="N">{lang.auth.notActive}</option>
            <option value="A">{lang.auth.active}</option>
            <option value="G">{lang.auth.athletic}</option>

          </select>
            {(this.state.level_of_physical_activity_changed && errors.level_of_physical_activity ) && errors.level_of_physical_activity.map((error ,  index) => 
                  <div className="invalid-feedback" key={index}>
                  <ul style={{paddingLeft:"18px", marginTop:"0px", marginBottom:"0px"}}>
                    <li >
                      {error}
                    </li>
                  </ul>
                  </div>)
              }
            </div>
        </div>


      </div>
      </div>





        {/* ${ this.state.sex_changed &&(errors.sex || errors.non_field_errors)  && "is-invalid"} */}

        <div   className={`col-md-12 mb-3  ${ this.state.sex_changed &&(errors.sex || errors.non_field_errors)  && "is-invalid"}  `} >
      
            {/* <h6 className=" logoColor  mx-2 ">Gender :</h6> */}
          <label className=" mx-2" htmlFor='sex'>{lang.auth.gender}</label>


          <div className={` custom-control custom-radio custom-control-inline ${ this.state.sex_changed &&(errors.sex || errors.non_field_errors)  && "is-invalid"} `}>
          <input className="form-check-input custom-radio" type="radio" onChange={this.onChange} name='sex' id="exampleRadios1"  value='M'  />
              <label className="form-check-label" style={locale?{"marginRight":"26px"}:{"marginLeft":"10px"}} htmlFor="exampleRadios1">
              {lang.male}
              </label>
          </div>

          <div className={` custom-control custom-radio custom-control-inline ${ this.state.sex_changed &&(errors.sex || errors.non_field_errors)  && "is-invalid"} `}>
          <input className="form-check-input" type="radio" onChange={this.onChange} name='sex' id="exampleRadios2" value='F' />
              <label className="form-check-label" style={locale?{"marginRight":"26px"}:{"marginLeft":"10px"}} htmlFor="exampleRadios2">

              {lang.female}
              </label>
          </div>

          {(this.state.sex_changed && errors.sex ) && errors.sex.map((error ,  index) => 
                  <div className="invalid-feedback" key={index}>
                  <ul style={{paddingLeft:"18px", marginTop:"0px", marginBottom:"0px"}}>
                    <li >
                      {error}
                    </li>
                  </ul>
                  </div>)
              }




















      <br/>
      <br/>
            {/* <p className="text-center"> باتمامك لعملية التسجيل فانك توافق على جميع <strong>  الشروط و الاحكام </strong></p> */}
            <p className="text-center"> {lang.auth.agree} <strong className="underline" data-target="#termsAndConditions" data-toggle="modal">  {lang.auth.termsAndCoditions} </strong></p>

      </div>


                      <div className="modal fade" id="termsAndConditions" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog TermsModalMaxWidth" role="document">
                              <div className="modal-content">
                                  <div className="modal-header" style={{direction:"ltr",textAlign:"left"}}>
                                      {/* <h4 className="centerHeader logoColor  mb-0">Meal Detail</h4> */}
                                      <h3 className="centerHeader logoColor popUpHeader ">{lang.auth.termsAndCoditions}</h3>

                                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div className="modal-body">
                                      {locale ? 
                                        <div className="col-12 p-0"> <TermsAndConditionsAr/> </div> 
                                        :
                                        <div className="col-12 p-0"> <TermsAndConditionsEn/> </div> 


                                      }
                                  </div>
                                  <div className="modal-footer">
                                      <button  className="btn btn-outline-warning btn-lg btn-block custom-button-new-address" data-dismiss="modal" >{lang.auth.close} </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="modal fade" id="mealDetail" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                  <div className="modal-header" style={{direction:"ltr",textAlign:"left"}}>
                                      {/* <h4 className="centerHeader logoColor  mb-0">Meal Detail</h4> */}
                                      <h3 className="centerHeader logoColor popUpHeader ">{lang.auth.DOB}</h3>

                                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  <div className="modal-body">
                                      <div className="col-12 p-0"> <SelectBirthday/> </div> 
                                  </div>
                                  <div className="modal-footer">
                                      <button  className="btn btn-outline-warning btn-lg btn-block custom-button-new-address" data-dismiss="modal" ><i className="fas fa-plus "></i> {lang.Select} </button>
                                  </div>
                              </div>
                          </div>
                      </div>

                      

      
                      <div className="col-md-12 mb-3">
                        <button type="submit" className="btn btn-lg btn-outline-warning custom-detail-meal  btn-block  mt-4 mx-2" > {lang.auth.verify}</button>       
                      </div>

                    </form>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
            </div>
    );
  }
}



const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errorReducer.errors,
  date_of_birth: state.productReducer.date_of_birth,
  new_user: state.auth.new_user,
  locale: state.productReducer.locale,

});

// const mapDispatchToProps = dispatch => {
//   return {
//     signup: (email) => dispatch(signup(email)),
//     handleNextRegistrationStep: (step) => dispatch(handleNextRegistrationStep(step))
//   };
// };

export default connect(
  mapStateToProps,
  {  resetErrors,signUpNoOTP }
)(Signup);
