import React, { Component } from 'react'
import { connect } from "react-redux";

import { handleAutocompleteLoad,handleSeletedDay, getFullAddress,handleLoadingMealsStatus,change_selected_date_to_true,handleMapLoad ,handleNextStep,handleSeletedStartDate,fetchMeals,handlePreviousStep } from "../../store/actions/productAction";
import { GoogleMap, LoadScript  ,Autocomplete } from '@react-google-maps/api'
import Geocode from "react-geocode";
import currentLocation from "../../assets/images/current-location.svg"
import searchIcon from "../../assets/images/search-icon.svg"

import  {notify} from '../../components/Notifications'

import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

Geocode.setApiKey(`${process.env.REACT_APP_GM_IP_KEY}`);
Geocode.enableDebug();
Geocode.setLanguage('en')
Geocode.setRegion('US')
class Map extends Component {


    
    
    state = {
        map: {},
        address: '',
        city: '',
        area: '',
        state: '',
        loading:true,
        libraries:['places'],
        language:'en'
        // lat: 27.0036247,
        // lng: 49.6562978
    }





    async componentDidMount() {
            await this.getLocation()
	};



    showError=(error) =>{
        let locale = this.props.locale
        let lang = locale ? ar : en


        switch(error.code) {
          case error.PERMISSION_DENIED:
            notify(  lang.errors.geoDenied , 'danger')
            break;
          case error.POSITION_UNAVAILABLE:
            notify(  lang.errors.Locationinfo, 'danger')
            break;
          case error.TIMEOUT:
            notify(  lang.errors.locationTimeOut, 'danger')
            break;
          case error.UNKNOWN_ERROR:
            notify(  lang.errors.unknowError, 'danger')
            break;
            default: return notify(  lang.errors.unknowError, 'danger')
        }
      }
    

    showPosition=async (position) =>{
        let lat = parseFloat(position.coords.latitude.toFixed(6))
        let lng = parseFloat(position.coords.longitude.toFixed(6))
        await this.setState({lat: lat , lng: lng, loading:false})
        
        
		Geocode.fromLatLng( this.state.lat , this.state.lng ).then(
            response => {
                const address = response.results[1].formatted_address,
                addressArray =  response.results[1].address_components,
                city = this.getCity( addressArray ),
                postal_code = this.getPostalCode(addressArray),
                county = this.getCounty( addressArray ),
                area = this.getArea( addressArray ),
                state = this.getState( addressArray ),
                country = this.getCountry( addressArray );
                
				this.setState( {
					address: ( address ) ? address : '',
					area: ( area ) ? area : '',
					city: ( city ) ? city : '',
                    state: ( state ) ? state : '',
                    country:country,
                    postal_code:postal_code,
                    county:county,

                    mapUrl:`https://www.google.com/maps/search/?api=1&query=${this.state.lat},${this.state.lng}`

                } )
                let mapUrl = this.state.mapUrl
                this.props.getFullAddress(( address ) ? address : '', ( area ) ? area : '',( city ) ? city : '',( state ) ? state : '', this.state.lat, this.state.lng, mapUrl,country ,postal_code,county)
                    
                        },
			error => {
				console.error( error );
			}
		);


    }

    getLocation=()=> {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          };
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition, this.showError, options);


        } else { 
            notify("Geolocation is not supported by this browser." , 'danger')
        }
      }








continue = e => {
  e.preventDefault();
  this.props.handleNextStep(3)
}


back = e => {
    e.preventDefault();
    this.props.handlePreviousStep(2);
}







    getCountry = ( addressArray ) => {
        let country = '';
        if (addressArray){

            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && "country" === addressArray[ i ].types[0] ) {
                    country = addressArray[ i ].long_name;

                    return country;
                }
            } return ''
        }  
    };


    getCounty = ( addressArray ) => {
        let county = '';
        if (addressArray){

            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && "administrative_area_level_2" === addressArray[ i ].types[0] ) {
                    county = addressArray[ i ].long_name;

                    return county;
                }
            } return ''
        }  
    };






    getPostalCode = ( addressArray ) => {
        let postalCode = '';
        if (addressArray){

            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && "postal_code" === addressArray[ i ].types[0] ) {
                    postalCode = addressArray[ i ].long_name;

                    return postalCode;
                }
            } return ''
        }  
    };


    getCity = ( addressArray ) => {
        let city = '';
        if (addressArray){

            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'locality' === addressArray[ i ].types[0] ) {
                    city = addressArray[ i ].long_name;
                    // cleanCity = city.startsWith('Al') ? city.slice(3) : city

                    return city;
                }
            } return ''
        }  
    };


    getArea = ( addressArray ) => {
        let area = '';
        if (addressArray){

            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0]  ) {
                    for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
                        if ( 'sublocality_level_1' === addressArray[ i ].types[j] || 'locality' === addressArray[ i ].types[j] ) {
                            area = addressArray[ i ].long_name;
                            return area;
                        }
                    }
                }
            }
        }return ''
    };

    getState = ( addressArray ) => {
        if (addressArray){

            let state = '';
            for( let i = 0; i < addressArray.length; i++ ) {
                for( let i = 0; i < addressArray.length; i++ ) {
                    if ( addressArray[ i ].types[0] && 'administrative_area_level_1' === addressArray[ i ].types[0] ) {
                        state = addressArray[ i ].long_name;
                        return state;
                    }
                }
            }
        }return ''
    };


    handleAutocompleteOnLoad = (autocomplete) => {
        this.autocomplete = autocomplete
      }  
    
      
    handleOnAutocompleteChanged = () => {

    // if (this.props.autocomplete !== null) {
    if (this.autocomplete !== null) {
        const place = this.autocomplete.getPlace()
        if (place.geometry=== undefined){return 'location not found' }               
            const address = place.formatted_address
            const addressArray =  place.address_components
            const city = this.getCity( addressArray )
            const postal_code = this.getPostalCode(addressArray)
            const county = this.getCounty( addressArray );
            const area = this.getArea( addressArray )
            const state = this.getState( addressArray )
            const country = this.getCountry( addressArray );
            const latValue = place.geometry.location.lat()
            const lngValue = place.geometry.location.lng();

            this.setState({
                address: ( address ) ? address : '',
                area: ( area ) ? area : '',
                city: ( city ) ? city : '',
                state: ( state ) ? state : '',
                lat: latValue,
                lng: lngValue,
                mapUrl:`https://www.google.com/maps/search/?api=1&query=${latValue},${lngValue}`,
                country:country,
                postal_code:postal_code,
                county:county,
            } )
            
            let mapUrl = this.state.mapUrl
            this.props.getFullAddress(( address ) ? address : '', ( area ) ? area : '',( city ) ? city : '',( state ) ? state : '', latValue, lngValue,mapUrl ,country,postal_code,county )
            
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  
  
  handleMapOnDrag = () => {
    const map = this.LoadMap

    Geocode.fromLatLng( map.center.lat() , map.center.lng() )
    .then(
        response => {
            const address = response.results[1].formatted_address,
            addressArray =  response.results[1].address_components,
            city = this.getCity( addressArray ),
            postal_code = this.getPostalCode(addressArray),
            county = this.getCounty( addressArray ),

            area = this.getArea( addressArray ),
            state = this.getState( addressArray ),
            country = this.getCountry( addressArray ),
            lat = parseFloat(map.center.lat().toFixed(6)),
            lng = parseFloat(map.center.lng().toFixed(6));
            
            this.setState( {
                address: ( address ) ? address : '',
                      area: ( area ) ? area : '',
                      city: ( city ) ? city : '',
                      state: ( state ) ? state : '',
                      lat: map.center.lat(),
                      lng: map.center.lng(),
                      country:country,
                      postal_code:postal_code,
                      county:county,
                      mapUrl:`https://www.google.com/maps/search/?api=1&query=${map.center.lat()},${map.center.lng()}`

                    } )
                    let mapUrl = this.state.mapUrl
                    this.props.getFullAddress(( address ) ? address : '', ( area ) ? area : '',( city ) ? city : '',( state ) ? state : '', lat, lng, mapUrl,country,postal_code,county )
                        
                
			},
			error => {
				console.error(error);
			}
		);
	};


    
  
handleMapLoad = async (map) => {
    this.LoadMap = map
	};
  






  render() {



    const options = {
        streetViewControl: false, zoomControl:false, mapTypeControl:false, fullscreenControl: false
      }

    let key = `${process.env.REACT_APP_GM_HTTP_KEY}`


        return(


         <div className="container ">
                <div className="row justify-content-center  mb-4" >

        <LoadScript googleMapsApiKey={key} libraries={this.state.libraries} language={this.state.language}>
            <GoogleMap
                
                id="map"
                mapContainerClassName="mymap"
                center={{ lat: this.state.lat, lng: this.state.lng }}
                zoom={17}
                onLoad={this.handleMapLoad}
                
                onDragEnd={this.handleMapOnDrag}
                options={options}
                mapTypeId='ROADMAP'
                >



                <Autocomplete
                        onLoad={this.handleAutocompleteOnLoad}
                        onPlaceChanged={this.handleOnAutocompleteChanged}
                        options={{componentRestrictions: {country: "SA",}}}
                    >

                    <div className="">

                            <div className="searchBox">
                                <img className=" search-icon" 
                                src={searchIcon} alt="magnifying-glass-gray" 
                                size="18"/>
                            </div>

                            <div className="autocompleteBox">
                                <input
                                type="text"
                                placeholder="Customized your placeholder"
                                className="autocompleteInput"
                                />
                            </div>
                    </div>
                </Autocomplete>

                <div id="current-location">
                <button onClick={()=>this.getLocation()} className="location-button">
                 <img className="location-icon" src={currentLocation} alt="gps" />

                 </button>
                </div>
            </GoogleMap>
        </LoadScript>

        </div>
      </div>
    )
    }
}

// }


const mapStateToProps = state => {
    return {
        subscriptions: state.productReducer.subscriptions,
        selectedStartDay: state.productReducer.selectedStartDay,
        selectedSub: state.productReducer.selectedSub,
        day: state.productReducer.day,
        loading: state.productReducer.loading,
        isSelectedSubChanged: state.productReducer.isSelectedSubChanged,
        
        map:state.productReducer.map,
        address:state.productReducer.address,
        area:state.productReducer.area,
        city:state.productReducer.city,
        state:state.productReducer.state,
        lat:state.productReducer.lat,
        lng:state.productReducer.lng,
        mapUrl:state.productReducer.mapUrl,
        localLocation:state.productReducer.localLocation,
        autocomplete:state.productReducer.autocomplete,
        locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleSeletedStartDate: (date) => dispatch(handleSeletedStartDate(date)),
        handleSeletedDay: (day) => dispatch(handleSeletedDay(day)),
        fetchMeals: (date,id) => dispatch(fetchMeals(date,id)),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        handleLoadingMealsStatus: () => dispatch(handleLoadingMealsStatus()),
        change_selected_date_to_true: () => dispatch(change_selected_date_to_true()),
        handleMapLoad: (map) => dispatch(handleMapLoad(map)),
        handleAutocompleteLoad: (autocomplete) => dispatch(handleAutocompleteLoad(autocomplete)),
        getFullAddress: (address,area,city,state,lat,lng,mapUrl,country,postal_code,county) => dispatch(getFullAddress(address,area,city,state,lat,lng,mapUrl,country,postal_code,county)),

        

    };
  };
  


  export default connect(mapStateToProps, mapDispatchToProps)(Map);
  