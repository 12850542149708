import jwt_decode from "jwt-decode";

// Utils
import setAuthToken from "./setAuthToken";

// Actions
import { setCurrentUser, logoutUser,refreshToken } from "../store/actions/authActions";
// import store from "../store";

// let intervalId = 0
const token = localStorage.token;

const checkForExpiredToken = store => {
  // Check for token expiration
  // const token = localStorage.token;
//   const user = localStorage.eilaUser;
  if (token) {

      const currentTime = Date.now() / 1000;

      // Decode token and get user info
      const decoded = jwt_decode(token);

      // Check token expiration
      if (decoded.exp >= currentTime) {
      // if (1 != 1) {
        
        // Set auth token header
        setAuthToken(token);
        // Set user and isAuthenticated
        store.dispatch(setCurrentUser(decoded));
        // refresh token every 12 min
        store.dispatch(refreshToken())
      } else {
        store.dispatch(logoutUser());
        
        // Redirect to login
        // window.location.href = "/login";
      }
    }
  };

// const checkForExpiredTokenAgain = store => {
//   // const token = localStorage.token;
//   if (token) {

//       const currentTime = Date.now() / 1000;
//       const decoded = jwt_decode(token);
//       if (decoded.exp < currentTime) {
//         store.dispatch(logoutUser());
//       }
// };
// }


// if (token) {
// intervalId = setInterval(() => {
//   checkForExpiredTokenAgain(store)
// }, 120000);
// }else if (intervalId){
//   clearInterval(intervalId);
// }

export default checkForExpiredToken;