import React from "react";
import { connect } from "react-redux";

import { handleNextStep,fetchMeals,handlePreviousStep ,change_selected_sub_to_false,handleLoadingMealsStatus,change_selected_date_to_false} from "../../store/actions/productAction";
import Loading from "../Loading";
import DatesList from "./DatesList";
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class Meals extends React.Component {
  state = {
    title:''
  };

  async componentDidMount() {
    const selectedStartDay = this.props.selectedStartDay
    const selectedSub = this.props.selectedSub

     if  (  this.props.isSelectedSubChanged || this.props.isSelectedDateChanged){
      await this.props.handleLoadingMealsStatus()
      await this.props.fetchMeals(selectedStartDay,selectedSub.id);
      this.props.isSelectedSubChanged && this.props.change_selected_sub_to_false()
      this.props.isSelectedDateChanged && this.props.change_selected_date_to_false()
    } 
  }






  continue = e => {
    e.preventDefault();
    this.props.handleNextStep(6)
  }
  

back = e => {
  e.preventDefault();
  this.props.handlePreviousStep(3);
  
}

getSeletedMealsTitle=(title)=>{

  this.setState({title:title})
}

  render() {
    let locale = this.props.locale
    let lang = locale ? ar : en

    if (this.props.loading) {
      return <Loading />
    } else {
    
      const datesList = this.props.meals.length > 0 &&this.props.meals.map((date, index) => (
      <DatesList key={date.id} index={index} dates={date} getSeletedMealsTitle={this.getSeletedMealsTitle} title={this.state.title}  />
    ));

      return (
        <div className="container " >
              <h3 className="centerHeader logoColor yourPlane mb-4">{lang.Recomended_Meals}</h3>
                  <div className="row justify-content-md-center mr-0 ml-0"  >
                      {datesList}
                  </div>

              <div className="row justify-content-center recomendedMeal">

                  <button  
                      onClick={this.back} 
                      className="btn btn-sm btn-outline-warning custom-detail-meal     mr-3 col-md-4  col-sm-4  col-5"
                      style={{marginTop:'.5rem'} }> <strong className="ButtonText"> <i className="far fa-calendar-alt mr-1"></i> {lang.Back}</strong>   
                  </button>

                  <button  
                      onClick={this.continue} 
                      className="btn btn-sm btn-outline-warning custom-detail-meal      col-md-4  col-sm-4  col-5 "
                      style={{marginTop:'.5rem'} }>
                      <strong className="ButtonText ">   {lang.Review} <i className="fas fa-shopping-cart ml-2"></i> </strong>   
                  </button>
              </div>
              <br/>
              <br/>

        </div>
      )
    }
  }
}


const mapStateToProps = state => {
    return {
        subscriptions: state.productReducer.subscriptions,
        meals: state.productReducer.meals,
        selectedSub: state.productReducer.selectedSub,
        selectedStartDay: state.productReducer.selectedStartDay,
        loading: state.productReducer.loadingMeals,
        isSelectedSubChanged: state.productReducer.isSelectedSubChanged,
        isSelectedDateChanged: state.productReducer.isSelectedDateChanged,
        productReducer: state.productReducer,
        locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        fetchMeals: (date,id) => dispatch(fetchMeals(date,id)),
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        change_selected_sub_to_false: () => dispatch(change_selected_sub_to_false()),
        handleLoadingMealsStatus: () => dispatch(handleLoadingMealsStatus()),
        change_selected_date_to_false: () => dispatch(change_selected_date_to_false()),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Meals);
  