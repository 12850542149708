import React, { Component } from "react";
import {withRouter,Link} from "react-router-dom"
import { connect } from "react-redux";
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class OrderList extends Component {
  state = {
  };

  continue = () => {

  }
    render() {

      
      let locale = this.props.locale
      let lang = locale ? ar : en

      let order = this.props.order
      let subscription = order.subscription

      return (





<div key={order.id} className="card  text-center col-lg-10  col-md-10 mb-3 pl-0 pr-0" style={{width:"100%", maxWidth:"", borderRadius:"30px 0px 60px 0px"}}>

  <div className="ordersHeader  "  style={{width:"100%", maxWidth:"", borderRadius:"30px 0px 0px"}}>

        {/* <h6 className="mb-0 date"> {order.is_active ? <i className="fas fa-bell "> <strong className="ml-2"></strong> </i>  : <i className="far fa-bell-slash"><strong className="ml-2"></strong>  </i>}  </h6> */}


        <h6 className="mb-0 "> <strong>{order.start_date} - {order.end_date} </strong> </h6>
                
        <h6 className="mb-0 rightSide"> { <strong className="ml-2"># {order.id}</strong> }  </h6>
                {/* <Link
                title="order detail" 
                className={`fas fa-edit editIcond  ${order.is_active && "EditIConnRight" }   `}
                to={`/orders/${order.id}/`}
                >
                </Link> */}
</div>
<div className="card-body ordersBody" style={{width:"100%", maxWidth:"", borderRadius:"0px 0px 60px"}} >

    <h3 className=" ">{locale ? subscription.name_ar: subscription.name}</h3>
    <h6 className="  ">{locale ? subscription.period_name_ar : subscription.period_name}, {locale ? subscription.displayed_number_ar:subscription.displayed_number}, {subscription.number_of_meals} { subscription.number_of_meals > 1 ? lang.noms : lang.nom}</h6>

    <Link  
        to={`/orders/${order.id}/`}
        title="order detail" 
        className="btn btn-sm btn-outline-warning custom-detail-meal      col-md-4  col-8 "      
        style={{marginTop:'.5rem'} }>
          

        <strong className="ButtonText ">   {lang.orderDetail} <i className="fas fa-edit ml-2"></i> </strong>   
    </Link>

</div>
</div>




      );
    }
  }
  
  
  const mapStateToProps = state => {
    return {

      locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderList));
  