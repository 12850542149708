import React, { Component } from "react";
import {withRouter,} from "react-router-dom"
import { connect } from "react-redux";

class Meals extends Component {
    state = {
    }




    render() {
        let meals = this.props.myMeal



        return (




            <li className="list-inline-item ">{meals} - </li>


        )
    }
}

const mapStateToProps = state => {
    return {



    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Meals));
  