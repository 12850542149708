import React, { Component } from "react";
import {withRouter,} from "react-router-dom"
import { connect } from "react-redux";
import { handleNextStep ,handleSelectedMealsDetail} from "../../store/actions/productAction";
import nf from '../../assets/images/nf.png'
import SelectedMealDetails from './SelectedMealDetails'
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class OneDayMeals extends Component {
    state = {
    }

    checkSelectedMeal = (id)=>{

        this.props.checkSelectedMeal(id)
    }

    componentDidMount(){
        
        let meal = this.props.meal
        let selected = `chosen_${this.props.day.date}_${meal.id}`

        if(this.props.productReducer[selected] === undefined && meal.isDefault){
            this.props.checkSelectedMeal(meal.id)

        }  
    }

    continue = (e) => {
        e.preventDefault();
        this.props.handleSelectedMealsDetail(this.props.meal)
      }

    render() {
        let meal = this.props.meal
        let selected = `chosen_${this.props.day.date}_${meal.id}`
        let total_selectedd = `total_selectedd_for_${this.props.day.date}`

        var className =  this.props.productReducer[selected] ?  "btn btn-outline-danger btn-sm" :"btn btn-outline-success btn-sm" 
        var NotselectedItemCard = 'row no-gutters shadowCard NotselectedItemCard'
        var CheckIfSelected =  this.props.productReducer[selected] ?NotselectedItemCard:NotselectedItemCard
        let locale = this.props.locale
        let lang = locale ? ar : en
        return (

            <div className=" p-0 col-lg-5 col-md-5 col-11 mr-auto ml-auto mb-5" 
              >

                <div className={CheckIfSelected}>
                
                    <div className="col m-auto ">
                        <div className="card-body p-0 ">
                            <img src={meal.image ? meal.image : nf}  alt="..."  style={{width:"133px", height:"133px"}} />
                        </div>
                    </div>

                    <div className="col-xl-7 col-lg-6 col-md-6 col-sm-8 col-6">
                        <div className="card-body pt-2 pb-2 pl-2">
                           
                            <h6 className="card-title mb-1">{locale ? meal.name_ar : meal.name}</h6>
                            <p className="card-text mb-2" style={{fontSize:'small'}} >{locale ? meal.description_ar: meal.description}</p>
                           
                           
                            <button 
                            disabled= {this.props.productReducer[total_selectedd] === this.props.selectedSub.number_of_meals && !this.props.productReducer[selected] }
                            onClick={ ()=> this.props.checkSelectedMeal(meal.id)}  
                            className={className}> 
                            {this.props.productReducer[selected] ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i> }</button>
                            
                            <button onClick={this.continue}   className="btn btn-outline-warning btn-sm ml-2  custom-detail-meal"  data-toggle="modal" data-target="#mealDetail" type="button" >  {lang.meal_detail} </button>



                            <div className="modal  fade" id="mealDetail" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog custom_meals" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="centerHeader logoColor  mb-0"> {lang.meal_detail} </h4>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="col-12 p-0"> <SelectedMealDetails/> </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>


        )
    }
}

const mapStateToProps = state => {
    return {
      selectedSub: state.productReducer.selectedSub,
      number_of_selected: state.productReducer.number_of_selected,
      step: state.productReducer.step,
      productReducer: state.productReducer,
      day: state.productReducer.ondDayMeals,
      locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleSelectedMealsDetail: (detail) => dispatch(handleSelectedMealsDetail(detail)),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OneDayMeals));
  