




import React, { Component } from "react";
import { connect } from "react-redux";

import { handleSelectedMealsDetail } from "../../store/actions/productAction";
import notFound from '../../assets/images/nf.png'
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class MultiCarouselItems extends Component {

        continue = () => {
    
            this.props.handleSelectedMealsDetail(this.props.meal)
          }


  render() {
    let locale = this.props.locale
    let lang = locale ? ar : en

    let meal = this.props.meal

      return (      

        <div className="single_recepie text-center">
            <div className="recepie_thumb">
                <img src={meal.image ? meal.image : notFound} alt=""/>
            </div>
            <h3>{locale ? meal.name_ar : meal.name}</h3>
            <p>{locale ?meal.description_ar : meal.description }</p>
            <button onClick={this.continue}   className="line_btn"  data-toggle="modal" data-target="#mealDetailHome" type="button" >{lang.meal_detail}</button>
        </div>

      )

    
    }
  }

const mapStateToProps = state => {
  return {
    locale: state.productReducer.locale,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSelectedMealsDetail: (detail) => dispatch(handleSelectedMealsDetail(detail)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiCarouselItems);
