import React, { Component } from "react";
import { connect } from "react-redux";
import {} from '../../../store/actions/authActions'

// Actions
import { passwordReset,handleResetPasswordSteps,passwordValidate } from "../../../store/actions/authActions";
import { resetErrors,ToggleLoading } from "../../../store/actions/errorActions";

// Components
import TextFieldGroup from "../../common/TextFieldGroup";

import Loading from "../../Loading";


import ar from '../../../assets/locales/ar.json'
import en from '../../../assets/locales/en.json'


class ResetPasswordStepThree extends Component {
    state = {
        pin: "",
        timer:180,
        isDisabled:false,
      }
      componentDidMount(){
        var intervalId = setInterval(this.timer, 1000);
        this.setState({ intervalId: intervalId, isDisabled:true ,pin_changed:true});
      }
      

onClick =()=>{

    var intervalId = setInterval(this.timer, 1000);
    // store intervalId in the state so it can be accessed later:
    this.setState({intervalId: intervalId, isDisabled:true ,pin_changed:true,
    });
    this.props.passwordValidate({
      phone_number:this.props.auth.reset_phone_number,
      password: this.props.auth.reset_password,
      password2:this.props.auth.reset_password
  }, 3);

}


  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    
    let errorChanged = `${e.target.name}_changed`
    this.setState({[errorChanged]:false})


  }

  onSubmit = e =>{
    e.preventDefault();

    let resetPassword = {
        phone_number:this.props.auth.reset_phone_number,
        password:this.props.auth.reset_password,
        pin:this.state.pin
    }
    
    this.props.ToggleLoading()
    this.props.passwordReset(resetPassword,1);
    
    this.setState({
      pin_changed:true,
      
    })
  }
  

  componentWillUnmount() {
    clearInterval(this.state.intervalId);

    }

  timer = ()=> {

    var newCount = this.state.timer - 1;
    if(newCount >= 0) { 
        this.setState({ timer: newCount });
    } else {
        clearInterval(this.state.intervalId);
        this.setState({isDisabled:false});    
      }
 }
  
  
  render() {
    const { errors, } = this.props;
    let timerMMSS =  new Date(this.state.timer * 1000).toISOString().substr(14, 5)
    let locale = this.props.locale
    let lang = locale ? ar : en

    let label= this.state.isDisabled ? 
    
    <strong className="requiredField" >{lang.auth.OTPSent}  <span className="requiredColor phoneNumberField" > {this.props.auth.reset_phone_number} </span> . </strong> 
    : 
    <strong className="requiredField" >{lang.auth.OTPSend}  <span className="requiredColor phoneNumberField" > {this.props.auth.reset_phone_number} </span> {lang.question} </strong>


    if (this.props.loadingError ) {
      return <Loading />
      }
      else {
    
    return (
      <div className={locale? 'rtl login': "ltr login"}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">{lang.auth.verification}</h1>
              <br/>
              <form className="needs-validation"  onSubmit={this.onSubmit} >
                <TextFieldGroup
                  inputCustomClassName={locale?"textFieldGroup_rtl noBorderColer":"textFieldGroup_ltr noBorderColer"}
                  name="pin"
                  isDisabledButton={this.state.isDisabled}
                  IconLeft={locale? ' fas fa-sms icon-left-with-button_rtl': ' fas fa-sms icon-left-with-button'} 
                  ButtonLeftText={this.state.isDisabled ? timerMMSS :lang.auth.clickToSentOTP}
                  ButtonLeftClassName=' verifyButton btn btn-dark'
                  ButtonLeft={this.onClick}
                  type="number"
                  label= {label } 
                  placeholder={lang.auth.pinPlaceholder }
                  IconRightTimer={'Timer icon-eye is-reverse'}
                  // IconRightText={timerMMSS}
                  value={this.state.pin || ""}
                  required={true}
                  onChange={this.onChange}
                  errors={this.state.pin_changed && (errors.response || errors.non_field_errors || errors[0])}
                />

                <div className="col-md-12 mb-3">
                        <button  
                            disabled={this.state.pin === ""} 
                            title={this.state.pin === "" ? "Please enter the code" :  "Register"}
                            type="submit"
                            className="btn btn-sm btn-outline-warning custom-detail-meal  btn-block  mt-4 mx-2"
                            style={this.state.pin === "" ? {filter:"blur(0.7px)opacity(0.5)" , marginTop:'.5rem'} :{ marginTop:'.5rem'}}>
                            <strong className="ButtonText ">  {lang.auth.reset} <i className="fas fa-user-plus ml-2"></i> </strong>   
                        </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errorReducer.errors,
  loadingError: state.errorReducer.loading,
  date_of_birth: state.productReducer.date_of_birth,
  new_user: state.auth.new_user,
  locale: state.productReducer.locale,

});



export default connect(
  mapStateToProps,
  { passwordReset, resetErrors,handleResetPasswordSteps,ToggleLoading ,passwordValidate}
)(ResetPasswordStepThree);
