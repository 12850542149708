import {TOGGLE_LOADING, GET_ERRORS } from "../actions/types";

const initialState = {
  errors:[],
  loading:false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errors:action.payload,
        loading:false

      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading:true
      };
      
        default:
          return state;
  }
}