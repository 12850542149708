// Error Types
export const GET_ERRORS = "GET_ERRORS";

// Auth Types
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// Products Types
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";

export const GET_SELECTED_SUB = "GET_SELECTED_SUB";

export const GET_ORDERS = "GET_ORDERS";
export const USER_PROFILE = "USER_PROFILE";
export const GET_SELECTED_START_DATE = "GET_SELECTED_START_DATE";
export const GET_SELECTED_DAY = "GET_SELECTED_DAY";
export const GET_MEALS = "GET_MEALS";
export const GET_NEXT_STEP = "GET_NEXT_STEP";
export const GET_PREVIOUS_STEP = "GET_PREVIOUS_STEP";
export const GET_MEALS_LIST_FOR_SELECTED_DAY = "GET_MEALS_LIST_FOR_SELECTED_DAY";
export const CHANGE_LOADING_MEALS_STATUS = "CHANGE_LOADING_MEALS_STATUS";
export const CHANGE_SLEECTED_SUB_TO_TRUE = "CHANGE_SLEECTED_SUB_TO_TRUE";
export const CHANGE_SLEECTED_SUB_TO_FALSE = "CHANGE_SLEECTED_SUB_TO_FALSE";
export const GET_SUB_TOTAL_DAYS = "GET_SUB_TOTAL_DAYS";
export const CHANGE_SLEECTED_DATE_TO_TRUE = "CHANGE_SLEECTED_DATE_TO_TRUE";
export const CHANGE_SLEECTED_DATE_TO_FALSE = "CHANGE_SLEECTED_DATE_TO_FALSE";
export const CHANGE_ONE_DAY_SELECTED_MEAL_TO_FALSE = "CHANGE_ONE_DAY_SELECTED_MEAL_TO_FALSE";
export const CHANGE_ONE_DAY_SELECTED_MEAL_TO_TRUE = "CHANGE_ONE_DAY_SELECTED_MEAL_TO_TRUE";
export const INCREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY = "INCREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY";
export const DECREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY = "DECREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY";
export const GET_SELECTED_MEAL_DETAIL = "GET_SELECTED_MEAL_DETAIL";
export const GET_SELECTED_DELIVERY_OPTION = "GET_SELECTED_DELIVERY_OPTION";
export const GET_COUPON = "GET_COUPON";
export const GET_MAP = "GET_MAP";
export const GET_ADDRESS = "GET_ADDRESS";
export const GET_USER_ADDRESSES = "GET_USER_ADDRESSES";
export const POST_ADDRESS = "POST_ADDRESS";
export const GET_SELECTED_ADDRESS_TYPE = "GET_SELECTED_ADDRESS_TYPE";
export const GET_AUTOCOMPLETE = "GET_AUTOCOMPLETE";
export const GET_SUPPORTED_PLACES = "GET_SUPPORTED_PLACES";
export const POST_PAYMENT = "POST_PAYMENT";
export const GET_SELECTED_BIRTH_DATE = "GET_SELECTED_BIRTH_DATE";
export const NEW_USER = "NEW_USER";
export const GET_NEXT_REGISTRATION_STEP = "GET_NEXT_REGISTRATION_STEP";
export const GET_PREVIOUS_REGISTRATION_STEP = "GET_PREVIOUS_REGISTRATION_STEP";
export const UPDATE_ORDER_MEALS = "UPDATE_ORDER_MEALS";
export const GET_ALL_MEALS = "GET_ALL_MEALS";
export const SEND_EMAIL = "SEND_EMAIL";
export const RESET_STORE = "RESET_STORE";
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";
export const GET_NEXT_RESETPASSWORD_STEP = "GET_NEXT_RESETPASSWORD_STEP";
export const RESET_PHONE_NUMBER = "RESET_PHONE_NUMBER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_KICHEN_MEALS = "GET_KICHEN_MEALS";
export const GET_LOGISTICS_ORDER_MEALS = "GET_LOGISTICS_ORDER_MEALS";
export const CHANGE_LOCAL = "CHANGE_LOCAL";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const Loading = "Loading";
export const GET_END_DAY = "GET_END_DAY";
export const GET_IS_MAX_ORDERS = "GET_IS_MAX_ORDERS";

