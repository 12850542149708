
import React, { Component } from 'react';

import OTP from './OTP';
import Signup from './Signup';


import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import { Redirect } from 'react-router';
import ReactGA from 'react-ga';
import { handleNextRegistrationStep} from "../../../store/actions/authActions";



export class RegistrationSteps extends Component {


    componentWillUnmount(){
        this.props.handleNextRegistrationStep(1)
    }

    showStep = () => {

        if(this.props.auth.registration_step === 1){
            ReactGA.pageview(`Signup`);
            return (<Signup 
            />);

        }


        if(this.props.auth.registration_step === 2){
            ReactGA.pageview(`Signup/OTP`);

            return (<OTP 
            />);
        }


    }

    render(){
        const { isAuthenticated } = this.props.auth;

        return isAuthenticated ? <Redirect to="/subscribe" /> :
            <>
                {this.showStep()}
            </>
    }
}


  
const mapStateToProps = state => {
    return {
      auth: state.auth

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        handleNextRegistrationStep: (step) => dispatch(handleNextRegistrationStep(step))
    };
  };
  export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RegistrationSteps));
  