import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchOrders } from "./store/actions/orderActions";
import { fetchSubscriptions,fetchAllMeals ,fetchSupportedPlaces} from "./store/actions/productAction";

import axios from "axios";



// Components
import Orders from "./components/Orders";
import OrderDetail from "./components/Orders/OrderDetail";
import OrderMealDetail from "./components/Orders/OrderMealDetail";

import Subscribe from "./components/Subscribe";
import RegistrationSteps from "./components/auth/RegistrationSteps";
import ResetPasswordSteps from "./components/auth/ResetPasswordSteps"
import Kitcken from "./components/Kitcken";
import Logistic from "./components/Logistic";
import Home from "./components/Home";
import PaymentResponses from "./components/PaymentResponses";
import Successful from "./components/PaymentResponses/Successful";
import Navbar from "./components/layout/Navbar";
import Notifications from "./components/Notifications";
import NotFound from "./components/common/NotFound";
import Login from "./components/auth/Login";
import userProfile from "./components/auth/userProfile";
import ChangePassword from "./components/auth/userProfile/ChangePassword";
import Footer from "./components/layout/Footer";

// Stylesheets
import "./App.css";
import "./assets/css/myStyles.css";
import "./assets/css/dayPicker.css";
import "./assets/css/homeStyles.css";
import "./assets/css/navStyles.css";
import "./assets/css/table.css";



class App extends Component {


  async componentDidMount() {

    axios.defaults.headers.common['Accept-Language'] = "ar"

    
    if ( Object.keys(this.props.subscriptions).length === 0){
      this.props.fetchSubscriptions()
    } 
    
    if ( Object.keys(this.props.all_meals).length === 0){
      this.props.fetchAllMeals()
    } 
    if ( Object.keys(this.props.supported_places).length === 0){
      await this.props.fetchSupportedPlaces()
    } 
  }


  render() {
   
    return (
      <div className="App">
        <div className="container-fluid ContainerPadding">
        <Notifications />
        <Navbar />
          <Switch >
            <Route exact path="/" component={Home} />
            <Route path="/subscribe" component={Subscribe} />
            <Route path="/kitchen" component={Kitcken} />
            <Route path="/logistic" component={Logistic} />
            <Route path="/orders/:orderID/:dateID" component={OrderMealDetail} />
            <Route path="/orders/:orderID" component={OrderDetail} />
            <Route path="/orders" component={Orders} />
            <Route path="/checkout/" component={PaymentResponses} />
            <Route path="/successful" component={Successful} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/profile" component={userProfile} />
            <Route exact path="/profile/password" component={ChangePassword} />
            <Route exact path="/reset/password" component={ResetPasswordSteps} />
            <Route exact path="/signup" component={RegistrationSteps} />
            <Route component={NotFound} />
            {/* <Redirect to="/" /> */}
          </Switch>
        <Footer />
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => {
  return {
      loading: state.productReducer.loading,
      auth:state.auth,
      subscriptions: state.productReducer.subscriptions,
      all_meals:state.productReducer.all_meals,
      loadingAllMeals:state.productReducer.loadingAllMeals,
      supported_places:state.productReducer.supported_places,


  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrders: () => dispatch(fetchOrders()),
    fetchSubscriptions: () => dispatch(fetchSubscriptions()),
    fetchAllMeals: () => dispatch(fetchAllMeals()),
    fetchSupportedPlaces: () => dispatch(fetchSupportedPlaces()),


  };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(App));
