import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import styled from 'styled-components'
import eventEmmiter from 'event-emitter'
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

const emitter = new eventEmmiter();

export const notify = (msg, type) => {
    emitter.emit('notification', msg, type);
}

class Notifications extends Component {
    constructor(props){
        super(props);


        this.state={
            top: -364,
            msg:'',
            type:'danger'
        }

        this.timeout = null
        
        emitter.on('notification', (msg, type) => {
            this.onShow(msg, type)
        })
    }
        



    // componentDidMount(){
    //     this.onShow("Your Address Added Succusfully ", 'danger')
    // }

  onShow =(msg, type)=>{
      if(this.timeout){
          clearTimeout(this.timeout);
          this.setState({ top:  -364}, 
            ()=>{
              this.timeout = setTimeout(()=> {
                  this.showNotification(msg, type);
              },500)
          }
          );
      } else {
          this.showNotification(msg, type)
      }

  }


  closePopUp=()=>{


        if(this.timeout){
            clearTimeout(this.timeout);
            this.setState({ top:  -364});
            this.timeout = null
        } else {
              this.setState({ top:  -364})
        }





  }

  
  showNotification =(msg, type) => {
      this.setState({
        top: 6,
        msg,
        type
    },
    () =>{
          this.timeout = setTimeout(() => {
              this.setState({
                  top: -364,
              })
          }, 3000);
      }
      )
  }

  render() {
    let locale = this.props.locale
    let lang = locale ? ar : en

    let classname= `alert alert-${this.state.type} custom-notification `
    return (


        <div 
            className={classname}
            role="alert" 
            style={{top:`${this.state.top}px`}}
            >
                <h4 className="alert-heading">  <i style={{right:'10px', marginLeft:'8px'}} className='fa fa-bell' > {lang.notification}</i> <i style={{left:'10px', marginRight:'8px', float:"right",cursor:"pointer"}} onClick={()=>this.closePopUp()} className='fas fa-times' > </i>  </h4>
                <hr/>


                <ul style={locale?{}:{paddingLeft:"18px", marginTop:"0px", marginBottom:"0px" , textAlign:"left"}} className={locale?" rtl m-auto":"ltr m-auto"}>{this.state.msg}</ul> 
        </div>
    );
  }
}


const mapStateToProps = state => {
    return {
      address_type: state.productReducer.address_type,
      locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
  