import React, { Component } from "react";
import { connect } from "react-redux";


// Actions
import { userExist} from "../../../store/actions/authActions";
import { resetErrors } from "../../../store/actions/errorActions";

// Components
import TextFieldGroup from "../../common/TextFieldGroup";
import 'react-day-picker/lib/style.css';


import ar from '../../../assets/locales/ar.json'
import en from '../../../assets/locales/en.json'


class ResetPasswordStepOne extends Component {
    state = {

        phone_number: "",
        password: "",
        password2:"",

      };
    



  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    
    let errorChanged = `${e.target.name}_changed`
    this.setState({[errorChanged]:false})



  }

  onSubmit = e =>{
    e.preventDefault();
    this.props.userExist(
      {phone_number: this.state.phone_number}, 2
      );

    this.setState({
      phone_number_changed:true,

    })
    this.props.resetErrors()

  }




  render() {
    const { errors, } = this.props;
    let locale = this.props.locale
    let lang = locale ? ar : en

    return (


      <div className={locale? 'rtl login': "ltr login"}>
    <div className="container">
      <div className="row">
        <div className="col-md-8 m-auto">
          <h1 className="display-4 text-center mb-4 logoColor">{lang.auth.passwordReset}</h1>
          <p className="lead text-center">{lang.auth.somethingClover}</p>
          <form className="needs-validation"  onSubmit={this.onSubmit}>
                                         

          {/* label={<p className="requiredField" >Phone Number  <span className="requiredColor"> * </span> </p> } */}


                                            <TextFieldGroup
                  inputCustomClassName={locale?"phoneField_rtl textFieldGroup_rtl":"textFieldGroup_ltr"}
                  errorMsgStyle="phoneField_rtl"

                  name="phone_number"
                  label= {<p className="requiredField" >{lang.auth.phoneNumber}<span className="requiredColor"> * </span> </p> } 
                  IconLeft='fas fa-mobile-alt icon-left'
                  placeholder={lang.auth.phoneNumberPlaceholder}
                  value={this.state.phone_number}
                  onChange={this.onChange}
                  errors={this.state.phone_number_changed  && (errors.response || errors.phone_number || errors.non_field_errors)}
                  required ={true}
                />



            <div className="col-md-12 mb-3">
              <button type="submit" className="btn btn-lg btn-outline-warning custom-detail-meal  btn-block  mt-4 mx-2" > <strong>{lang.auth.validate}</strong></button>       
            </div>
          
          </form>

        </div>
      </div>
    </div>
  </div>
    );
  }
}



const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errorReducer.errors,
  date_of_birth: state.productReducer.date_of_birth,
  new_user: state.auth.new_user,
  locale: state.productReducer.locale,

});

// const mapDispatchToProps = dispatch => {
//   return {
//     signupSendOTP: (email) => dispatch(signupSendOTP(email)),
//     handleNextRegistrationStep: (step) => dispatch(handleNextRegistrationStep(step))
//   };
// };

export default connect(
  mapStateToProps,
  {  resetErrors,userExist }
)(ResetPasswordStepOne);
