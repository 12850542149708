import React from "react";
import { connect } from "react-redux";

import { fetchKitchenMeals} from "../../store/actions/orderActions";
import Loading from "../Loading";
import Meals from "./Meals";
import ReactGA from 'react-ga';
import unauthorized from '../../assets/images/401.png'
import { Redirect } from 'react-router';


class Kitcken extends React.Component {
  state = {
    currentDate:"",
    currentDay:"",
    redirect: false,
    timer:10,
  };

  async componentDidMount() {
    ReactGA.pageview('Kitchen');

    this.props.fetchKitchenMeals()

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var date = yyyy + '-' + mm + '-' + dd; 



    
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    
    var currentDay = weekday[today.getDay()];


    this.setState({currentDate:date,currentDay:currentDay})




    if(this.props.auth.user.is_staff !== true) {
      this.redirect = setTimeout(() => this.setState({ redirect: true }), 10000)
      var intervalId = setInterval(this.timer, 1000);
      // store intervalId in the state so it can be accessed later:
      this.setState({intervalId: intervalId});
    }

  }




  timer = ()=> {

    var newCount = this.state.timer - 1;
    if(newCount >= 0) { 
        this.setState({ timer: newCount });
    } else {
        clearInterval(this.state.intervalId);
    }
 }


 componentWillUnmount() {
  clearTimeout(this.redirect)
  clearInterval(this.state.intervalId);

  }

  render() {

    if (this.props.auth.user.is_staff !== true){
      ReactGA.pageview(`/kitchen/unauthorized`);

      return this.state.redirect ? <Redirect to="/" /> :
        <div>
          <img src={unauthorized} alt="bg" className="payment-img"/>
          <h6 className="redirectTimerText"> Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6>
      </div>
      
  }

    if (this.props.loading) {
      return <Loading />
    } else {

    const meals = this.props.meals.length > 0 &&this.props.meals.map(meal => (
      <Meals key={meal.id} meal={meal} />
    ));

      return (
        <div className="container mt-3">

<br/>
<br/>
<br/>

        <h5 className="centerHeader logoColor  mb-4">{this.state.currentDate} : {this.state.currentDay} </h5>
<br/>
        <div className="row justify-content-md-center " >
            {meals}
        </div>
        <hr className="mb-4"/>



        </div>
      )
    }
  }
}


const mapStateToProps = state => {
    return {
        meals: state.orderReducer.kitchen_meals,
        loading: state.orderReducer.loading_kitchen_meals,
        auth: state.auth,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        fetchKitchenMeals: () => dispatch(fetchKitchenMeals()),

    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Kitcken);
  