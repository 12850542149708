import {TOGGLE_LOADING, GET_ERRORS} from "./types";
import  {notify} from '../../components/Notifications'
import React from 'react';
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'



// let locale = this.props.locale
// let lang = locale ? ar : en


// Reset errors
export const resetErrors = () => {
  return {
    type: GET_ERRORS,
    payload: []
  };
};


export const ToggleLoading = () => ({
  type: TOGGLE_LOADING,
  
});


export const errorNotification = (errors, state) => {
  let language = errors.headers["content-language"]
  let lang = language === "ar" ? ar : en 

  // lang.serializerFields.phone_number
  
  let errorType = Object.prototype.toString.call(errors.data)
  // alert(errorType)
  if (errorType === '[object Object]'){
    notify(
    Object.keys(errors.data).map((key ,  index) => 

    Array.isArray(errors.data[key])  && errors.data[key].length > 1  ?  
        
      errors.data[key].map( (err ,  idx) => 
        <li key={idx} > {err}  </li>
    )
    :
      <li key={index}  >
        {lang.serializerFields[key]?lang.serializerFields[key]: key}: <span  className="localNumber">{errors.data[key]}</span> 
      </li>

    ) , state)
  }
  else if (errorType === '[object Array]'){
    notify(
    errors.data.map((key ,  index) => 

    Array.isArray(key)  && key.length > 1  ?  
        
      key.map( (err ,  idx) => 
        <li key={idx} > {err}  </li>
        //  <li key={idx} style={{paddingLeft:"18px", marginTop:"0px", marginBottom:"0px" , textAlign:"left"}}> {err}  </li> 
    )
    :
      <li key={index} >
        {key}
      </li>

    ) , state)
  }
};


export const successNotification = (msg, state) => {
    notify(<p className="centerHeader">{msg}</p>  , state)
  }
