import axios from "axios";
import { GET_ERRORS, GET_ORDERS,UPDATE_ORDER_MEALS,GET_ORDER_DETAIL,GET_KICHEN_MEALS,GET_LOGISTICS_ORDER_MEALS,GET_IS_MAX_ORDERS } from "./types";

export const fetchOrders = () => {
  return async dispatch => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}api/orders/`
      );
      let orders = response.data;
      dispatch({
        type: GET_ORDERS,
        payload: orders
      });
    } catch (error) {
      console.error(error.response);

      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    }
  };
};
export const fetchIsMaxOrders = () => {
  return async dispatch => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}api/orders/max`
      );
      let isMax = response.data;
      dispatch({
        type: GET_IS_MAX_ORDERS,
        payload: isMax
      });
    } catch (error) {
      console.error(error.response);

      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    }
  };
};
export const fetchOrderDetail = (orderID) => {
  return async dispatch => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}api/orders/${orderID}/`
      );
      let order = response.data;
      dispatch({
        type: GET_ORDER_DETAIL,
        payload: order
      });
    } catch (error) {
      console.error(error.response);

      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    }
  };
};


export const fetchKitchenMeals = () => {
  return async dispatch => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}api/kitchen/meals/list/`
      );
      let meals = response.data;
      dispatch({
        type: GET_KICHEN_MEALS,
        payload: meals
      });
    } catch (error) {
      console.error(error.response);

      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    }
  };
};

export const fetchLogistics = () => {
  return async dispatch => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}api/logistic/order/meals/list/`
      );
      let orderMeals = response.data;
      dispatch({
        type: GET_LOGISTICS_ORDER_MEALS,
        payload: orderMeals
      });
    } catch (error) {
      console.error(error.response);

      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    }
  };
};

export const updateOrderMeals = (id, meals,history,orderID) => {
  return async dispatch => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}api/orders/meal/${id}/update`,{
          "meals":meals
        }
      );
      let orderMeals = response.data;

      dispatch(fetchOrderDetail(orderID))
      history.goBack()
      dispatch({
        type: UPDATE_ORDER_MEALS,
        payload: orderMeals
      });
    } catch (error) {
      console.error(error.response);

      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    }
  };
};

// export const getOrderMeals = (orderMeals) => ({
//   type: GET_ORDER_MEALS,
//   payload: orderMeals,

// });

// export const handleNextOrderStep = (step) => ({
//   type: GET_NEXT_ORDER_STEP,
//   payload: step,
  
// });
export default fetchOrders;
