import React, { Component } from "react";
import { connect } from "react-redux";

import Loading from "../Loading";
import OrderList from './OrderList'
import { fetchOrders } from "../../store/actions/orderActions";
import ReactGA from 'react-ga';
import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class OrdersList extends Component {
  state = {
    filter:true
    };

    async componentDidMount() {
      ReactGA.pageview('View My Orders');

      if (this.props.orders.length === 0)
        await this.props.fetchOrders();

      }


      filter=(q)=>{
        this.setState({
          filter:q
        })
      }

  render() {

    let locale = this.props.locale
    let lang = locale ? ar : en
    const { isAuthenticated } = this.props.auth;
    let location =  this.props.location.pathname
    isAuthenticated === false && this.props.history.push('/login',{ from: location })
    
    if (this.props.loading) {
      return <Loading />
    } else {
            const orderList = 
            // this.state.filter ?
            this.props.orders.filter(e => e.is_active === (this.state.filter)).map(order => (
        <OrderList key={order.id} order={order}  />
      ));


      let noOrders = true
      if (orderList.length > 0) {

        noOrders = false
      } 


      return (      
      <div className="container onNaveChange" >
            <h3 className="centerHeader logoColor yourPlane mb-4">{lang.nav.my_orders}</h3>


            <div className="row justify-content-center recomendedMeal">


                      

          <button  
              onClick={()=>this.filter(false)} 
              className={`btn btn-sm btn-outline-warning custom-detail-meal  col-lg-3    mr-3 col-md-4  col-sm-4  col-5  ${this.state.filter&& "inActiveFilter"}`}
              style={{marginTop:'.5rem'} }> <strong className="ButtonText"> <i className="far fa-bell-slash mr-2"></i>  {lang.Expired}</strong>   
          </button>
          <button  
              onClick={()=>this.filter(true)} 
              className={`btn btn-sm btn-outline-warning custom-detail-meal  col-lg-3    mr-3 col-md-4  col-sm-4  col-5  ${!this.state.filter&& "inActiveFilter"}`}
              style= {{ marginTop:'.5rem'}}>
              <strong className="ButtonText ">   {lang.Active} <i className="fas fa-bell ml-2"></i> </strong>   
          </button>

</div>


          <div className="row justify-content-md-center mr-0 ml-0  mt-4" >


            {noOrders? <h4 className="centered"> {lang.noOrders} </h4> :orderList}
        </div>
        <br/>
              
      </div>
      )

    
    }
  }
}
const mapStateToProps = state => {
  return {
    loading: state.orderReducer.loading,
    orders: state.orderReducer.orders,
    auth: state.auth,
    locale: state.productReducer.locale,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrders: () => dispatch(fetchOrders())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
