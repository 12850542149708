import React, { Component } from "react";
import {withRouter} from "react-router-dom"
import { connect } from "react-redux";

import nf from '../../assets/images/nf.png'

import MealDetails from './MealDetails'

import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'


class MealsList extends Component {
  state = {
      isSelected:this.props.meal.isSelected,

  };



  componentDidMount(){

    if (this.props.meal.isSelected){
        this.props.IncreaseTotalSelected()
        this.props.AddToTheList(this.props.meal.id)
    }
    
}

checkSelectedMeal = ()=>{
    if (this.state.isSelected) {
        this.props.DecreaseTotalSelected()
        this.props.RemoveFromTheList(this.props.meal.id)
    } 
    else {
        this.props.IncreaseTotalSelected()
        this.props.AddToTheList(this.props.meal.id)
    }
    this.setState({
          isSelected:!this.state.isSelected

      })
  }




    render() {

        let {isSelected} =  this.state
        let {total_slected, number_of_meals ,meal} =  this.props

        let locale = this.props.locale
        let lang = locale ? ar : en










        let dateID = this.props.match.params.dateID 
        
        let UpdateHiddr = false 
        
        var mealDate = new Date(dateID);
        var todaysDate = new Date()

        mealDate.setHours(0,0,0,0)
        todaysDate.setHours(0,0,0,0)

        if (mealDate <= todaysDate ){
            UpdateHiddr = true
        }







        return (
                
            <div className=" p-0 col-lg-5 col-md-5 col-11 mr-auto ml-auto mb-5" 
              >

                <div className={isSelected ?  `row no-gutters shadowCard NotselectedItemCard` : `row no-gutters shadowCard NotselectedItemCard`}>
                
                    <div className="col m-auto ">
                        <div className="card-body p-0 ">
                            <img src={meal.image ? meal.image : nf}  alt="..."  style={{width:"133px", height:"133px"}} />
                        </div>
                    </div>

                    <div className="col-xl-7 col-lg-6 col-md-6 col-sm-8 col-6">
                        <div className="card-body pt-2 pb-2 pl-2">
                           
                            <h6 className="card-title mb-1">{locale? meal.name_ar :meal.name}</h6>
                            <p className="card-text mb-2" style={{fontSize:'small'}} >{locale ? meal.description_ar :meal.description}</p>
                           
                           {!UpdateHiddr && 
                           
                            <button 
                            disabled= {total_slected === number_of_meals && isSelected === false }
                            onClick={this.checkSelectedMeal}  
                            className={isSelected ? `btn btn-outline-danger btn-sm` :`btn btn-outline-success btn-sm`}> 
                            {isSelected ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i> }</button>
                            }


                              <button  onClick={()=> this.props.handleClickedMeal(meal)} className="btn btn-outline-warning btn-sm ml-2  custom-detail-meal"  data-toggle="modal" data-target="#mealDetail" type="button" >  {lang.meal_detail} </button>



                            <div className="modal fade" id="mealDetail" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="centerHeader logoColor  mb-0">{lang.meal_detail_pop}</h4>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="col-12 p-0"> 
                                            {this.props.showMealDetail && <MealDetails meal={this.props.clickedMeal}/> } </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>










                        </div>
                    </div>

                </div>
            </div>
        );
        }
    } 
    // }
  const mapStateToProps = state => {
    return {
      loading: state.orderReducer.loadingOrder,
      orders: state.orderReducer.orders,
      auth: state.auth,
      locale: state.productReducer.locale,

  
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      // getOrderMeals: (order_meals) => dispatch(getOrderMeals(order_meals)),
      // handleNextOrderStep: (step) => dispatch(handleNextOrderStep(step)),
    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MealsList));
  