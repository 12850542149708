import React, { Component } from "react";
import {withRouter} from "react-router-dom"
import { connect } from "react-redux";
import {addressType,handleNextStep, } from "../../store/actions/productAction";
import home from '../../assets/images/home.png'
import office from '../../assets/images/office.jpg'
import nf from '../../assets/images/nf.png'
import friend from '../../assets/images/friend.png'

import ar from '../../assets/locales/ar.json'
import en from '../../assets/locales/en.json'

class AddressesList extends Component {
  state = {
    AddressType:''
  };


  componentDidMount(){

    if (this.props.address_type === ''){
      if (this.props.myAddress.default === true){

        this.props.addressType(this.props.myAddress.address_type)
      }

    }  
  }

  handleChange = event => {

    this.props.addressType(event.target.value)

  
  };

  checkSelected = address => {

    this.props.addressType(address)
    

  
  };
  


    render() {
      let locale = this.props.locale
      let lang = locale ? ar : en

      let Icon = nf
      let addressType = ""
      
      const myAddress = this.props.myAddress


      if (myAddress.address_type === 'Work'){
         Icon = office
         addressType = lang.map.work
        }
        if (myAddress.address_type === 'Home'){
          Icon = home
          addressType = lang.map.home
        }
        if (myAddress.address_type === 'Friend'){
          Icon = friend
          addressType = lang.map.friend
      }


      return (
          <div
            onClick={ () => this.checkSelected(myAddress.address_type)}
            data-target="#selectAddress" data-dismiss="modal"
            // className = {myAddress.address_type === this.props.address_type ? 'SelectedAddress col-lg-12  col-md-11 ' : ' NotSelectedAddress col-md-11  col-lg-12 pl-0 pr-0'}
            className = {myAddress.address_type === this.props.address_type ? 'SelectedAddressPopUP col-lg-12  col-md-11 ' : ' NotSelectedAddress col-md-11  col-lg-12 pl-0 pr-0'}

            // style={ myAddress.address_type === this.props.address_type ? {paddingTop:"9px", paddingBottom:"9px" , borderColor:"#b9702a", borderStyle:"solid",width:"100%", maxWidth:"",} :{paddingTop:"9px", paddingBottom:"9px",width:"100%", maxWidth:"",} }

            >
              <div className="row no-gutters custom-card-style addressesCard "  >
              
                  <div className="col  m-auto" >
                      <div className="card-body p-0 ">
                          <img src={Icon}  alt="..."  style={{width:"133px", height:"133px"}} />
                      </div>
                  </div>

                  <div className="col-xl-8  customWidth col-lg-8 col-md-8 col-sm-8 col-6">
                      <div className="card-body p-2 addressesCard ">
                          <h5  className="card-title mb-2 mt-0.1">{addressType}</h5>
                          <p  className="card-text mb-2" style={{fontSize:'small'}}>{myAddress.address}</p>
                      </div>
                  </div>

              </div>
          </div>
      )
    }
  }
  
  
  const mapStateToProps = state => {
    return {
      address_type: state.productReducer.address_type,
      locale: state.productReducer.locale,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      addressType: (type) => dispatch(addressType(type)),
      handleNextStep: (step) => dispatch(handleNextStep(step)),

    };
  };
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddressesList));
  