import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";


const Loading = () => (
  <div className=" centered   ">
  <FontAwesomeIcon
      style={{ color: "#cdcdcd" }}
      icon={faSpinner}
      spin
      size="4x"
    /> 
  </div>
);
export default Loading;
