import axios from "axios";
import fetchOrders from './orderActions'
import {
  GET_SUBSCRIPTIONS,
  GET_SELECTED_SUB,
  GET_SELECTED_START_DATE,
  GET_SELECTED_DAY,
  GET_MEALS,
  GET_NEXT_STEP,
  GET_PREVIOUS_STEP,
  GET_MEALS_LIST_FOR_SELECTED_DAY,
  CHANGE_LOADING_MEALS_STATUS,
  CHANGE_SLEECTED_SUB_TO_TRUE,
  CHANGE_SLEECTED_SUB_TO_FALSE,
  CHANGE_SLEECTED_DATE_TO_TRUE,
  CHANGE_SLEECTED_DATE_TO_FALSE,
  CHANGE_ONE_DAY_SELECTED_MEAL_TO_FALSE,
  CHANGE_ONE_DAY_SELECTED_MEAL_TO_TRUE,
  INCREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY,
  DECREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY,
  GET_SELECTED_MEAL_DETAIL,
  GET_SELECTED_DELIVERY_OPTION,
  GET_COUPON,
  GET_ERRORS,
  GET_MAP,
  POST_ADDRESS,
  GET_USER_ADDRESSES,
  GET_ADDRESS,
  GET_SELECTED_ADDRESS_TYPE,
  GET_AUTOCOMPLETE,
  GET_SUPPORTED_PLACES,
  POST_PAYMENT,
  GET_SELECTED_BIRTH_DATE,
  GET_ALL_MEALS,
  SEND_EMAIL,
  CHANGE_LOCAL,
  GET_END_DAY
} from "./types";

// const instance = axios.create({
//   baseURL: `${process.env.REACT_APP_SERVER_URL}`,

// });





export const fetchSubscriptions = () => {
  return async dispatch => {

    try {
      let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/subscriptions/list/`);

      // let response = await instance.get(`api/subscriptions/list/`);
      let subscriptions = response.data;
      dispatch({
        type: GET_SUBSCRIPTIONS,
        payload: subscriptions
      });
    } catch (error) {

      console.error(error);
    }
  };
};

export const fetchAllMeals = () => {
  return async dispatch => {

    try {
      let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/meals/list/`);

      // let response = await instance.get(`api/meals/list/`);
      let meals = response.data;
      dispatch({
        type: GET_ALL_MEALS,
        payload: meals
      });
    } catch (error) {

      console.error(error);
    }
  };
};

export const fetchSupportedPlaces = () => {
  return async dispatch => {

    try {
      let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/suppported/places/list`);

      // let response = await instance.get(`api/suppported/places/list`);
      let places = response.data;
      dispatch({
        type: GET_SUPPORTED_PLACES,
        payload: places
      });
    } catch (error) {

      console.error(error);
    }
  };
};

export const fetchMeals = (date,id) => {
  return async dispatch => {

    try {
      let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/serve/dates/${date}/${id}/list/`);

      // let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/serve/dates/${date}/${id}/list/`);
      let meals = response.data;
      dispatch({
        type: GET_MEALS,
        payload: meals
      });
    } catch (error) {

      console.error(error);
    }
  };
};
export const sendEmail = (email) => {
  return async dispatch => {

    try {
      let response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/email/`,email);

      // let response = await instance.post(`api/email/`,email);
      let res = response.data;
      dispatch({
        type: SEND_EMAIL,
        payload: res
      });
    } catch (error) {

      console.error(error);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    }
  };
};

export const fetchCoupon = (data) => {
  return async dispatch => {

    try {
      let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/coupon/${data}/`);
      let coupon = response.data;
      dispatch({
        type: GET_COUPON,
        payload: coupon
      });
    } catch (error) {
        let resetCoupon = null
        dispatch({
          type: GET_COUPON,
          payload: resetCoupon
        });

      console.error(error);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    }
  };
};


export const fetchAddressesList = () => {
  return async dispatch => {

    try {
      let response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/address/list`);
      let address = response.data;
      dispatch({
        type: GET_USER_ADDRESSES,
        payload: address
      });
    } catch (error) {
      console.error(error);

    }
  };
};

export const postAddress = (address) => {
  return async dispatch => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/address/create/`, address);
      const newAddress = res.data;
      dispatch({
        type: POST_ADDRESS,
        payload: newAddress
      });
    } catch (error) {
      console.error(error.response.data);
    }
  };
};

// step 2
export const checkPayment = (tapID) => {
  return async dispatch => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/check/payment/`, 
      {
        "tap_id":tapID,
      }
      );
      const response = res.data;
      
      dispatch({
        type: POST_PAYMENT,
        payload: response
      });
      dispatch(fetchOrders())

    } catch (error) {

      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });

      dispatch({
        type: POST_PAYMENT,
        payload: error.response.data.response
      });
      console.error(error.response.data);
    }
  };
};


// step 1
export const handlePayment = (sup_id,delivery,coupon, selectedMeals,address_type,start_date) => {
  return async dispatch => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/handlePayment/`, 
      {
        "sup_id":sup_id,
        "delivery":delivery,
        "coupon":coupon,
        "address_type":address_type,
        "selectedMeals":selectedMeals,
        "start_date":start_date,
      }
      );
      const response = res.data;
      const redirectUrl = response.redirectUrl
      window.location.replace(redirectUrl);


    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
      console.error(error.response.data);
    }
  };
};




export const getFullAddress = (address,area,city,state,lat,lng,mapUrl,country,postal_code,county) => ({
  type: GET_ADDRESS,
  address: address,
  area: area,
  city: city,
  state: state,
  lat: lat,
  lng: lng,
  mapUrl: mapUrl,
  country:country,
  postal_code:postal_code,
  county:county,

  
});
export const handleMapLoad = (map) => ({
  type: GET_MAP,
  payload: map
});
export const handleAutocompleteLoad = (autocomplete) => ({
  type: GET_AUTOCOMPLETE,
  payload: autocomplete
});
export const handleSeletedSub = (id) => ({
  type: GET_SELECTED_SUB,
  payload: id
});
export const handleSeletedStartDate = (date) => ({
  type: GET_SELECTED_START_DATE,
  payload: date,
});
export const handleSeletedBirthday = (date) => ({
  type: GET_SELECTED_BIRTH_DATE,
  payload: date,
});

export const handleSeletedDay = (day) => ({
  type: GET_SELECTED_DAY,
  payload: day,
  
});


export const handleEndDay = (startDate, total_days,include_weekend) => {
  
  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  var beginDate = startDate;
  beginDate = new Date(beginDate);
  var endDate = "", count = 1;
  if (isValidDate(beginDate)){

    while(count < total_days ){
      endDate = new Date(beginDate.setDate(beginDate.getDate() + 1));

          if (include_weekend){

          if(endDate.getDay() !== 5 ){
            count++;
          }

        }

        else{
          if(endDate.getDay() !== 5 && endDate.getDay() !== 6){
            count++;
          }  
        }

    }
  }

  return  dispatch => {
    dispatch({
      type: GET_END_DAY,
      payload: endDate
    });

  }

}









export const handleChangeLocal = (locale) => {


  let language = locale?'en':'ar'
  // alert(language)
  axios.defaults.headers.common['Accept-Language'] = language

  return  dispatch => {
    dispatch({
      type: CHANGE_LOCAL,

    });
  }
}

export const changeOndDaySeletedMealToFalse = (selected) => ({
  type: CHANGE_ONE_DAY_SELECTED_MEAL_TO_FALSE,
  payload: selected,
});

export const changeOndDaySeletedMealToTrue = (selected) => ({
  type: CHANGE_ONE_DAY_SELECTED_MEAL_TO_TRUE,
  payload: selected,
});

export const increase_number_of_selected_meals = (selected) => ({
  type: INCREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY,
  payload: selected,
});

export const decrease_number_of_selected_meals = (selected) => ({
  type: DECREASE_NUMBER_OF_SELECTED_MEALS_PER_DAY,
  payload: selected,
});

export const handleNextStep = (step) => ({
  type: GET_NEXT_STEP,
  payload: step,
  
});

export const handlePreviousStep = (step) => ({
  type: GET_PREVIOUS_STEP,
  payload: step,
});
export const deliveryOption = (DP) => ({
  type: GET_SELECTED_DELIVERY_OPTION,
  payload: DP,
  
});

export const addressType = (DP) => ({
  type: GET_SELECTED_ADDRESS_TYPE,
  payload: DP,
  
});

export const handleLoadingMealsStatus = () => ({
  type: CHANGE_LOADING_MEALS_STATUS,
});

export const handleMealsListForSelectedDay = (day) => ({
  type: GET_MEALS_LIST_FOR_SELECTED_DAY,
  payload: day,

});





export const change_selected_sub_to_true = () => ({
  type: CHANGE_SLEECTED_SUB_TO_TRUE,
  
});
export const change_selected_sub_to_false = () => ({
  type: CHANGE_SLEECTED_SUB_TO_FALSE,
  
});
export const change_selected_date_to_true = () => ({
  type: CHANGE_SLEECTED_DATE_TO_TRUE,
  
});
export const change_selected_date_to_false = () => ({
  type: CHANGE_SLEECTED_DATE_TO_FALSE,
  
});

export const handleSelectedMealsDetail = (detail) => ({
  type: GET_SELECTED_MEAL_DETAIL,
  payload: detail,

});