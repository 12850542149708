import React from "react";
import { connect } from "react-redux";

import { fetchLogistics} from "../../store/actions/orderActions";
import Loading from "../Loading";
import OrderMeals from "./OrderMeals";
import ReactGA from 'react-ga';
import unauthorized from '../../assets/images/401.png'
import { Redirect } from 'react-router';
import ReactToExcel from 'react-html-table-to-excel'

class Logistic extends React.Component {
  state = {
    currentDate:"",
    currentDay:"",
    redirect: false,
    timer:10,
    filter:""
    };

  async componentDidMount() {
    ReactGA.pageview('Logistic');


    this.props.fetchLogistics()

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var date = yyyy + '-' + mm + '-' + dd; 



    
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    
    var currentDay = weekday[today.getDay()];


    this.setState({currentDate:date,currentDay:currentDay})



    if(this.props.auth.user.is_staff !== true) {
      this.redirect = setTimeout(() => this.setState({ redirect: true }), 10000)
      var intervalId = setInterval(this.timer, 1000);
      this.setState({intervalId: intervalId});
    }

  }




  timer = ()=> {

    var newCount = this.state.timer - 1;
    if(newCount >= 0) { 
        this.setState({ timer: newCount });
    } else {
        clearInterval(this.state.intervalId);
    }
 }


 componentWillUnmount() {
  clearTimeout(this.redirect)
  clearInterval(this.state.intervalId);


}


filter=(q)=>{
  this.setState({
    filter:q
  })
}


_export;
export = () => {
    this._export.save();
}

render() {


  if (this.props.auth.user.is_staff !== true){
    ReactGA.pageview(`/logistic/unauthorized`);

    return this.state.redirect ? <Redirect to="/" /> :

      <div>
        <img src={unauthorized} alt="bg" className="payment-img"/>
        <h6 className="redirectTimerText"> Redirect In <strong className="CounterStyle">{this.state.timer}</strong>  Seconds</h6>
    </div>
    
}


if (this.props.loading) {
  return <Loading />
} else {



  const orderMeals = this.props.orderMeals.length > 0 &&this.props.orderMeals.filter(e => e.delivery_option.includes(this.state.filter)).map((orderMeal, index) => (
    <OrderMeals key={orderMeal.id} index={index} orderMeal={orderMeal} />
  ));
      return (
        <div className="  pl-5 pr-5 mt-3">

<br/>
<br/>
<br/>



        <h5 className="centerHeader logoColor  mb-4">{this.state.currentDate} : {this.state.currentDay} </h5>




        <div className="row justify-content-center recomendedMeal">


                        
                        <button  
                            onClick={()=>this.filter("")} 
                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-3    mr-3 col-md-4  col-sm-4  col-5"
                            style={{marginTop:'.5rem'} }> <strong className="ButtonText"> <i className="fas fa-clipboard-list mr-2"></i>  All</strong>   
                        </button>


                        <button  
                            onClick={()=>this.filter("Delivery")} 
                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-3    mr-3 col-md-4  col-sm-4  col-5"
                            style={{marginTop:'.5rem'} }> <strong className="ButtonText"> <i className="fas fa-shipping-fast mr-2"></i>  Delivery</strong>   
                        </button>
                        <button  
                            onClick={()=>this.filter("Pick Up")} 
                            className="btn btn-sm btn-outline-warning custom-detail-meal  col-lg-3   col-md-4  col-sm-4  col-5 "
                            style= {{ marginTop:'.5rem'}}>
                            <strong className="ButtonText ">   Pick Up <i className="fas fa-people-carry ml-2"></i> </strong>   
                        </button>
                </div>


                <br/>


<br/>



  {/* <table className="table custom-border table-bordered text-center table-hover table-sm" id="table-to-xls"> */}
  <table className="table userMangement-table  table-customResponsive" id="table-to-xls" >
  <thead>
    <tr>
      <th scope="col" style={{minWidth:"80px"}} >#</th>
      <th scope="col" style={{minWidth:"80px"}}>Order Id</th>
      <th scope="col" style={{minWidth:"140px"}}>Full Name</th>
      <th scope="col" style={{minWidth:"140px"}}>Phone Number </th>
      <th scope="col" style={{minWidth:"140px"}}>Delivery Option</th>
      <th scope="col">Address </th>
      <th scope="col">mapUrl </th>
      <th scope="col">Meals </th>
      <th scope="col">Date </th>
      <th scope="col">Subscription </th>
    </tr>
  </thead>
  <tbody>
  
  {orderMeals}
  </tbody>
</table>

<ReactToExcel 
                        className="btn btn-secondary   btn-lg btn-block "
                        table="table-to-xls" filename={this.state.currentDate} sheet="sheet 1" buttonText="Export to Excel"/>

        <br/>
        <br/>
        <br/>
        </div>
      )
    }
  }
}


const mapStateToProps = state => {
    return {
        orderMeals: state.orderReducer.logistics_order_meals,
        loading: state.orderReducer.loading_logistics_order_meals,
        auth: state.auth,

    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        fetchLogistics: () => dispatch(fetchLogistics()),

    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Logistic);
  