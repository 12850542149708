import React from "react";
import { connect } from "react-redux";

import { handleNextStep,fetchMeals,handlePreviousStep ,change_selected_sub_to_false,handleLoadingMealsStatus,change_selected_date_to_false} from "../../store/actions/productAction";
import Loading from "../Loading";
import MyDatesList from "./MyDatesList";
// import _ from 'lodash';

class MyMeals extends React.Component {
  state = {
    title:''
  };

  async componentDidMount() {
    const selectedStartDay = this.props.selectedStartDay
    const selectedSub = this.props.selectedSub

     if  (  this.props.isSelectedSubChanged || this.props.isSelectedDateChanged){
      await this.props.handleLoadingMealsStatus()
      await this.props.fetchMeals(selectedStartDay,selectedSub.id);
      this.props.isSelectedSubChanged && this.props.change_selected_sub_to_false()
      this.props.isSelectedDateChanged && this.props.change_selected_date_to_false()
    } 




  }






  continue = e => {
    e.preventDefault();
    // this.props.nextStep();
    this.props.handleNextStep(6)
  }
  

back = e => {
  e.preventDefault();
  // this.props.prevStep();
  this.props.handlePreviousStep(2);
  
}

getSeletedMealsTitle=(title)=>{

  this.setState({title:title})
}

  render() {

  

    const myDatesList = this.props.meals.length > 0 &&this.props.meals.map(date => (
      <MyDatesList key={date.id} dates={date} getSeletedMealsTitle={this.getSeletedMealsTitle} title={this.state.title}  />
    ));

    if (this.props.loading) {
      return <Loading />
    } else {
      return (

        <div className="row justify-content-md-center allMealsViewInner mt-1" >
                        {myDatesList}
                    </div>
        )

    }
  }
}


const mapStateToProps = state => {
    return {
        subscriptions: state.productReducer.subscriptions,
        meals: state.productReducer.meals,
        selectedSub: state.productReducer.selectedSub,
        selectedStartDay: state.productReducer.selectedStartDay,
        loading: state.productReducer.loadingMeals,
        isSelectedSubChanged: state.productReducer.isSelectedSubChanged,
        isSelectedDateChanged: state.productReducer.isSelectedDateChanged,
        productReducer: state.productReducer,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        fetchMeals: (date,id) => dispatch(fetchMeals(date,id)),
        handlePreviousStep: (step) => dispatch(handlePreviousStep(step)),
        change_selected_sub_to_false: () => dispatch(change_selected_sub_to_false()),
        handleLoadingMealsStatus: () => dispatch(handleLoadingMealsStatus()),
        change_selected_date_to_false: () => dispatch(change_selected_date_to_false()),
        handleNextStep: (step) => dispatch(handleNextStep(step)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(MyMeals);
  